import React, {useEffect, useState} from "react";
import Select, {SingleValue} from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../store/Store";
import {
    DDProps,
    DropdownProps,
    getSelectedDropdownOptionByValue,
    sortGenericNameIdToDropdownProps
} from "./Helpers/dropdownUtils";
import {Category} from "../../api/ppb";
import {nullifyCategoryListStore} from "../../store/categories/actions/CategoriesActions";

const CategoryDropdown = (props: DropdownProps<Category>) => {
    const categoryList = useSelector((state: RootStore) => state.categories.data || []);
    const [categoryOptions, setCategoryOptions] = useState<DDProps[]>([]);
    const [selectedOption, setSelectedOption] = useState<DDProps | undefined>(undefined);
    const dispatch = useDispatch();

    /** Get the venue list upon mounting */
    useEffect(() => {
        processCategoriesIncoming(categoryList);

        return function () {
            dispatch(nullifyCategoryListStore());
        };
    }, []);

    useEffect(() => {
        processCategoriesIncoming(categoryList);

        return function () {
            dispatch(nullifyCategoryListStore());
        };
    }, [categoryList]);

    const processCategoriesIncoming = (venues: Category[]) => {
        const options = sortGenericNameIdToDropdownProps(venues);
        setCategoryOptions(options);

        if (props.item.id > 0) {
            const option = getSelectedDropdownOptionByValue(props.item.id, options);
            setSelectedOption(option);
            const category = findCategory(categoryList, +option.value);
            props.changeOption(category);
            return;
        }

        if (options.length === 0 || props.clearable) {
            setSelectedOption(undefined);
            props.changeOption(getEmptyCategory());
            return;
        }

        const option = getSelectedDropdownOptionByValue(props.item.id, options);
        setSelectedOption(option);
        const category = findCategory(categoryList, +option.value);
        props.changeOption(category);
    };

    /** Fired when a new option is selected */
    const handleCategoryChange = (newValue?: SingleValue<DDProps>) => {
        if (!newValue) {
            props.changeOption(undefined);
            setSelectedOption(undefined);
            return;
        }
        const option = getSelectedDropdownOptionByValue(newValue.value, categoryOptions);
        setSelectedOption(option);
        const category = findCategory(categoryList, +option.value);
        props.changeOption(category);
    };

    return (
        <React.Fragment>
            <React.Fragment>
                <Select
                    className="search-bar"
                    classNamePrefix="select-options"
                    options={categoryOptions}
                    onChange={handleCategoryChange}
                    isSearchable={props.searchable}
                    value={selectedOption}
                    noOptionsMessage={() => "No Categories Found"}
                    placeholder="Select Category"
                    isDisabled={props.disabled}
                    isClearable={props.clearable}
                    menuPortalTarget={document.body}
                />
            </React.Fragment>
        </React.Fragment>
    );
};

export default CategoryDropdown;

export function getEmptyCategory(): Category {
    return {
        clinicalGrades: [],
        id: 0,
        name: ""
    };
}

function findCategory(categories: Category[], id: number): Category | undefined {
    const index = categories.findIndex((item: Category) => item.id === id);

    if (index < 0) return;

    return categories[index];
}
