/* tslint:disable */
/* eslint-disable */
/**
 * Staff Management
 * API to manage users (staff) in the medicare system
 *
 * The version of the OpenAPI document: 0.2-SNAPSHOT
 * Contact: dan@pulseadvancedmedia.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * Response to either approve or reject a users absence request
 * @export
 * @interface AbsenceApprovalResponse
 */
export interface AbsenceApprovalResponse {
    /**
     * Absence request ID
     * @type {number}
     * @memberof AbsenceApprovalResponse
     */
    'id': number;
    /**
     * Optional reason why the request was approved or denied
     * @type {string}
     * @memberof AbsenceApprovalResponse
     */
    'reason'?: string;
    /**
     * Optional **override** number of days this absence is considered to be. If not specified, underlying absence will not change.
     * @type {number}
     * @memberof AbsenceApprovalResponse
     */
    'numberDays'?: number;
}
/**
 * High-level stats for a primary absence dashboard
 * @export
 * @interface AbsenceDashboardStatsRequest
 */
export interface AbsenceDashboardStatsRequest {
    /**
     * Minimum start date for an absence, inclusive
     * @type {number}
     * @memberof AbsenceDashboardStatsRequest
     */
    'startDate': number;
    /**
     * Maximum end date for an absence, exclusive
     * @type {number}
     * @memberof AbsenceDashboardStatsRequest
     */
    'endDate': number;
}
/**
 * High-level stats for a primary absence dashboard
 * @export
 * @interface AbsenceDashboardStatsResponse
 */
export interface AbsenceDashboardStatsResponse {
    /**
     * Minimum start date for an absence, inclusive
     * @type {number}
     * @memberof AbsenceDashboardStatsResponse
     */
    'startDate': number;
    /**
     * Maximum end date for an absence, exclusive
     * @type {number}
     * @memberof AbsenceDashboardStatsResponse
     */
    'endDate': number;
    /**
     * Total day counts for each absence type. Key is the absence type enum name, value is the count
     * @type {{ [key: string]: number; }}
     * @memberof AbsenceDashboardStatsResponse
     */
    'absenceCounts': { [key: string]: number; };
}
/**
 * Single absence to save to the DB
 * @export
 * @interface AbsencePersistRequest
 */
export interface AbsencePersistRequest {
    /**
     * Unique ID for the record. 0 for new, positive number for existing.
     * @type {number}
     * @memberof AbsencePersistRequest
     */
    'id': number;
    /**
     * Unique ID (username) of the staff member the absence is for
     * @type {string}
     * @memberof AbsencePersistRequest
     */
    'staffId': string;
    /**
     * Name of staff member the absence is for
     * @type {string}
     * @memberof AbsencePersistRequest
     */
    'staffName': string;
    /**
     * User should / should not be paid for this day. Overrides type and blocks
     * @type {boolean}
     * @memberof AbsencePersistRequest
     */
    'paidOverride'?: boolean;
    /**
     * User should have this day taken from the leave allowance. Overrides type and blocks
     * @type {boolean}
     * @memberof AbsencePersistRequest
     */
    'leaveOverride'?: boolean;
    /**
     * User is available for other shifts on this day. Overrides type and blocks
     * @type {boolean}
     * @memberof AbsencePersistRequest
     */
    'availableForShiftsOverride'?: boolean;
    /**
     * Optional comments from staff member, visible to staff member
     * @type {string}
     * @memberof AbsencePersistRequest
     */
    'staffComments'?: string;
    /**
     * Optional comments from admin, visible only to admins
     * @type {string}
     * @memberof AbsencePersistRequest
     */
    'adminComments'?: string;
    /**
     * ID of the original leave request that led to this record, if applicable
     * @type {number}
     * @memberof AbsencePersistRequest
     */
    'leaveRequestId'?: number;
}
/**
 * A single absence record for a user, the top-level header for multiple days
 * @export
 * @interface AbsenceRecord
 */
export interface AbsenceRecord {
    /**
     * Unique ID for the record. 0 for new, positive number for existing.
     * @type {number}
     * @memberof AbsenceRecord
     */
    'id': number;
    /**
     * Unique ID (username) of the staff member the absence is for
     * @type {string}
     * @memberof AbsenceRecord
     */
    'staffId': string;
    /**
     * Name of staff member the absence is for
     * @type {string}
     * @memberof AbsenceRecord
     */
    'staffName': string;
    /**
     * The primary absence type ID, calculated from days and blocks
     * @type {number}
     * @memberof AbsenceRecord
     */
    'typeId': number;
    /**
     * Total number of minutes for the absence. Will be calculated based on days and blocks within days
     * @type {number}
     * @memberof AbsenceRecord
     */
    'mins': number;
    /**
     * Linux epoch for the first day of the absence, inclusive. Should be 00:00 local time
     * @type {number}
     * @memberof AbsenceRecord
     */
    'startDate': number;
    /**
     * Linux epoch for the last day of the absence, inclusive. Should be 00:00 local time
     * @type {number}
     * @memberof AbsenceRecord
     */
    'endDate': number;
    /**
     * User should / should not be paid for this day. Overrides type and blocks
     * @type {boolean}
     * @memberof AbsenceRecord
     */
    'paidOverride'?: boolean;
    /**
     * User should have this day taken from the leave allowance. Overrides type and blocks
     * @type {boolean}
     * @memberof AbsenceRecord
     */
    'leaveOverride'?: boolean;
    /**
     * User is available for other shifts on this day. Overrides type and blocks
     * @type {boolean}
     * @memberof AbsenceRecord
     */
    'availableForShiftsOverride'?: boolean;
    /**
     * Optional comments from staff member, visible to staff member
     * @type {string}
     * @memberof AbsenceRecord
     */
    'staffComments'?: string;
    /**
     * Optional comments from admin, visible only to admins
     * @type {string}
     * @memberof AbsenceRecord
     */
    'adminComments'?: string;
    /**
     * ID of the original leave request that led to this record, if applicable
     * @type {number}
     * @memberof AbsenceRecord
     */
    'leaveRequestId'?: number;
}
/**
 * Request from a user to take some time off
 * @export
 * @interface AbsenceRequest
 */
export interface AbsenceRequest {
    /**
     * Unique ID for the request
     * @type {number}
     * @memberof AbsenceRequest
     */
    'id': number;
    /**
     * Unique ID (username) of the staff member the absence is for
     * @type {string}
     * @memberof AbsenceRequest
     */
    'staffId': string;
    /**
     * Name of staff member the absence is for
     * @type {string}
     * @memberof AbsenceRequest
     */
    'staffName': string;
    /**
     * Linux epoch of the first day of the absence request, clamped to 00:00 local time. Inclusive.
     * @type {number}
     * @memberof AbsenceRequest
     */
    'startDate': number;
    /**
     * Linux epoch of the last day of the absence request, clamped to 00:00 local time. Inclusive.
     * @type {number}
     * @memberof AbsenceRequest
     */
    'endDate': number;
    /**
     * 
     * @type {AbsenceRequestStatus}
     * @memberof AbsenceRequest
     */
    'status': AbsenceRequestStatus;
    /**
     * Optional comments from staff member, visible to staff member
     * @type {string}
     * @memberof AbsenceRequest
     */
    'staffComments'?: string;
    /**
     * Optional comments from admin, visible only to admins
     * @type {string}
     * @memberof AbsenceRequest
     */
    'adminComments'?: string;
    /**
     * Linux epoch for when the request was accepted or rejected. Null if pending
     * @type {number}
     * @memberof AbsenceRequest
     */
    'acceptRejectDate'?: number;
    /**
     * Reason for accepting or rejecting the request. Null if pending
     * @type {string}
     * @memberof AbsenceRequest
     */
    'acceptRejectReason'?: string;
    /**
     * Unique ID (username) of the admin who accepted / rejected the request
     * @type {string}
     * @memberof AbsenceRequest
     */
    'adminId'?: string;
    /**
     * Name of the admin who accepted / rejected the request
     * @type {string}
     * @memberof AbsenceRequest
     */
    'adminName'?: string;
}


/**
 * Request absences that meet criteria
 * @export
 * @interface AbsenceRequestListRequest
 */
export interface AbsenceRequestListRequest {
    /**
     * Linux epoch for the minimum date that the request must be within, inclusive
     * @type {number}
     * @memberof AbsenceRequestListRequest
     */
    'startInclusive': number;
    /**
     * Linux epoch for the last date that the request must be within, exclusive
     * @type {number}
     * @memberof AbsenceRequestListRequest
     */
    'endExclusive': number;
    /**
     * Unique ID/username of the staff member the request must be for
     * @type {string}
     * @memberof AbsenceRequestListRequest
     */
    'staffId'?: string;
    /**
     * If specified, the request must be in one of these states
     * @type {Array<AbsenceRequestStatus>}
     * @memberof AbsenceRequestListRequest
     */
    'states'?: Array<AbsenceRequestStatus>;
}
/**
 * Absences that met criteria
 * @export
 * @interface AbsenceRequestListResponse
 */
export interface AbsenceRequestListResponse {
    /**
     * Linux epoch for the minimum date that the request must be within, inclusive
     * @type {number}
     * @memberof AbsenceRequestListResponse
     */
    'startInclusive'?: number;
    /**
     * Linux epoch for the last date that the request must be within, exclusive
     * @type {number}
     * @memberof AbsenceRequestListResponse
     */
    'endExclusive'?: number;
    /**
     * Unique ID/username of the staff member the request must be for
     * @type {string}
     * @memberof AbsenceRequestListResponse
     */
    'staffId'?: string;
    /**
     * If specified, the request must be in one of these states
     * @type {Array<AbsenceRequestStatus>}
     * @memberof AbsenceRequestListResponse
     */
    'states'?: Array<AbsenceRequestStatus>;
    /**
     * All absences that met the criteria
     * @type {Array<AbsenceRequest>}
     * @memberof AbsenceRequestListResponse
     */
    'results'?: Array<AbsenceRequest>;
}
/**
 * Possible status for an absence request
 * @export
 * @enum {string}
 */

export enum AbsenceRequestStatus {
    Pending = 'Pending',
    Accepted = 'Accepted',
    Rejected = 'Rejected',
    Cancelled = 'Cancelled'
}


/**
 * Type of absence for tracking purposes
 * @export
 * @enum {string}
 */

export enum AbsenceType {
    Holiday = 'Holiday',
    SickPaid = 'SickPaid',
    SickUnpaid = 'SickUnpaid',
    Unauthorised = 'Unauthorised'
}


/**
 * A possible absence type within the system
 * @export
 * @interface AbsenceType2
 */
export interface AbsenceType2 {
    /**
     * Unique ID for the type
     * @type {number}
     * @memberof AbsenceType2
     */
    'id': number;
    /**
     * Unique name for the absence
     * @type {string}
     * @memberof AbsenceType2
     */
    'name': string;
    /**
     * Hex code (including #) for the colour of the absence
     * @type {string}
     * @memberof AbsenceType2
     */
    'colour': string;
    /**
     * Unicode character for the icon of the absence
     * @type {string}
     * @memberof AbsenceType2
     */
    'icon': string;
    /**
     * Whether a staff member can select this absence type or not
     * @type {boolean}
     * @memberof AbsenceType2
     */
    'staffSelectable': boolean;
    /**
     * Whether the staff member should be paid for this absence
     * @type {boolean}
     * @memberof AbsenceType2
     */
    'paid': boolean;
    /**
     * Whether this absence is an acceptable absence or not
     * @type {boolean}
     * @memberof AbsenceType2
     */
    'authorised': boolean;
    /**
     * Whether the absence should be deducted from the staff member\'s holiday entitlement
     * @type {boolean}
     * @memberof AbsenceType2
     */
    'deductLeave': boolean;
    /**
     * Should the staff member still be shown as available for shifts even though there\'s an absence of this type active?
     * @type {boolean}
     * @memberof AbsenceType2
     */
    'availableForShifts': boolean;
    /**
     * Priority for this absence, higher numbers are higher priority. Used to adjust what the absence type is when multiple types are in play
     * @type {number}
     * @memberof AbsenceType2
     */
    'priority': number;
}
/**
 * Absence for a user, summarised for accurate information. No calculations required.
 * @export
 * @interface AbsenceView
 */
export interface AbsenceView {
    /**
     * Unique ID for the absence
     * @type {number}
     * @memberof AbsenceView
     */
    'id': number;
    /**
     * Unique ID (username) of the staff member the absence is for
     * @type {string}
     * @memberof AbsenceView
     */
    'staffId': string;
    /**
     * Name of staff member the absence is for
     * @type {string}
     * @memberof AbsenceView
     */
    'staffName': string;
    /**
     * ID of the leave request that triggered this absence, if applicable
     * @type {number}
     * @memberof AbsenceView
     */
    'leaveRequestId'?: number;
    /**
     * 
     * @type {AbsenceType2}
     * @memberof AbsenceView
     */
    'type': AbsenceType2;
    /**
     * Duration of leave for this absence in minutes, across all days. This will be for all types etc.
     * @type {number}
     * @memberof AbsenceView
     */
    'minutes': number;
    /**
     * Duration of leave for this absence in minutes, across all days. This only counts leave minutes and is NOT in addition to minutes, this is the portion of minutes that are for leave.
     * @type {number}
     * @memberof AbsenceView
     */
    'leaveMinutes': number;
    /**
     * Duration of paid leave for this absence in minutes, across all days. This only counts paid minutes and is NOT in addition to minutes, this is the portion of minutes that are paid.
     * @type {number}
     * @memberof AbsenceView
     */
    'paidMinutes': number;
    /**
     * Linux epoch for the first day of the absence, inclusive. Should be 00:00 local time
     * @type {number}
     * @memberof AbsenceView
     */
    'startDate': number;
    /**
     * Linux epoch for the last day of the absence, inclusive. Should be 00:00 local time
     * @type {number}
     * @memberof AbsenceView
     */
    'endDate': number;
    /**
     * Days that make up this absence
     * @type {Array<DayView>}
     * @memberof AbsenceView
     */
    'days': Array<DayView>;
}
/**
 * Simple object containing the absence header record, and all days within it
 * @export
 * @interface AbsenceWithDays
 */
export interface AbsenceWithDays {
    /**
     * 
     * @type {AbsenceRecord}
     * @memberof AbsenceWithDays
     */
    'absence': AbsenceRecord;
    /**
     * 
     * @type {Array<DayRecord>}
     * @memberof AbsenceWithDays
     */
    'days': Array<DayRecord>;
}
/**
 * Block within a day, summarised for accurate information. No calculations required.
 * @export
 * @interface BlockView
 */
export interface BlockView {
    /**
     * Number of minutes the block covers within the day
     * @type {number}
     * @memberof BlockView
     */
    'minutes': number;
    /**
     * 
     * @type {AbsenceType2}
     * @memberof BlockView
     */
    'type': AbsenceType2;
    /**
     * Staff member should be paid for this block
     * @type {boolean}
     * @memberof BlockView
     */
    'paid': boolean;
    /**
     * Staff member should have this block deducted from their leave allowance
     * @type {boolean}
     * @memberof BlockView
     */
    'leave': boolean;
    /**
     * Optional start time of the block, in minutes since midnight
     * @type {number}
     * @memberof BlockView
     */
    'startMins'?: number;
    /**
     * Optional end time of the block, in minutes since midnight
     * @type {number}
     * @memberof BlockView
     */
    'endMins'?: number;
}
/**
 * Send an SMS message to multiple staff members
 * @export
 * @interface BulkSMSRequest
 */
export interface BulkSMSRequest {
    /**
     * The IDs of the staff members to send the messages to
     * @type {Array<string>}
     * @memberof BulkSMSRequest
     */
    'staffIds': Array<string>;
    /**
     * Message to send to the staff members
     * @type {string}
     * @memberof BulkSMSRequest
     */
    'message': string;
    /**
     * Unique identifier for the user. This will almost always be a random string
     * @type {string}
     * @memberof BulkSMSRequest
     */
    'senderUsername': string;
    /**
     * Custom annotations for an object
     * @type {{ [key: string]: string; }}
     * @memberof BulkSMSRequest
     */
    'metadata': { [key: string]: string; };
}
/**
 * Request users with a specific username
 * @export
 * @interface ByUsernameListRequest
 */
export interface ByUsernameListRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ByUsernameListRequest
     */
    'usernames': Array<string>;
}
/**
 * Cancel a request for time off
 * @export
 * @interface CancelAbsenceRequest
 */
export interface CancelAbsenceRequest {
    /**
     * Unique ID of the original request
     * @type {number}
     * @memberof CancelAbsenceRequest
     */
    'id': number;
    /**
     * Optional reason for cancelling the request
     * @type {string}
     * @memberof CancelAbsenceRequest
     */
    'reason': string;
}
/**
 * What clinical grade the user is at. If none, use \'None\'
 * @export
 * @enum {string}
 */

export enum ClinicalGrade {
    Paramedic = 'Paramedic',
    Technician = 'Technician',
    FirstResponder = 'FirstResponder',
    None = 'None',
    EAC = 'EAC',
    Nurse = 'Nurse'
}


/**
 * Accept an absence request
 * @export
 * @interface CompleteAbsenceRequest
 */
export interface CompleteAbsenceRequest {
    /**
     * Unique ID of the original request
     * @type {number}
     * @memberof CompleteAbsenceRequest
     */
    'requestId': number;
    /**
     * Unique ID of the absence record that was created
     * @type {number}
     * @memberof CompleteAbsenceRequest
     */
    'absenceId': number;
    /**
     * Optional reason for accepting the request
     * @type {string}
     * @memberof CompleteAbsenceRequest
     */
    'reason'?: string;
}
/**
 * An absence within a day
 * @export
 * @interface DayBlock
 */
export interface DayBlock {
    /**
     * Total number of minutes this block of leave takes up
     * @type {number}
     * @memberof DayBlock
     */
    'mins': number;
    /**
     * ID of the absence type for this block
     * @type {number}
     * @memberof DayBlock
     */
    'typeId': number;
    /**
     * User should / should not be paid for this block
     * @type {boolean}
     * @memberof DayBlock
     */
    'paidOverride'?: boolean;
    /**
     * User should have this block taken from the leave allowance
     * @type {boolean}
     * @memberof DayBlock
     */
    'leaveOverride'?: boolean;
    /**
     * Optional start time of the block, in minutes since midnight
     * @type {number}
     * @memberof DayBlock
     */
    'startMins'?: number;
    /**
     * Optional end time of the block, in minutes since midnight
     * @type {number}
     * @memberof DayBlock
     */
    'endMins'?: number;
    /**
     * Optional comments from admin, visible only to admins
     * @type {string}
     * @memberof DayBlock
     */
    'adminComments'?: string;
}
/**
 * Single day of absence for a user
 * @export
 * @interface DayRecord
 */
export interface DayRecord {
    /**
     * Unique ID for this day record
     * @type {number}
     * @memberof DayRecord
     */
    'id': number;
    /**
     * ID of the containing absence this day is within
     * @type {number}
     * @memberof DayRecord
     */
    'absenceRecordId': number;
    /**
     * Unique ID (username) of the staff member the absence is for
     * @type {string}
     * @memberof DayRecord
     */
    'staffId': string;
    /**
     * Name of staff member the absence is for
     * @type {string}
     * @memberof DayRecord
     */
    'staffName': string;
    /**
     * Linux epoch for the day of the absence. Should be 00:00 local time
     * @type {number}
     * @memberof DayRecord
     */
    'date': number;
    /**
     * Individual blocks of absence within this day, must be at least one
     * @type {Array<DayBlock>}
     * @memberof DayRecord
     */
    'blocks': Array<DayBlock>;
    /**
     * User should / should not be paid for this day. Overrides type and blocks
     * @type {boolean}
     * @memberof DayRecord
     */
    'paidOverride'?: boolean;
    /**
     * User should have this day taken from the leave allowance. Overrides type and blocks
     * @type {boolean}
     * @memberof DayRecord
     */
    'leaveOverride'?: boolean;
    /**
     * User is available for other shifts on this day. Overrides type and blocks
     * @type {boolean}
     * @memberof DayRecord
     */
    'availableForShiftsOverride'?: boolean;
    /**
     * Optional comments from admin, visible only to admins
     * @type {string}
     * @memberof DayRecord
     */
    'adminComments'?: string;
}
/**
 * Single day to save to the DB
 * @export
 * @interface DayRecordPersistRequest
 */
export interface DayRecordPersistRequest {
    /**
     * Unique ID for this day record, 0 for new, positive for an existing
     * @type {number}
     * @memberof DayRecordPersistRequest
     */
    'id': number;
    /**
     * Linux epoch for the day of the absence. Should be 00:00 local time
     * @type {number}
     * @memberof DayRecordPersistRequest
     */
    'date': number;
    /**
     * Individual blocks of absence within this day, must be at least one
     * @type {Array<DayBlock>}
     * @memberof DayRecordPersistRequest
     */
    'blocks': Array<DayBlock>;
    /**
     * User should / should not be paid for this day. Overrides type and blocks
     * @type {boolean}
     * @memberof DayRecordPersistRequest
     */
    'paidOverride'?: boolean;
    /**
     * User should have this day taken from the leave allowance. Overrides type and blocks
     * @type {boolean}
     * @memberof DayRecordPersistRequest
     */
    'leaveOverride'?: boolean;
    /**
     * User is available for other shifts on this day. Overrides type and blocks
     * @type {boolean}
     * @memberof DayRecordPersistRequest
     */
    'availableForShiftsOverride'?: boolean;
    /**
     * Optional comments from admin, visible only to admins
     * @type {string}
     * @memberof DayRecordPersistRequest
     */
    'adminComments'?: string;
}
/**
 * Day within an absence, summarised for accurate information. No calculations required.
 * @export
 * @interface DayView
 */
export interface DayView {
    /**
     * Unique ID for the day
     * @type {number}
     * @memberof DayView
     */
    'id': number;
    /**
     * Linux epoch for the day of the absence. Should be 00:00 local time
     * @type {number}
     * @memberof DayView
     */
    'date': number;
    /**
     * Duration of leave for this day in minutes, across all blocks. This will be for all types etc.
     * @type {number}
     * @memberof DayView
     */
    'minutes': number;
    /**
     * Duration of leave for this day in minutes, across all blocks. This only counts leave minutes and is NOT in addition to minutes, this is the portion of minutes that are for leave.
     * @type {number}
     * @memberof DayView
     */
    'leaveMinutes': number;
    /**
     * Duration of paid leave for this day in minutes, across all blocks. This only counts paid minutes and is NOT in addition to minutes, this is the portion of minutes that are paid.
     * @type {number}
     * @memberof DayView
     */
    'paidMinutes': number;
    /**
     * Whether the staff member is available for shifts on this day
     * @type {boolean}
     * @memberof DayView
     */
    'availableForShifts': boolean;
    /**
     * 
     * @type {AbsenceType2}
     * @memberof DayView
     */
    'type': AbsenceType2;
    /**
     * Individual blocks that make up this day
     * @type {Array<BlockView>}
     * @memberof DayView
     */
    'blocks': Array<BlockView>;
}
/**
 * An error has occurred, and this object contains the detailed information
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'message': string;
}
/**
 * A single point on the Earth, in latitude and longitude
 * @export
 * @interface GeoLocation
 */
export interface GeoLocation {
    /**
     * 
     * @type {number}
     * @memberof GeoLocation
     */
    'latitude': number;
    /**
     * 
     * @type {number}
     * @memberof GeoLocation
     */
    'longitude': number;
}
/**
 * Get a list of all absences that meet the provided criteria
 * @export
 * @interface ListAbsencesRequest
 */
export interface ListAbsencesRequest {
    /**
     * Unique identifier for the user. This will almost always be a random string
     * @type {string}
     * @memberof ListAbsencesRequest
     */
    'username'?: string;
    /**
     * Linux epoch, the absence must be active during or after this time
     * @type {number}
     * @memberof ListAbsencesRequest
     */
    'startDate'?: number;
    /**
     * Linux epoch, the absence must be active during or before this time
     * @type {number}
     * @memberof ListAbsencesRequest
     */
    'endDate'?: number;
    /**
     * Years that the absences must be within
     * @type {Array<number>}
     * @memberof ListAbsencesRequest
     */
    'years'?: Array<number>;
    /**
     * The types of absence that should be returned. If blank or not specified, all absences will be returned.
     * @type {Array<AbsenceType>}
     * @memberof ListAbsencesRequest
     */
    'types'?: Array<AbsenceType>;
}
/**
 * Response to a request for absences
 * @export
 * @interface ListAbsencesResponse
 */
export interface ListAbsencesResponse {
    /**
     * The absences found that match the criteria
     * @type {Array<UserAbsence>}
     * @memberof ListAbsencesResponse
     */
    'absences': Array<UserAbsence>;
    /**
     * Unique identifier for the user. This will almost always be a random string
     * @type {string}
     * @memberof ListAbsencesResponse
     */
    'username'?: string;
    /**
     * Linux epoch, the absence must be active during or after this time
     * @type {number}
     * @memberof ListAbsencesResponse
     */
    'startDate'?: number;
    /**
     * Linux epoch, the absence must be active during or before this time
     * @type {number}
     * @memberof ListAbsencesResponse
     */
    'endDate'?: number;
    /**
     * Years that the absences must be within
     * @type {Array<number>}
     * @memberof ListAbsencesResponse
     */
    'years'?: Array<number>;
    /**
     * The types of absence that should be returned. If blank or not specified, all absences will be returned.
     * @type {Array<AbsenceType>}
     * @memberof ListAbsencesResponse
     */
    'types': Array<AbsenceType>;
}
/**
 * Request to get a list of all outstanding absence requests
 * @export
 * @interface ListUserAbsenceRequests
 */
export interface ListUserAbsenceRequests {
    /**
     * Linux epoch, inclusive
     * @type {number}
     * @memberof ListUserAbsenceRequests
     */
    'startDate': number;
    /**
     * Linux epoch, exclusive
     * @type {number}
     * @memberof ListUserAbsenceRequests
     */
    'endDate': number;
    /**
     * Optional usernames to filter to
     * @type {Array<string>}
     * @memberof ListUserAbsenceRequests
     */
    'usernames'?: Array<string>;
}
/**
 * Response to get a list of all outstanding absence requests
 * @export
 * @interface ListUserAbsenceRequestsResponse
 */
export interface ListUserAbsenceRequestsResponse {
    /**
     * Found requests
     * @type {Array<UserAbsenceRequest>}
     * @memberof ListUserAbsenceRequestsResponse
     */
    'requests'?: Array<UserAbsenceRequest>;
    /**
     * Linux epoch, inclusive
     * @type {number}
     * @memberof ListUserAbsenceRequestsResponse
     */
    'startDate': number;
    /**
     * Linux epoch, exclusive
     * @type {number}
     * @memberof ListUserAbsenceRequestsResponse
     */
    'endDate': number;
    /**
     * Optional usernames to filter to
     * @type {Array<string>}
     * @memberof ListUserAbsenceRequestsResponse
     */
    'usernames'?: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum MfaType {
    None = 'None',
    Sms = 'Sms',
    Totp = 'Totp'
}


/**
 * Request time off
 * @export
 * @interface NewAbsenceRequest
 */
export interface NewAbsenceRequest {
    /**
     * Linux epoch of the first day of the absence request, clamped to 00:00 local time. Inclusive.
     * @type {number}
     * @memberof NewAbsenceRequest
     */
    'startDate': number;
    /**
     * Linux epoch of the last day of the absence request, clamped to 00:00 local time. Inclusive.
     * @type {number}
     * @memberof NewAbsenceRequest
     */
    'endDate': number;
    /**
     * Optional comments from staff member, visible to staff member
     * @type {string}
     * @memberof NewAbsenceRequest
     */
    'comments'?: string;
}
/**
 * Request to add a new user to the system
 * @export
 * @interface NewUser
 */
export interface NewUser {
    /**
     * Email address for the user. This can be used to uniquely identify a specific user.
     * @type {string}
     * @memberof NewUser
     */
    'email': string;
    /**
     * User\'s first name
     * @type {string}
     * @memberof NewUser
     */
    'firstName': string;
    /**
     * User\'s surname
     * @type {string}
     * @memberof NewUser
     */
    'lastName': string;
    /**
     * User\'s **mobile** telephone number in international format: +447777777777
     * @type {string}
     * @memberof NewUser
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {StaffAccessLevel}
     * @memberof NewUser
     */
    'accessLevel': StaffAccessLevel;
    /**
     * 
     * @type {ClinicalGrade}
     * @memberof NewUser
     */
    'clinicalGrade': ClinicalGrade;
    /**
     * User\'s postcode, optional
     * @type {string}
     * @memberof NewUser
     */
    'postCode'?: string;
    /**
     * Whether the user is required to login with MFA (Multifactor Authentication) or not (false)
     * @type {boolean}
     * @memberof NewUser
     */
    'mfa'?: boolean;
    /**
     * Whether the user has TOTP enabled
     * @type {boolean}
     * @memberof NewUser
     */
    'totp'?: boolean;
    /**
     * 
     * @type {MfaType}
     * @memberof NewUser
     */
    'favouredMfa'?: MfaType;
    /**
     * Optional list of flags that the user has applied to them. These flags are decoupled from \'PossibleFlags\' and will not be amended when the main Possible Flags table is amended.
     * @type {Array<string>}
     * @memberof NewUser
     */
    'flags'?: Array<string>;
    /**
     * User is on PAYE instead of contractor
     * @type {boolean}
     * @memberof NewUser
     */
    'paye': boolean;
    /**
     * Optional region IDs that the staff member is assigned to
     * @type {Array<number>}
     * @memberof NewUser
     */
    'regions'?: Array<number>;
    /**
     * Optional access level overrides for specific modules
     * @type {{ [key: string]: StaffAccessLevel; }}
     * @memberof NewUser
     */
    'accessDetail'?: { [key: string]: StaffAccessLevel; };
    /**
     * User is a super admin and has more access. Only SystemAdministrator at GLOBAL level can set this to true.
     * @type {boolean}
     * @memberof NewUser
     */
    'superAdmin'?: boolean;
}


/**
 * A \'flag\' value that can be added to the user
 * @export
 * @interface PossibleFlag
 */
export interface PossibleFlag {
    /**
     * The flag name
     * @type {string}
     * @memberof PossibleFlag
     */
    'name': string;
}
/**
 * Geographical region that things can be assigned to
 * @export
 * @interface Region
 */
export interface Region {
    /**
     * Unique ID for the region
     * @type {number}
     * @memberof Region
     */
    'id': number;
    /**
     * Name of the region
     * @type {string}
     * @memberof Region
     */
    'name': string;
    /**
     * Postcode for the region
     * @type {string}
     * @memberof Region
     */
    'postcode': string;
    /**
     * Whether this region is archived and shouldn\'t appear as an option, but is still present for naming purposes
     * @type {boolean}
     * @memberof Region
     */
    'archived': boolean;
}
/**
 * Reject a request for time off
 * @export
 * @interface RejectAbsenceRequest
 */
export interface RejectAbsenceRequest {
    /**
     * Unique ID of the original request
     * @type {number}
     * @memberof RejectAbsenceRequest
     */
    'id': number;
    /**
     * Optional reason for rejecting the request
     * @type {string}
     * @memberof RejectAbsenceRequest
     */
    'reason': string;
}
/**
 * Set an explicit password for a user
 * @export
 * @interface SetPasswordRequest
 */
export interface SetPasswordRequest {
    /**
     * The new password to set
     * @type {string}
     * @memberof SetPasswordRequest
     */
    'password': string;
    /**
     * Whether the password is set forever (true), or if the user must replace it on first login (false)
     * @type {boolean}
     * @memberof SetPasswordRequest
     */
    'permanent': boolean;
}
/**
 * Historic record for a bulk SMS request
 * @export
 * @interface SmsHistory
 */
export interface SmsHistory {
    /**
     * Unique ID for the request
     * @type {number}
     * @memberof SmsHistory
     */
    'id': number;
    /**
     * Unique identifier for the user. This will almost always be a random string
     * @type {string}
     * @memberof SmsHistory
     */
    'username': string;
    /**
     * Linux timestamp for when the message was sent
     * @type {number}
     * @memberof SmsHistory
     */
    'date': number;
    /**
     * Raw message sent
     * @type {string}
     * @memberof SmsHistory
     */
    'message': string;
    /**
     * People who were sent the message. No guarantees it was successfully sent, or the recipient received the message
     * @type {Array<SmsHistoryRecipient>}
     * @memberof SmsHistory
     */
    'recipients': Array<SmsHistoryRecipient>;
    /**
     * Custom annotations for an object
     * @type {{ [key: string]: string; }}
     * @memberof SmsHistory
     */
    'metadata': { [key: string]: string; };
}
/**
 * Single recipient of an SMS message
 * @export
 * @interface SmsHistoryRecipient
 */
export interface SmsHistoryRecipient {
    /**
     * Unique identifier for the user. This will almost always be a random string
     * @type {string}
     * @memberof SmsHistoryRecipient
     */
    'username': string;
    /**
     * User\'s full name
     * @type {string}
     * @memberof SmsHistoryRecipient
     */
    'name': string;
    /**
     * User\'s **mobile** telephone number in international format: +447777777777
     * @type {string}
     * @memberof SmsHistoryRecipient
     */
    'phone': string;
}
/**
 * Request for SMS logs for the given criteria/filters
 * @export
 * @interface SmsHistoryRequest
 */
export interface SmsHistoryRequest {
    /**
     * Page number
     * @type {number}
     * @memberof SmsHistoryRequest
     */
    'pageNum': number;
    /**
     * The number of items per page
     * @type {number}
     * @memberof SmsHistoryRequest
     */
    'numPerPage': number;
    /**
     * Earliest date sent, inclusive
     * @type {number}
     * @memberof SmsHistoryRequest
     */
    'startDateInclusive'?: number;
    /**
     * Earliest date sent, exclusive
     * @type {number}
     * @memberof SmsHistoryRequest
     */
    'endDateExclusive'?: number;
    /**
     * Custom annotations for an object
     * @type {{ [key: string]: string; }}
     * @memberof SmsHistoryRequest
     */
    'metadata'?: { [key: string]: string; };
    /**
     * 
     * @type {SmsHistoryRequestRecipient}
     * @memberof SmsHistoryRequest
     */
    'recipient'?: SmsHistoryRequestRecipient;
    /**
     * 
     * @type {SmsHistoryRequestSender}
     * @memberof SmsHistoryRequest
     */
    'sender'?: SmsHistoryRequestSender;
}
/**
 * Recipient filter for the SMS message
 * @export
 * @interface SmsHistoryRequestRecipient
 */
export interface SmsHistoryRequestRecipient {
    /**
     * Unique identifier for the user. This will almost always be a random string
     * @type {string}
     * @memberof SmsHistoryRequestRecipient
     */
    'username'?: string;
    /**
     * User\'s full name
     * @type {string}
     * @memberof SmsHistoryRequestRecipient
     */
    'name'?: string;
    /**
     * User\'s **mobile** telephone number in international format: +447777777777
     * @type {string}
     * @memberof SmsHistoryRequestRecipient
     */
    'phone'?: string;
}
/**
 * Sender filter for the SMS message
 * @export
 * @interface SmsHistoryRequestSender
 */
export interface SmsHistoryRequestSender {
    /**
     * Unique identifier for the user. This will almost always be a random string
     * @type {string}
     * @memberof SmsHistoryRequestSender
     */
    'username'?: string;
}
/**
 * SMS logs that matched the search critieria
 * @export
 * @interface SmsHistoryResponse
 */
export interface SmsHistoryResponse {
    /**
     * Page number
     * @type {number}
     * @memberof SmsHistoryResponse
     */
    'pageNum': number;
    /**
     * The number of items per page
     * @type {number}
     * @memberof SmsHistoryResponse
     */
    'numPerPage': number;
    /**
     * Total number of matching entries in the entire DB
     * @type {number}
     * @memberof SmsHistoryResponse
     */
    'totalEntries': number;
    /**
     * SMS logs for this page of data
     * @type {Array<SmsHistory>}
     * @memberof SmsHistoryResponse
     */
    'entries': Array<SmsHistory>;
    /**
     * Earliest date sent, inclusive
     * @type {number}
     * @memberof SmsHistoryResponse
     */
    'startDateInclusive'?: number;
    /**
     * Earliest date sent, exclusive
     * @type {number}
     * @memberof SmsHistoryResponse
     */
    'endDateExclusive'?: number;
    /**
     * Custom annotations for an object
     * @type {{ [key: string]: string; }}
     * @memberof SmsHistoryResponse
     */
    'metadata': { [key: string]: string; };
    /**
     * 
     * @type {SmsHistoryRequestRecipient}
     * @memberof SmsHistoryResponse
     */
    'recipient'?: SmsHistoryRequestRecipient;
    /**
     * 
     * @type {SmsHistoryRequestSender}
     * @memberof SmsHistoryResponse
     */
    'sender'?: SmsHistoryRequestSender;
}
/**
 * The role the user belongs to. If unknown or not applicable use \'Staff\' this is the lowest level of access
 * @export
 * @enum {string}
 */

export enum StaffAccessLevel {
    SystemAdministrator = 'SystemAdministrator',
    DutyManager = 'DutyManager',
    Staff = 'Staff'
}


/**
 * Associate a TOTP app with a user
 * @export
 * @interface TotpTokenAssociate
 */
export interface TotpTokenAssociate {
    /**
     * The secret code to go into the authenticator app
     * @type {string}
     * @memberof TotpTokenAssociate
     */
    'secretCode': string;
    /**
     * The QR code content for convenience, contains the secret code
     * @type {string}
     * @memberof TotpTokenAssociate
     */
    'qrCode': string;
}
/**
 * Verify TOTP link up
 * @export
 * @interface TotpTokenVerify
 */
export interface TotpTokenVerify {
    /**
     * Generated code from authenticator
     * @type {string}
     * @memberof TotpTokenVerify
     */
    'totpCode': string;
}
/**
 * Response from TOTP verification, ie. if the auth app successfully linked. If the link fails, it must be started from scratch.
 * @export
 * @interface TotpVerifyResponse
 */
export interface TotpVerifyResponse {
    /**
     * If the link was successful or not
     * @type {boolean}
     * @memberof TotpVerifyResponse
     */
    'success': boolean;
    /**
     * If not successful, a message explaining why
     * @type {string}
     * @memberof TotpVerifyResponse
     */
    'message'?: string;
}
/**
 * Single absence for a user
 * @export
 * @interface UserAbsence
 */
export interface UserAbsence {
    /**
     * Unique ID for this absence.
     * @type {number}
     * @memberof UserAbsence
     */
    'id'?: number;
    /**
     * Unique identifier for the user. This will almost always be a random string
     * @type {string}
     * @memberof UserAbsence
     */
    'username': string;
    /**
     * Name of the user
     * @type {string}
     * @memberof UserAbsence
     */
    'name'?: string;
    /**
     * 
     * @type {AbsenceType}
     * @memberof UserAbsence
     */
    'type': AbsenceType;
    /**
     * Optional description for this specific absence
     * @type {string}
     * @memberof UserAbsence
     */
    'reason'?: string;
    /**
     * Linux epoch, inclusive, for the start of the absence
     * @type {number}
     * @memberof UserAbsence
     */
    'startDate': number;
    /**
     * Linux epoch, exclusive, for the end of the absence
     * @type {number}
     * @memberof UserAbsence
     */
    'endDate': number;
    /**
     * Optional number of days this absence is considered to be. Will be automatically calculated if not specified.
     * @type {number}
     * @memberof UserAbsence
     */
    'numberDays'?: number;
}


/**
 * Full absence config for a user
 * @export
 * @interface UserAbsenceConfig
 */
export interface UserAbsenceConfig {
    /**
     * 
     * @type {UserAbsenceConfigDefault}
     * @memberof UserAbsenceConfig
     */
    'default': UserAbsenceConfigDefault;
    /**
     * Specific absence config for years, if any
     * @type {Array<UserAbsenceConfigYear>}
     * @memberof UserAbsenceConfig
     */
    'years': Array<UserAbsenceConfigYear>;
}
/**
 * Default user absence configuration, if no specific year configuration provided
 * @export
 * @interface UserAbsenceConfigDefault
 */
export interface UserAbsenceConfigDefault {
    /**
     * Unique identifier for the user. This will almost always be a random string
     * @type {string}
     * @memberof UserAbsenceConfigDefault
     */
    'username': string;
    /**
     * Default number of days holiday the user has
     * @type {number}
     * @memberof UserAbsenceConfigDefault
     */
    'defaultHolidayLimit': number;
}
/**
 * Unique identifier for a users absence config for a specific year
 * @export
 * @interface UserAbsenceConfigKey
 */
export interface UserAbsenceConfigKey {
    /**
     * Unique identifier for the user. This will almost always be a random string
     * @type {string}
     * @memberof UserAbsenceConfigKey
     */
    'username': string;
    /**
     * The year the configuration is for
     * @type {number}
     * @memberof UserAbsenceConfigKey
     */
    'year': number;
}
/**
 * User absence configuration for a specific year
 * @export
 * @interface UserAbsenceConfigYear
 */
export interface UserAbsenceConfigYear {
    /**
     * Unique identifier for the user. This will almost always be a random string
     * @type {string}
     * @memberof UserAbsenceConfigYear
     */
    'username': string;
    /**
     * The year the configuration is for
     * @type {number}
     * @memberof UserAbsenceConfigYear
     */
    'year': number;
    /**
     * Default number of days holiday the user has
     * @type {number}
     * @memberof UserAbsenceConfigYear
     */
    'holidayLimit': number;
}
/**
 * Request some time off for yourself
 * @export
 * @interface UserAbsenceInitialRequest
 */
export interface UserAbsenceInitialRequest {
    /**
     * Optional description for this specific absence
     * @type {string}
     * @memberof UserAbsenceInitialRequest
     */
    'reason'?: string;
    /**
     * Linux epoch, inclusive, for the start of the absence
     * @type {number}
     * @memberof UserAbsenceInitialRequest
     */
    'startDate': number;
    /**
     * Linux epoch, exclusive, for the end of the absence
     * @type {number}
     * @memberof UserAbsenceInitialRequest
     */
    'endDate': number;
}
/**
 * User requesting some time off / holiday
 * @export
 * @interface UserAbsenceRequest
 */
export interface UserAbsenceRequest {
    /**
     * Unique ID for this request
     * @type {number}
     * @memberof UserAbsenceRequest
     */
    'id': number;
    /**
     * Unique identifier for the user. This will almost always be a random string
     * @type {string}
     * @memberof UserAbsenceRequest
     */
    'username': string;
    /**
     * Name of the user, in case the user can\'t be found in the main user table
     * @type {string}
     * @memberof UserAbsenceRequest
     */
    'name': string;
    /**
     * Optional description for this specific absence
     * @type {string}
     * @memberof UserAbsenceRequest
     */
    'reason'?: string;
    /**
     * 
     * @type {UserAbsenceRequestStatus}
     * @memberof UserAbsenceRequest
     */
    'status': UserAbsenceRequestStatus;
    /**
     * Linux epoch, inclusive, for the start of the absence
     * @type {number}
     * @memberof UserAbsenceRequest
     */
    'startDate': number;
    /**
     * Linux epoch, exclusive, for the end of the absence
     * @type {number}
     * @memberof UserAbsenceRequest
     */
    'endDate': number;
}


/**
 * Status of a request, for example approved / rejected.
 * @export
 * @enum {string}
 */

export enum UserAbsenceRequestStatus {
    PendingApproval = 'PendingApproval',
    Approved = 'Approved',
    Rejected = 'Rejected'
}


/**
 * Get users that match the criteria, returning location data
 * @export
 * @interface UserComplexRequest
 */
export interface UserComplexRequest {
    /**
     * Optional list of region IDs that the users can be within. This is an OR operation instead of AND.
     * @type {Array<number>}
     * @memberof UserComplexRequest
     */
    'regions'?: Array<number>;
}
/**
 * Stats on total user counts
 * @export
 * @interface UserCount
 */
export interface UserCount {
    /**
     * Total number of users in the system
     * @type {number}
     * @memberof UserCount
     */
    'totalUsers': number;
}
/**
 * Full information on a user
 * @export
 * @interface UserData
 */
export interface UserData {
    /**
     * Unique identifier for the user. This will almost always be a random string
     * @type {string}
     * @memberof UserData
     */
    'username': string;
    /**
     * Email address for the user. This can be used to uniquely identify a specific user.
     * @type {string}
     * @memberof UserData
     */
    'email': string;
    /**
     * User\'s first name
     * @type {string}
     * @memberof UserData
     */
    'firstName': string;
    /**
     * User\'s surname
     * @type {string}
     * @memberof UserData
     */
    'lastName': string;
    /**
     * User\'s **mobile** telephone number in international format: +447777777777
     * @type {string}
     * @memberof UserData
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {StaffAccessLevel}
     * @memberof UserData
     */
    'accessLevel': StaffAccessLevel;
    /**
     * 
     * @type {ClinicalGrade}
     * @memberof UserData
     */
    'clinicalGrade': ClinicalGrade;
    /**
     * User\'s postcode, optional
     * @type {string}
     * @memberof UserData
     */
    'postCode'?: string;
    /**
     * Whether the user is required to login with MFA (Multifactor Authentication) or not (false)
     * @type {boolean}
     * @memberof UserData
     */
    'mfa'?: boolean;
    /**
     * Whether the user has TOTP enabled
     * @type {boolean}
     * @memberof UserData
     */
    'totp'?: boolean;
    /**
     * 
     * @type {MfaType}
     * @memberof UserData
     */
    'favouredMfa'?: MfaType;
    /**
     * Optional list of flags that the user has applied to them. These flags are decoupled from \'PossibleFlags\' and will not be amended when the main Possible Flags table is amended.
     * @type {Array<string>}
     * @memberof UserData
     */
    'flags'?: Array<string>;
    /**
     * User is on PAYE instead of contractor
     * @type {boolean}
     * @memberof UserData
     */
    'paye': boolean;
    /**
     * Optional region IDs that the staff member is assigned to
     * @type {Array<number>}
     * @memberof UserData
     */
    'regions'?: Array<number>;
    /**
     * 
     * @type {GeoLocation}
     * @memberof UserData
     */
    'location'?: GeoLocation;
    /**
     * Optional access level overrides for specific modules
     * @type {{ [key: string]: StaffAccessLevel; }}
     * @memberof UserData
     */
    'accessDetail'?: { [key: string]: StaffAccessLevel; };
    /**
     * User is a super admin and has more access. Only SystemAdministrator at GLOBAL level can set this to true.
     * @type {boolean}
     * @memberof UserData
     */
    'superAdmin'?: boolean;
}


/**
 * Request to upload new / updated documentation for a user
 * @export
 * @interface UserDocUploadRequest
 */
export interface UserDocUploadRequest {
    /**
     * Unique ID for the document. 0 or less to insert a new document.
     * @type {number}
     * @memberof UserDocUploadRequest
     */
    'id': number;
    /**
     * Username of the user to upload this document for
     * @type {string}
     * @memberof UserDocUploadRequest
     */
    'username': string;
    /**
     * Friendly name (possibly filename) for this document
     * @type {string}
     * @memberof UserDocUploadRequest
     */
    'name': string;
    /**
     * Raw filename for the file such as \'myfile.txt\'
     * @type {string}
     * @memberof UserDocUploadRequest
     */
    'filename': string;
    /**
     * The MIME type for the file, such as \'application/json\', or \'text/plain\'
     * @type {string}
     * @memberof UserDocUploadRequest
     */
    'mimetype': string;
    /**
     * Optional BASE64 ENCODED file content. REQUIRED if the ID is <= 0 (fresh insert)
     * @type {string}
     * @memberof UserDocUploadRequest
     */
    'content'?: string;
    /**
     * Optional Linux epoch when the document expires
     * @type {number}
     * @memberof UserDocUploadRequest
     */
    'expiry'?: number;
}
/**
 * Detailed information about a document tied to a user
 * @export
 * @interface UserDocumentation
 */
export interface UserDocumentation {
    /**
     * Unique ID for the document. 0 or less to insert a new document.
     * @type {number}
     * @memberof UserDocumentation
     */
    'id': number;
    /**
     * Username of the user to upload this document for
     * @type {string}
     * @memberof UserDocumentation
     */
    'username': string;
    /**
     * Friendly name (possibly filename) for this document
     * @type {string}
     * @memberof UserDocumentation
     */
    'name': string;
    /**
     * Raw filename for the file such as \'myfile.txt\'
     * @type {string}
     * @memberof UserDocumentation
     */
    'filename': string;
    /**
     * The MIME type for the file, such as \'application/json\', or \'text/plain\'
     * @type {string}
     * @memberof UserDocumentation
     */
    'mimetype': string;
    /**
     * Linux epoch for when the document was uploaded
     * @type {number}
     * @memberof UserDocumentation
     */
    'uploaded': number;
    /**
     * Optional Linux epoch when the document expires
     * @type {number}
     * @memberof UserDocumentation
     */
    'expiry'?: number;
}
/**
 * Quick overview of user\'s earliest document expiry date
 * @export
 * @interface UserExpiry
 */
export interface UserExpiry {
    /**
     * User\'s username
     * @type {string}
     * @memberof UserExpiry
     */
    'username': string;
    /**
     * Linux epoch of when the earliest document expires
     * @type {string}
     * @memberof UserExpiry
     */
    'expiry': string;
}

/**
 * AbsenceApi - axios parameter creator
 * @export
 */
export const AbsenceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary System admin only. Accept a user\'s absence request
         * @param {AbsenceApprovalResponse} absenceApprovalResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptAbsenceRequest: async (absenceApprovalResponse: AbsenceApprovalResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'absenceApprovalResponse' is not null or undefined
            assertParamExists('acceptAbsenceRequest', 'absenceApprovalResponse', absenceApprovalResponse)
            const localVarPath = `/v1/absence/user/request/accept`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(absenceApprovalResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary System admin only. Delete a specific absence from the system
         * @param {number} id Unique ID for the absence
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAbsence: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAbsence', 'id', id)
            const localVarPath = `/v1/absence/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Admin only. Delete the absence configuration for a specific user for a specific year
         * @param {UserAbsenceConfigKey} userAbsenceConfigKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserAbsenceConfigYear: async (userAbsenceConfigKey: UserAbsenceConfigKey, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userAbsenceConfigKey' is not null or undefined
            assertParamExists('deleteUserAbsenceConfigYear', 'userAbsenceConfigKey', userAbsenceConfigKey)
            const localVarPath = `/v1/absence/config/user/year/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAbsenceConfigKey, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary System admin only. Get a specific absence from the system
         * @param {number} id Unique ID for the absence
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAbsence: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAbsence', 'id', id)
            const localVarPath = `/v1/absence/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Admin only. Get the stats to render a dashboard
         * @param {AbsenceDashboardStatsRequest} absenceDashboardStatsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAbsenceDashStats: async (absenceDashboardStatsRequest: AbsenceDashboardStatsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'absenceDashboardStatsRequest' is not null or undefined
            assertParamExists('getAbsenceDashStats', 'absenceDashboardStatsRequest', absenceDashboardStatsRequest)
            const localVarPath = `/v1/absence/stats/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(absenceDashboardStatsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Admin only. Get the absence config for a single user
         * @param {string} username Username to get the info for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAbsenceConfig: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getUserAbsenceConfig', 'username', username)
            const localVarPath = `/v1/absence/config/user/info/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Admin only. Get the absence configuration for a specific user for a specific year
         * @param {UserAbsenceConfigKey} userAbsenceConfigKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAbsenceConfigYear: async (userAbsenceConfigKey: UserAbsenceConfigKey, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userAbsenceConfigKey' is not null or undefined
            assertParamExists('getUserAbsenceConfigYear', 'userAbsenceConfigKey', userAbsenceConfigKey)
            const localVarPath = `/v1/absence/config/user/year/single`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAbsenceConfigKey, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List the absences currently pending approval or rejection. Staff will automatically populate usernames field with the user\'s username restricting the list to themselves. Admins can populate any usernames, or even none.
         * @param {ListUserAbsenceRequests} listUserAbsenceRequests 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAbsenceRequests: async (listUserAbsenceRequests: ListUserAbsenceRequests, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listUserAbsenceRequests' is not null or undefined
            assertParamExists('listAbsenceRequests', 'listUserAbsenceRequests', listUserAbsenceRequests)
            const localVarPath = `/v1/absence/user/request/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listUserAbsenceRequests, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of absences that meet a specific criteria. Will be capped to 10,000 absences. If the count exceeds this, only the first 10,000 will be returned. Staff will only be able to see their own absences.
         * @param {ListAbsencesRequest} listAbsencesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAbsences: async (listAbsencesRequest: ListAbsencesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listAbsencesRequest' is not null or undefined
            assertParamExists('listAbsences', 'listAbsencesRequest', listAbsencesRequest)
            const localVarPath = `/v1/absence/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listAbsencesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary System admin only. Reject a user\'s absence request
         * @param {AbsenceApprovalResponse} absenceApprovalResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectAbsenceRequest: async (absenceApprovalResponse: AbsenceApprovalResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'absenceApprovalResponse' is not null or undefined
            assertParamExists('rejectAbsenceRequest', 'absenceApprovalResponse', absenceApprovalResponse)
            const localVarPath = `/v1/absence/user/request/reject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(absenceApprovalResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Staff and above. Request some time off for currently logged in user
         * @param {UserAbsenceInitialRequest} userAbsenceInitialRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestAbsence: async (userAbsenceInitialRequest: UserAbsenceInitialRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userAbsenceInitialRequest' is not null or undefined
            assertParamExists('requestAbsence', 'userAbsenceInitialRequest', userAbsenceInitialRequest)
            const localVarPath = `/v1/absence/user/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAbsenceInitialRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Admin only. Save an absence to the system. If the ID is 0, the absence will be inserted. Otherwise the existing absence will be updated. Absences cannot overlap date ranges.
         * @param {UserAbsence} userAbsence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAbsence: async (userAbsence: UserAbsence, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userAbsence' is not null or undefined
            assertParamExists('saveAbsence', 'userAbsence', userAbsence)
            const localVarPath = `/v1/absence/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAbsence, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Admin only. Set the defaults for a user\'s absence configuration, if not overriden by a year config
         * @param {UserAbsenceConfigDefault} userAbsenceConfigDefault 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserDefaultAbsenceConfig: async (userAbsenceConfigDefault: UserAbsenceConfigDefault, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userAbsenceConfigDefault' is not null or undefined
            assertParamExists('setUserDefaultAbsenceConfig', 'userAbsenceConfigDefault', userAbsenceConfigDefault)
            const localVarPath = `/v1/absence/config/user/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAbsenceConfigDefault, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Admin only. Set the a user\'s absence configuration for a specific year. If the year matches an existing year, the existing record will be updated.
         * @param {UserAbsenceConfigYear} userAbsenceConfigYear 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserYearAbsenceConfig: async (userAbsenceConfigYear: UserAbsenceConfigYear, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userAbsenceConfigYear' is not null or undefined
            assertParamExists('setUserYearAbsenceConfig', 'userAbsenceConfigYear', userAbsenceConfigYear)
            const localVarPath = `/v1/absence/config/user/year`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAbsenceConfigYear, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AbsenceApi - functional programming interface
 * @export
 */
export const AbsenceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AbsenceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary System admin only. Accept a user\'s absence request
         * @param {AbsenceApprovalResponse} absenceApprovalResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptAbsenceRequest(absenceApprovalResponse: AbsenceApprovalResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAbsence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptAbsenceRequest(absenceApprovalResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary System admin only. Delete a specific absence from the system
         * @param {number} id Unique ID for the absence
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAbsence(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAbsence(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Admin only. Delete the absence configuration for a specific user for a specific year
         * @param {UserAbsenceConfigKey} userAbsenceConfigKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserAbsenceConfigYear(userAbsenceConfigKey: UserAbsenceConfigKey, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserAbsenceConfigYear(userAbsenceConfigKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary System admin only. Get a specific absence from the system
         * @param {number} id Unique ID for the absence
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAbsence(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAbsence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAbsence(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Admin only. Get the stats to render a dashboard
         * @param {AbsenceDashboardStatsRequest} absenceDashboardStatsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAbsenceDashStats(absenceDashboardStatsRequest: AbsenceDashboardStatsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbsenceDashboardStatsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAbsenceDashStats(absenceDashboardStatsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Admin only. Get the absence config for a single user
         * @param {string} username Username to get the info for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserAbsenceConfig(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAbsenceConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAbsenceConfig(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Admin only. Get the absence configuration for a specific user for a specific year
         * @param {UserAbsenceConfigKey} userAbsenceConfigKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserAbsenceConfigYear(userAbsenceConfigKey: UserAbsenceConfigKey, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAbsenceConfigYear>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAbsenceConfigYear(userAbsenceConfigKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List the absences currently pending approval or rejection. Staff will automatically populate usernames field with the user\'s username restricting the list to themselves. Admins can populate any usernames, or even none.
         * @param {ListUserAbsenceRequests} listUserAbsenceRequests 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAbsenceRequests(listUserAbsenceRequests: ListUserAbsenceRequests, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListUserAbsenceRequestsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAbsenceRequests(listUserAbsenceRequests, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of absences that meet a specific criteria. Will be capped to 10,000 absences. If the count exceeds this, only the first 10,000 will be returned. Staff will only be able to see their own absences.
         * @param {ListAbsencesRequest} listAbsencesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAbsences(listAbsencesRequest: ListAbsencesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAbsencesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAbsences(listAbsencesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary System admin only. Reject a user\'s absence request
         * @param {AbsenceApprovalResponse} absenceApprovalResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectAbsenceRequest(absenceApprovalResponse: AbsenceApprovalResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAbsenceRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rejectAbsenceRequest(absenceApprovalResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Staff and above. Request some time off for currently logged in user
         * @param {UserAbsenceInitialRequest} userAbsenceInitialRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestAbsence(userAbsenceInitialRequest: UserAbsenceInitialRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAbsenceRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestAbsence(userAbsenceInitialRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Admin only. Save an absence to the system. If the ID is 0, the absence will be inserted. Otherwise the existing absence will be updated. Absences cannot overlap date ranges.
         * @param {UserAbsence} userAbsence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveAbsence(userAbsence: UserAbsence, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAbsence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveAbsence(userAbsence, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Admin only. Set the defaults for a user\'s absence configuration, if not overriden by a year config
         * @param {UserAbsenceConfigDefault} userAbsenceConfigDefault 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUserDefaultAbsenceConfig(userAbsenceConfigDefault: UserAbsenceConfigDefault, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAbsenceConfigDefault>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUserDefaultAbsenceConfig(userAbsenceConfigDefault, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Admin only. Set the a user\'s absence configuration for a specific year. If the year matches an existing year, the existing record will be updated.
         * @param {UserAbsenceConfigYear} userAbsenceConfigYear 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUserYearAbsenceConfig(userAbsenceConfigYear: UserAbsenceConfigYear, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAbsenceConfigYear>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUserYearAbsenceConfig(userAbsenceConfigYear, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AbsenceApi - factory interface
 * @export
 */
export const AbsenceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AbsenceApiFp(configuration)
    return {
        /**
         * 
         * @summary System admin only. Accept a user\'s absence request
         * @param {AbsenceApprovalResponse} absenceApprovalResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptAbsenceRequest(absenceApprovalResponse: AbsenceApprovalResponse, options?: any): AxiosPromise<UserAbsence> {
            return localVarFp.acceptAbsenceRequest(absenceApprovalResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary System admin only. Delete a specific absence from the system
         * @param {number} id Unique ID for the absence
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAbsence(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAbsence(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Admin only. Delete the absence configuration for a specific user for a specific year
         * @param {UserAbsenceConfigKey} userAbsenceConfigKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserAbsenceConfigYear(userAbsenceConfigKey: UserAbsenceConfigKey, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserAbsenceConfigYear(userAbsenceConfigKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary System admin only. Get a specific absence from the system
         * @param {number} id Unique ID for the absence
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAbsence(id: number, options?: any): AxiosPromise<UserAbsence> {
            return localVarFp.getAbsence(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Admin only. Get the stats to render a dashboard
         * @param {AbsenceDashboardStatsRequest} absenceDashboardStatsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAbsenceDashStats(absenceDashboardStatsRequest: AbsenceDashboardStatsRequest, options?: any): AxiosPromise<AbsenceDashboardStatsResponse> {
            return localVarFp.getAbsenceDashStats(absenceDashboardStatsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Admin only. Get the absence config for a single user
         * @param {string} username Username to get the info for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAbsenceConfig(username: string, options?: any): AxiosPromise<UserAbsenceConfig> {
            return localVarFp.getUserAbsenceConfig(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Admin only. Get the absence configuration for a specific user for a specific year
         * @param {UserAbsenceConfigKey} userAbsenceConfigKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAbsenceConfigYear(userAbsenceConfigKey: UserAbsenceConfigKey, options?: any): AxiosPromise<UserAbsenceConfigYear> {
            return localVarFp.getUserAbsenceConfigYear(userAbsenceConfigKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List the absences currently pending approval or rejection. Staff will automatically populate usernames field with the user\'s username restricting the list to themselves. Admins can populate any usernames, or even none.
         * @param {ListUserAbsenceRequests} listUserAbsenceRequests 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAbsenceRequests(listUserAbsenceRequests: ListUserAbsenceRequests, options?: any): AxiosPromise<ListUserAbsenceRequestsResponse> {
            return localVarFp.listAbsenceRequests(listUserAbsenceRequests, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of absences that meet a specific criteria. Will be capped to 10,000 absences. If the count exceeds this, only the first 10,000 will be returned. Staff will only be able to see their own absences.
         * @param {ListAbsencesRequest} listAbsencesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAbsences(listAbsencesRequest: ListAbsencesRequest, options?: any): AxiosPromise<ListAbsencesResponse> {
            return localVarFp.listAbsences(listAbsencesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary System admin only. Reject a user\'s absence request
         * @param {AbsenceApprovalResponse} absenceApprovalResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectAbsenceRequest(absenceApprovalResponse: AbsenceApprovalResponse, options?: any): AxiosPromise<UserAbsenceRequest> {
            return localVarFp.rejectAbsenceRequest(absenceApprovalResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Staff and above. Request some time off for currently logged in user
         * @param {UserAbsenceInitialRequest} userAbsenceInitialRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestAbsence(userAbsenceInitialRequest: UserAbsenceInitialRequest, options?: any): AxiosPromise<UserAbsenceRequest> {
            return localVarFp.requestAbsence(userAbsenceInitialRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Admin only. Save an absence to the system. If the ID is 0, the absence will be inserted. Otherwise the existing absence will be updated. Absences cannot overlap date ranges.
         * @param {UserAbsence} userAbsence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAbsence(userAbsence: UserAbsence, options?: any): AxiosPromise<UserAbsence> {
            return localVarFp.saveAbsence(userAbsence, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Admin only. Set the defaults for a user\'s absence configuration, if not overriden by a year config
         * @param {UserAbsenceConfigDefault} userAbsenceConfigDefault 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserDefaultAbsenceConfig(userAbsenceConfigDefault: UserAbsenceConfigDefault, options?: any): AxiosPromise<UserAbsenceConfigDefault> {
            return localVarFp.setUserDefaultAbsenceConfig(userAbsenceConfigDefault, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Admin only. Set the a user\'s absence configuration for a specific year. If the year matches an existing year, the existing record will be updated.
         * @param {UserAbsenceConfigYear} userAbsenceConfigYear 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserYearAbsenceConfig(userAbsenceConfigYear: UserAbsenceConfigYear, options?: any): AxiosPromise<UserAbsenceConfigYear> {
            return localVarFp.setUserYearAbsenceConfig(userAbsenceConfigYear, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AbsenceApi - object-oriented interface
 * @export
 * @class AbsenceApi
 * @extends {BaseAPI}
 */
export class AbsenceApi extends BaseAPI {
    /**
     * 
     * @summary System admin only. Accept a user\'s absence request
     * @param {AbsenceApprovalResponse} absenceApprovalResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public acceptAbsenceRequest(absenceApprovalResponse: AbsenceApprovalResponse, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).acceptAbsenceRequest(absenceApprovalResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary System admin only. Delete a specific absence from the system
     * @param {number} id Unique ID for the absence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public deleteAbsence(id: number, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).deleteAbsence(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Admin only. Delete the absence configuration for a specific user for a specific year
     * @param {UserAbsenceConfigKey} userAbsenceConfigKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public deleteUserAbsenceConfigYear(userAbsenceConfigKey: UserAbsenceConfigKey, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).deleteUserAbsenceConfigYear(userAbsenceConfigKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary System admin only. Get a specific absence from the system
     * @param {number} id Unique ID for the absence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public getAbsence(id: number, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).getAbsence(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Admin only. Get the stats to render a dashboard
     * @param {AbsenceDashboardStatsRequest} absenceDashboardStatsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public getAbsenceDashStats(absenceDashboardStatsRequest: AbsenceDashboardStatsRequest, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).getAbsenceDashStats(absenceDashboardStatsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Admin only. Get the absence config for a single user
     * @param {string} username Username to get the info for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public getUserAbsenceConfig(username: string, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).getUserAbsenceConfig(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Admin only. Get the absence configuration for a specific user for a specific year
     * @param {UserAbsenceConfigKey} userAbsenceConfigKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public getUserAbsenceConfigYear(userAbsenceConfigKey: UserAbsenceConfigKey, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).getUserAbsenceConfigYear(userAbsenceConfigKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List the absences currently pending approval or rejection. Staff will automatically populate usernames field with the user\'s username restricting the list to themselves. Admins can populate any usernames, or even none.
     * @param {ListUserAbsenceRequests} listUserAbsenceRequests 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public listAbsenceRequests(listUserAbsenceRequests: ListUserAbsenceRequests, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).listAbsenceRequests(listUserAbsenceRequests, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of absences that meet a specific criteria. Will be capped to 10,000 absences. If the count exceeds this, only the first 10,000 will be returned. Staff will only be able to see their own absences.
     * @param {ListAbsencesRequest} listAbsencesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public listAbsences(listAbsencesRequest: ListAbsencesRequest, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).listAbsences(listAbsencesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary System admin only. Reject a user\'s absence request
     * @param {AbsenceApprovalResponse} absenceApprovalResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public rejectAbsenceRequest(absenceApprovalResponse: AbsenceApprovalResponse, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).rejectAbsenceRequest(absenceApprovalResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Staff and above. Request some time off for currently logged in user
     * @param {UserAbsenceInitialRequest} userAbsenceInitialRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public requestAbsence(userAbsenceInitialRequest: UserAbsenceInitialRequest, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).requestAbsence(userAbsenceInitialRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Admin only. Save an absence to the system. If the ID is 0, the absence will be inserted. Otherwise the existing absence will be updated. Absences cannot overlap date ranges.
     * @param {UserAbsence} userAbsence 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public saveAbsence(userAbsence: UserAbsence, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).saveAbsence(userAbsence, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Admin only. Set the defaults for a user\'s absence configuration, if not overriden by a year config
     * @param {UserAbsenceConfigDefault} userAbsenceConfigDefault 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public setUserDefaultAbsenceConfig(userAbsenceConfigDefault: UserAbsenceConfigDefault, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).setUserDefaultAbsenceConfig(userAbsenceConfigDefault, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Admin only. Set the a user\'s absence configuration for a specific year. If the year matches an existing year, the existing record will be updated.
     * @param {UserAbsenceConfigYear} userAbsenceConfigYear 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public setUserYearAbsenceConfig(userAbsenceConfigYear: UserAbsenceConfigYear, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).setUserYearAbsenceConfig(userAbsenceConfigYear, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * Absence2Api - axios parameter creator
 * @export
 */
export const Absence2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Accept an open absence request. The absence should have been created separately.
         * @param {CompleteAbsenceRequest} completeAbsenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceRequestAcceptV2: async (completeAbsenceRequest: CompleteAbsenceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'completeAbsenceRequest' is not null or undefined
            assertParamExists('absenceRequestAcceptV2', 'completeAbsenceRequest', completeAbsenceRequest)
            const localVarPath = `/v2/absence/request/accept`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completeAbsenceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel an open absence request
         * @param {CancelAbsenceRequest} cancelAbsenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceRequestCancelV2: async (cancelAbsenceRequest: CancelAbsenceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cancelAbsenceRequest' is not null or undefined
            assertParamExists('absenceRequestCancelV2', 'cancelAbsenceRequest', cancelAbsenceRequest)
            const localVarPath = `/v2/absence/request/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cancelAbsenceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reject an open absence request
         * @param {RejectAbsenceRequest} rejectAbsenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceRequestRejectV2: async (rejectAbsenceRequest: RejectAbsenceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rejectAbsenceRequest' is not null or undefined
            assertParamExists('absenceRequestRejectV2', 'rejectAbsenceRequest', rejectAbsenceRequest)
            const localVarPath = `/v2/absence/request/reject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rejectAbsenceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request time off for logged in staff member
         * @param {NewAbsenceRequest} newAbsenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceRequestV2: async (newAbsenceRequest: NewAbsenceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newAbsenceRequest' is not null or undefined
            assertParamExists('absenceRequestV2', 'newAbsenceRequest', newAbsenceRequest)
            const localVarPath = `/v2/absence/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newAbsenceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a specific absence
         * @param {number} id ID of the absence to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAbsenceV2: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAbsenceV2', 'id', id)
            const localVarPath = `/v2/absence/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific absence with provided ID
         * @param {number} id ID of the absence to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAbsenceV2: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAbsenceV2', 'id', id)
            const localVarPath = `/v2/absence/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all the absence types in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAbsenceTypesV2: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/absence/types/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all absence requests that meet provided criteria
         * @param {AbsenceRequestListRequest} absenceRequestListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAbsenceRequestsV2: async (absenceRequestListRequest: AbsenceRequestListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'absenceRequestListRequest' is not null or undefined
            assertParamExists('listAbsenceRequestsV2', 'absenceRequestListRequest', absenceRequestListRequest)
            const localVarPath = `/v2/absence/request/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(absenceRequestListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save an absence type
         * @param {AbsenceType2} absenceType2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAbsenceTypeV2: async (absenceType2: AbsenceType2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'absenceType2' is not null or undefined
            assertParamExists('saveAbsenceTypeV2', 'absenceType2', absenceType2)
            const localVarPath = `/v2/absence/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(absenceType2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save an absence into the system
         * @param {AbsencePersistRequest} absencePersistRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAbsenceV2: async (absencePersistRequest: AbsencePersistRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'absencePersistRequest' is not null or undefined
            assertParamExists('saveAbsenceV2', 'absencePersistRequest', absencePersistRequest)
            const localVarPath = `/v2/absence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(absencePersistRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Absence2Api - functional programming interface
 * @export
 */
export const Absence2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Absence2ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Accept an open absence request. The absence should have been created separately.
         * @param {CompleteAbsenceRequest} completeAbsenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async absenceRequestAcceptV2(completeAbsenceRequest: CompleteAbsenceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbsenceRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.absenceRequestAcceptV2(completeAbsenceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel an open absence request
         * @param {CancelAbsenceRequest} cancelAbsenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async absenceRequestCancelV2(cancelAbsenceRequest: CancelAbsenceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbsenceRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.absenceRequestCancelV2(cancelAbsenceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reject an open absence request
         * @param {RejectAbsenceRequest} rejectAbsenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async absenceRequestRejectV2(rejectAbsenceRequest: RejectAbsenceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbsenceRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.absenceRequestRejectV2(rejectAbsenceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Request time off for logged in staff member
         * @param {NewAbsenceRequest} newAbsenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async absenceRequestV2(newAbsenceRequest: NewAbsenceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbsenceRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.absenceRequestV2(newAbsenceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a specific absence
         * @param {number} id ID of the absence to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAbsenceV2(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAbsenceV2(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a specific absence with provided ID
         * @param {number} id ID of the absence to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAbsenceV2(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbsenceView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAbsenceV2(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all the absence types in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAbsenceTypesV2(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AbsenceType2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAbsenceTypesV2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all absence requests that meet provided criteria
         * @param {AbsenceRequestListRequest} absenceRequestListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAbsenceRequestsV2(absenceRequestListRequest: AbsenceRequestListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbsenceRequestListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAbsenceRequestsV2(absenceRequestListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save an absence type
         * @param {AbsenceType2} absenceType2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveAbsenceTypeV2(absenceType2: AbsenceType2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbsenceType2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveAbsenceTypeV2(absenceType2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save an absence into the system
         * @param {AbsencePersistRequest} absencePersistRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveAbsenceV2(absencePersistRequest: AbsencePersistRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbsenceWithDays>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveAbsenceV2(absencePersistRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * Absence2Api - factory interface
 * @export
 */
export const Absence2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Absence2ApiFp(configuration)
    return {
        /**
         * 
         * @summary Accept an open absence request. The absence should have been created separately.
         * @param {CompleteAbsenceRequest} completeAbsenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceRequestAcceptV2(completeAbsenceRequest: CompleteAbsenceRequest, options?: any): AxiosPromise<AbsenceRequest> {
            return localVarFp.absenceRequestAcceptV2(completeAbsenceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel an open absence request
         * @param {CancelAbsenceRequest} cancelAbsenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceRequestCancelV2(cancelAbsenceRequest: CancelAbsenceRequest, options?: any): AxiosPromise<AbsenceRequest> {
            return localVarFp.absenceRequestCancelV2(cancelAbsenceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reject an open absence request
         * @param {RejectAbsenceRequest} rejectAbsenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceRequestRejectV2(rejectAbsenceRequest: RejectAbsenceRequest, options?: any): AxiosPromise<AbsenceRequest> {
            return localVarFp.absenceRequestRejectV2(rejectAbsenceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request time off for logged in staff member
         * @param {NewAbsenceRequest} newAbsenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceRequestV2(newAbsenceRequest: NewAbsenceRequest, options?: any): AxiosPromise<AbsenceRequest> {
            return localVarFp.absenceRequestV2(newAbsenceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a specific absence
         * @param {number} id ID of the absence to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAbsenceV2(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAbsenceV2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a specific absence with provided ID
         * @param {number} id ID of the absence to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAbsenceV2(id: number, options?: any): AxiosPromise<AbsenceView> {
            return localVarFp.getAbsenceV2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all the absence types in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAbsenceTypesV2(options?: any): AxiosPromise<Array<AbsenceType2>> {
            return localVarFp.getAllAbsenceTypesV2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all absence requests that meet provided criteria
         * @param {AbsenceRequestListRequest} absenceRequestListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAbsenceRequestsV2(absenceRequestListRequest: AbsenceRequestListRequest, options?: any): AxiosPromise<AbsenceRequestListResponse> {
            return localVarFp.listAbsenceRequestsV2(absenceRequestListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save an absence type
         * @param {AbsenceType2} absenceType2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAbsenceTypeV2(absenceType2: AbsenceType2, options?: any): AxiosPromise<AbsenceType2> {
            return localVarFp.saveAbsenceTypeV2(absenceType2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save an absence into the system
         * @param {AbsencePersistRequest} absencePersistRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAbsenceV2(absencePersistRequest: AbsencePersistRequest, options?: any): AxiosPromise<AbsenceWithDays> {
            return localVarFp.saveAbsenceV2(absencePersistRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Absence2Api - object-oriented interface
 * @export
 * @class Absence2Api
 * @extends {BaseAPI}
 */
export class Absence2Api extends BaseAPI {
    /**
     * 
     * @summary Accept an open absence request. The absence should have been created separately.
     * @param {CompleteAbsenceRequest} completeAbsenceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Absence2Api
     */
    public absenceRequestAcceptV2(completeAbsenceRequest: CompleteAbsenceRequest, options?: AxiosRequestConfig) {
        return Absence2ApiFp(this.configuration).absenceRequestAcceptV2(completeAbsenceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel an open absence request
     * @param {CancelAbsenceRequest} cancelAbsenceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Absence2Api
     */
    public absenceRequestCancelV2(cancelAbsenceRequest: CancelAbsenceRequest, options?: AxiosRequestConfig) {
        return Absence2ApiFp(this.configuration).absenceRequestCancelV2(cancelAbsenceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reject an open absence request
     * @param {RejectAbsenceRequest} rejectAbsenceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Absence2Api
     */
    public absenceRequestRejectV2(rejectAbsenceRequest: RejectAbsenceRequest, options?: AxiosRequestConfig) {
        return Absence2ApiFp(this.configuration).absenceRequestRejectV2(rejectAbsenceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request time off for logged in staff member
     * @param {NewAbsenceRequest} newAbsenceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Absence2Api
     */
    public absenceRequestV2(newAbsenceRequest: NewAbsenceRequest, options?: AxiosRequestConfig) {
        return Absence2ApiFp(this.configuration).absenceRequestV2(newAbsenceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a specific absence
     * @param {number} id ID of the absence to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Absence2Api
     */
    public deleteAbsenceV2(id: number, options?: AxiosRequestConfig) {
        return Absence2ApiFp(this.configuration).deleteAbsenceV2(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a specific absence with provided ID
     * @param {number} id ID of the absence to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Absence2Api
     */
    public getAbsenceV2(id: number, options?: AxiosRequestConfig) {
        return Absence2ApiFp(this.configuration).getAbsenceV2(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all the absence types in the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Absence2Api
     */
    public getAllAbsenceTypesV2(options?: AxiosRequestConfig) {
        return Absence2ApiFp(this.configuration).getAllAbsenceTypesV2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all absence requests that meet provided criteria
     * @param {AbsenceRequestListRequest} absenceRequestListRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Absence2Api
     */
    public listAbsenceRequestsV2(absenceRequestListRequest: AbsenceRequestListRequest, options?: AxiosRequestConfig) {
        return Absence2ApiFp(this.configuration).listAbsenceRequestsV2(absenceRequestListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save an absence type
     * @param {AbsenceType2} absenceType2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Absence2Api
     */
    public saveAbsenceTypeV2(absenceType2: AbsenceType2, options?: AxiosRequestConfig) {
        return Absence2ApiFp(this.configuration).saveAbsenceTypeV2(absenceType2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save an absence into the system
     * @param {AbsencePersistRequest} absencePersistRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Absence2Api
     */
    public saveAbsenceV2(absencePersistRequest: AbsencePersistRequest, options?: AxiosRequestConfig) {
        return Absence2ApiFp(this.configuration).saveAbsenceV2(absencePersistRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContactApi - axios parameter creator
 * @export
 */
export const ContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send the same message to multiple users
         * @param {BulkSMSRequest} bulkSMSRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkSms: async (bulkSMSRequest: BulkSMSRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkSMSRequest' is not null or undefined
            assertParamExists('bulkSms', 'bulkSMSRequest', bulkSMSRequest)
            const localVarPath = `/v1/contact/sms/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkSMSRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the SMS logs for the given criteria/filters, paged
         * @param {SmsHistoryRequest} smsHistoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageSmsLogs: async (smsHistoryRequest: SmsHistoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'smsHistoryRequest' is not null or undefined
            assertParamExists('pageSmsLogs', 'smsHistoryRequest', smsHistoryRequest)
            const localVarPath = `/v1/contact/sms/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(smsHistoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactApi - functional programming interface
 * @export
 */
export const ContactApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send the same message to multiple users
         * @param {BulkSMSRequest} bulkSMSRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkSms(bulkSMSRequest: BulkSMSRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkSms(bulkSMSRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the SMS logs for the given criteria/filters, paged
         * @param {SmsHistoryRequest} smsHistoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageSmsLogs(smsHistoryRequest: SmsHistoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SmsHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageSmsLogs(smsHistoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactApi - factory interface
 * @export
 */
export const ContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactApiFp(configuration)
    return {
        /**
         * 
         * @summary Send the same message to multiple users
         * @param {BulkSMSRequest} bulkSMSRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkSms(bulkSMSRequest: BulkSMSRequest, options?: any): AxiosPromise<void> {
            return localVarFp.bulkSms(bulkSMSRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the SMS logs for the given criteria/filters, paged
         * @param {SmsHistoryRequest} smsHistoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageSmsLogs(smsHistoryRequest: SmsHistoryRequest, options?: any): AxiosPromise<SmsHistoryResponse> {
            return localVarFp.pageSmsLogs(smsHistoryRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactApi - object-oriented interface
 * @export
 * @class ContactApi
 * @extends {BaseAPI}
 */
export class ContactApi extends BaseAPI {
    /**
     * 
     * @summary Send the same message to multiple users
     * @param {BulkSMSRequest} bulkSMSRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public bulkSms(bulkSMSRequest: BulkSMSRequest, options?: AxiosRequestConfig) {
        return ContactApiFp(this.configuration).bulkSms(bulkSMSRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the SMS logs for the given criteria/filters, paged
     * @param {SmsHistoryRequest} smsHistoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public pageSmsLogs(smsHistoryRequest: SmsHistoryRequest, options?: AxiosRequestConfig) {
        return ContactApiFp(this.configuration).pageSmsLogs(smsHistoryRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LocationsApi - axios parameter creator
 * @export
 */
export const LocationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the latitude / longitude for the provided postcode
         * @param {string} postcode ID of the region to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeoLocationForPostcode: async (postcode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postcode' is not null or undefined
            assertParamExists('getGeoLocationForPostcode', 'postcode', postcode)
            const localVarPath = `/v1/location/postcode/{postcode}`
                .replace(`{${"postcode"}}`, encodeURIComponent(String(postcode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Scan through users / regions and ensure postcodes are all geolocated where possible. Admin only.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshLocationCache: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/location/refreshcache`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationsApi - functional programming interface
 * @export
 */
export const LocationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the latitude / longitude for the provided postcode
         * @param {string} postcode ID of the region to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeoLocationForPostcode(postcode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeoLocation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeoLocationForPostcode(postcode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Scan through users / regions and ensure postcodes are all geolocated where possible. Admin only.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshLocationCache(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshLocationCache(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LocationsApi - factory interface
 * @export
 */
export const LocationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the latitude / longitude for the provided postcode
         * @param {string} postcode ID of the region to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeoLocationForPostcode(postcode: string, options?: any): AxiosPromise<GeoLocation> {
            return localVarFp.getGeoLocationForPostcode(postcode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Scan through users / regions and ensure postcodes are all geolocated where possible. Admin only.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshLocationCache(options?: any): AxiosPromise<void> {
            return localVarFp.refreshLocationCache(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocationsApi - object-oriented interface
 * @export
 * @class LocationsApi
 * @extends {BaseAPI}
 */
export class LocationsApi extends BaseAPI {
    /**
     * 
     * @summary Get the latitude / longitude for the provided postcode
     * @param {string} postcode ID of the region to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public getGeoLocationForPostcode(postcode: string, options?: AxiosRequestConfig) {
        return LocationsApiFp(this.configuration).getGeoLocationForPostcode(postcode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Scan through users / regions and ensure postcodes are all geolocated where possible. Admin only.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public refreshLocationCache(options?: AxiosRequestConfig) {
        return LocationsApiFp(this.configuration).refreshLocationCache(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RegionsApi - axios parameter creator
 * @export
 */
export const RegionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all regions in the platform
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRegions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get region with the specified ID, if possible
         * @param {number} id ID of the region to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegion: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRegion', 'id', id)
            const localVarPath = `/v1/regions/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a single region to the DB, either inserting or updating depending on ID
         * @param {Region} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveRegion: async (region: Region, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('saveRegion', 'region', region)
            const localVarPath = `/v1/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(region, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegionsApi - functional programming interface
 * @export
 */
export const RegionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all regions in the platform
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllRegions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Region>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRegions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get region with the specified ID, if possible
         * @param {number} id ID of the region to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegion(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Region>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegion(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save a single region to the DB, either inserting or updating depending on ID
         * @param {Region} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveRegion(region: Region, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Region>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveRegion(region, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RegionsApi - factory interface
 * @export
 */
export const RegionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all regions in the platform
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRegions(options?: any): AxiosPromise<Array<Region>> {
            return localVarFp.getAllRegions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get region with the specified ID, if possible
         * @param {number} id ID of the region to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegion(id: number, options?: any): AxiosPromise<Region> {
            return localVarFp.getRegion(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save a single region to the DB, either inserting or updating depending on ID
         * @param {Region} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveRegion(region: Region, options?: any): AxiosPromise<Region> {
            return localVarFp.saveRegion(region, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegionsApi - object-oriented interface
 * @export
 * @class RegionsApi
 * @extends {BaseAPI}
 */
export class RegionsApi extends BaseAPI {
    /**
     * 
     * @summary Get all regions in the platform
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionsApi
     */
    public getAllRegions(options?: AxiosRequestConfig) {
        return RegionsApiFp(this.configuration).getAllRegions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get region with the specified ID, if possible
     * @param {number} id ID of the region to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionsApi
     */
    public getRegion(id: number, options?: AxiosRequestConfig) {
        return RegionsApiFp(this.configuration).getRegion(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save a single region to the DB, either inserting or updating depending on ID
     * @param {Region} region 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionsApi
     */
    public saveRegion(region: Region, options?: AxiosRequestConfig) {
        return RegionsApiFp(this.configuration).saveRegion(region, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserDocsApi - axios parameter creator
 * @export
 */
export const UserDocsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a specific document
         * @param {number} id ID of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserDocument: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUserDocument', 'id', id)
            const localVarPath = `/v1/users/docs/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all of the documents in the system for the specified user
         * @param {string} username Username to get the documents for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsForUser: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getDocumentsForUser', 'username', username)
            const localVarPath = `/v1/users/docs/byuser/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific document
         * @param {number} id ID of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDocument: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserDocument', 'id', id)
            const localVarPath = `/v1/users/docs/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the earliest expiry date for each user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDocumentExpiries: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users/docs/expiry/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the raw binary file
         * @param {number} id ID of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDocumentFile: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserDocumentFile', 'id', id)
            const localVarPath = `/v1/users/docs/byid/{id}/file`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a pre-signed URL to access a file, this URL will auto-expire
         * @param {number} id ID of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDocumentFileUrl: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserDocumentFileUrl', 'id', id)
            const localVarPath = `/v1/users/docs/byid/{id}/file/url`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a document to a user. ID of 0 creates a new document, other values update an existing document.
         * @param {UserDocUploadRequest} [userDocUploadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserDocument: async (userDocUploadRequest?: UserDocUploadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users/docs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDocUploadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserDocsApi - functional programming interface
 * @export
 */
export const UserDocsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserDocsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a specific document
         * @param {number} id ID of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserDocument(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserDocument(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all of the documents in the system for the specified user
         * @param {string} username Username to get the documents for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentsForUser(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDocumentation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentsForUser(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a specific document
         * @param {number} id ID of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDocument(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDocumentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDocument(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the earliest expiry date for each user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDocumentExpiries(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserExpiry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDocumentExpiries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the raw binary file
         * @param {number} id ID of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDocumentFile(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDocumentFile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a pre-signed URL to access a file, this URL will auto-expire
         * @param {number} id ID of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDocumentFileUrl(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDocumentFileUrl(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save a document to a user. ID of 0 creates a new document, other values update an existing document.
         * @param {UserDocUploadRequest} [userDocUploadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveUserDocument(userDocUploadRequest?: UserDocUploadRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDocumentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveUserDocument(userDocUploadRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserDocsApi - factory interface
 * @export
 */
export const UserDocsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserDocsApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a specific document
         * @param {number} id ID of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserDocument(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserDocument(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all of the documents in the system for the specified user
         * @param {string} username Username to get the documents for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsForUser(username: string, options?: any): AxiosPromise<Array<UserDocumentation>> {
            return localVarFp.getDocumentsForUser(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a specific document
         * @param {number} id ID of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDocument(id: number, options?: any): AxiosPromise<UserDocumentation> {
            return localVarFp.getUserDocument(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the earliest expiry date for each user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDocumentExpiries(options?: any): AxiosPromise<Array<UserExpiry>> {
            return localVarFp.getUserDocumentExpiries(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the raw binary file
         * @param {number} id ID of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDocumentFile(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.getUserDocumentFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a pre-signed URL to access a file, this URL will auto-expire
         * @param {number} id ID of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDocumentFileUrl(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.getUserDocumentFileUrl(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save a document to a user. ID of 0 creates a new document, other values update an existing document.
         * @param {UserDocUploadRequest} [userDocUploadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserDocument(userDocUploadRequest?: UserDocUploadRequest, options?: any): AxiosPromise<UserDocumentation> {
            return localVarFp.saveUserDocument(userDocUploadRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserDocsApi - object-oriented interface
 * @export
 * @class UserDocsApi
 * @extends {BaseAPI}
 */
export class UserDocsApi extends BaseAPI {
    /**
     * 
     * @summary Delete a specific document
     * @param {number} id ID of the document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDocsApi
     */
    public deleteUserDocument(id: number, options?: AxiosRequestConfig) {
        return UserDocsApiFp(this.configuration).deleteUserDocument(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all of the documents in the system for the specified user
     * @param {string} username Username to get the documents for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDocsApi
     */
    public getDocumentsForUser(username: string, options?: AxiosRequestConfig) {
        return UserDocsApiFp(this.configuration).getDocumentsForUser(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a specific document
     * @param {number} id ID of the document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDocsApi
     */
    public getUserDocument(id: number, options?: AxiosRequestConfig) {
        return UserDocsApiFp(this.configuration).getUserDocument(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the earliest expiry date for each user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDocsApi
     */
    public getUserDocumentExpiries(options?: AxiosRequestConfig) {
        return UserDocsApiFp(this.configuration).getUserDocumentExpiries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the raw binary file
     * @param {number} id ID of the document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDocsApi
     */
    public getUserDocumentFile(id: number, options?: AxiosRequestConfig) {
        return UserDocsApiFp(this.configuration).getUserDocumentFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a pre-signed URL to access a file, this URL will auto-expire
     * @param {number} id ID of the document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDocsApi
     */
    public getUserDocumentFileUrl(id: number, options?: AxiosRequestConfig) {
        return UserDocsApiFp(this.configuration).getUserDocumentFileUrl(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save a document to a user. ID of 0 creates a new document, other values update an existing document.
     * @param {UserDocUploadRequest} [userDocUploadRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDocsApi
     */
    public saveUserDocument(userDocUploadRequest?: UserDocUploadRequest, options?: AxiosRequestConfig) {
        return UserDocsApiFp(this.configuration).saveUserDocument(userDocUploadRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a new user to the system
         * @param {NewUser} newUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser: async (newUser: NewUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newUser' is not null or undefined
            assertParamExists('addUser', 'newUser', newUser)
            const localVarPath = `/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Associate a TOTP authenticator app with the user\'s account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        associateTotp: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users/totp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a specific flag
         * @param {string} name Name of the flag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePossibleFlag: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deletePossibleFlag', 'name', name)
            const localVarPath = `/v1/users/flags/byname/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the user with the specified email address
         * @param {string} email User\&#39;s email address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserByEmail: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('deleteUserByEmail', 'email', email)
            const localVarPath = `/v1/users/byemail/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the user with the specified username
         * @param {string} username User\&#39;s username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserByUsername: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('deleteUserByUsername', 'username', username)
            const localVarPath = `/v1/users/byusername/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of ALL users in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a flag with a specific name, unlikely to be needed outside of testing
         * @param {string} name Name of the flag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPossibleFlagByName: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getPossibleFlagByName', 'name', name)
            const localVarPath = `/v1/users/flags/byname/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of all the flags that can be applied to a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPossibleFlags: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users/flags/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the user with the specified email address
         * @param {string} email User\&#39;s email address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByEmail: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('getUserByEmail', 'email', email)
            const localVarPath = `/v1/users/byemail/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get users with the specified PAYE status
         * @param {boolean} paye User\&#39;s PAYE status. True for PAYE, false for NOT PAYE
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByPayeStatus: async (paye: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paye' is not null or undefined
            assertParamExists('getUserByPayeStatus', 'paye', paye)
            const localVarPath = `/v1/users/bypaye/{paye}`
                .replace(`{${"paye"}}`, encodeURIComponent(String(paye)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the user with the specified username
         * @param {string} username User\&#39;s username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByUsername: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getUserByUsername', 'username', username)
            const localVarPath = `/v1/users/byusername/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get stats on total user counts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users/stats/usercount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all users that have a username within the request
         * @param {ByUsernameListRequest} byUsernameListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByUsernames: async (byUsernameListRequest: ByUsernameListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'byUsernameListRequest' is not null or undefined
            assertParamExists('getUsersByUsernames', 'byUsernameListRequest', byUsernameListRequest)
            const localVarPath = `/v1/users/byusername/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(byUsernameListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get users that meet criteria, returning location data in user data
         * @param {UserComplexRequest} userComplexRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersComplex: async (userComplexRequest: UserComplexRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userComplexRequest' is not null or undefined
            assertParamExists('getUsersComplex', 'userComplexRequest', userComplexRequest)
            const localVarPath = `/v1/users/complex`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userComplexRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Clear the password for user with the specified email address
         * @param {string} email User\&#39;s email address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserPasswordByEmail: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('resetUserPasswordByEmail', 'email', email)
            const localVarPath = `/v1/users/byemail/{email}/resetpassword`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Clear the password for user with the specified username
         * @param {string} username User\&#39;s username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserPasswordByUsername: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('resetUserPasswordByUsername', 'username', username)
            const localVarPath = `/v1/users/byusername/{username}/resetpassword`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a flag value that can be assigned to users
         * @param {PossibleFlag} possibleFlag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePossibleFlag: async (possibleFlag: PossibleFlag, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'possibleFlag' is not null or undefined
            assertParamExists('savePossibleFlag', 'possibleFlag', possibleFlag)
            const localVarPath = `/v1/users/flags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(possibleFlag, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set the password for user with the specified email address
         * @param {string} email User\&#39;s email address
         * @param {SetPasswordRequest} setPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserPasswordByEmail: async (email: string, setPasswordRequest: SetPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('setUserPasswordByEmail', 'email', email)
            // verify required parameter 'setPasswordRequest' is not null or undefined
            assertParamExists('setUserPasswordByEmail', 'setPasswordRequest', setPasswordRequest)
            const localVarPath = `/v1/users/byemail/{email}/setpassword`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set the password for user with the specified username
         * @param {string} username User\&#39;s username
         * @param {SetPasswordRequest} setPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserPasswordByUsername: async (username: string, setPasswordRequest: SetPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('setUserPasswordByUsername', 'username', username)
            // verify required parameter 'setPasswordRequest' is not null or undefined
            assertParamExists('setUserPasswordByUsername', 'setPasswordRequest', setPasswordRequest)
            const localVarPath = `/v1/users/byusername/{username}/setpassword`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a PNG QR code image for a TOTP associate/secret step. This cannot be a GET due to the secret in the body.
         * @param {TotpTokenAssociate} [totpTokenAssociate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        totpQrImage: async (totpTokenAssociate?: TotpTokenAssociate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/images/totp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(totpTokenAssociate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing user in the system
         * @param {UserData} userData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userData: UserData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userData' is not null or undefined
            assertParamExists('updateUser', 'userData', userData)
            const localVarPath = `/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verify that the auth app has linked correctly. Send a generated TOTP token and see if it matches what we expect.
         * @param {TotpTokenVerify} [totpTokenVerify] TOTP token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyTotp: async (totpTokenVerify?: TotpTokenVerify, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users/totp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(totpTokenVerify, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a new user to the system
         * @param {NewUser} newUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUser(newUser: NewUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUser(newUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Associate a TOTP authenticator app with the user\'s account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async associateTotp(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotpTokenAssociate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.associateTotp(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a specific flag
         * @param {string} name Name of the flag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePossibleFlag(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePossibleFlag(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete the user with the specified email address
         * @param {string} email User\&#39;s email address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserByEmail(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserByEmail(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete the user with the specified username
         * @param {string} username User\&#39;s username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserByUsername(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserByUsername(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of ALL users in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a flag with a specific name, unlikely to be needed outside of testing
         * @param {string} name Name of the flag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPossibleFlagByName(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PossibleFlag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPossibleFlagByName(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of all the flags that can be applied to a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPossibleFlags(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PossibleFlag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPossibleFlags(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the user with the specified email address
         * @param {string} email User\&#39;s email address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserByEmail(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserByEmail(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get users with the specified PAYE status
         * @param {boolean} paye User\&#39;s PAYE status. True for PAYE, false for NOT PAYE
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserByPayeStatus(paye: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserByPayeStatus(paye, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the user with the specified username
         * @param {string} username User\&#39;s username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserByUsername(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserByUsername(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get stats on total user counts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserCounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserCounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all users that have a username within the request
         * @param {ByUsernameListRequest} byUsernameListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersByUsernames(byUsernameListRequest: ByUsernameListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersByUsernames(byUsernameListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get users that meet criteria, returning location data in user data
         * @param {UserComplexRequest} userComplexRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersComplex(userComplexRequest: UserComplexRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersComplex(userComplexRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Clear the password for user with the specified email address
         * @param {string} email User\&#39;s email address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetUserPasswordByEmail(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetUserPasswordByEmail(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Clear the password for user with the specified username
         * @param {string} username User\&#39;s username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetUserPasswordByUsername(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetUserPasswordByUsername(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save a flag value that can be assigned to users
         * @param {PossibleFlag} possibleFlag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePossibleFlag(possibleFlag: PossibleFlag, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePossibleFlag(possibleFlag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set the password for user with the specified email address
         * @param {string} email User\&#39;s email address
         * @param {SetPasswordRequest} setPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUserPasswordByEmail(email: string, setPasswordRequest: SetPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUserPasswordByEmail(email, setPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set the password for user with the specified username
         * @param {string} username User\&#39;s username
         * @param {SetPasswordRequest} setPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUserPasswordByUsername(username: string, setPasswordRequest: SetPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUserPasswordByUsername(username, setPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a PNG QR code image for a TOTP associate/secret step. This cannot be a GET due to the secret in the body.
         * @param {TotpTokenAssociate} [totpTokenAssociate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async totpQrImage(totpTokenAssociate?: TotpTokenAssociate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.totpQrImage(totpTokenAssociate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existing user in the system
         * @param {UserData} userData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userData: UserData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Verify that the auth app has linked correctly. Send a generated TOTP token and see if it matches what we expect.
         * @param {TotpTokenVerify} [totpTokenVerify] TOTP token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyTotp(totpTokenVerify?: TotpTokenVerify, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotpVerifyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyTotp(totpTokenVerify, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a new user to the system
         * @param {NewUser} newUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser(newUser: NewUser, options?: any): AxiosPromise<UserData> {
            return localVarFp.addUser(newUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Associate a TOTP authenticator app with the user\'s account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        associateTotp(options?: any): AxiosPromise<TotpTokenAssociate> {
            return localVarFp.associateTotp(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a specific flag
         * @param {string} name Name of the flag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePossibleFlag(name: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePossibleFlag(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the user with the specified email address
         * @param {string} email User\&#39;s email address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserByEmail(email: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserByEmail(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the user with the specified username
         * @param {string} username User\&#39;s username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserByUsername(username: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserByUsername(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of ALL users in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsers(options?: any): AxiosPromise<Array<UserData>> {
            return localVarFp.getAllUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a flag with a specific name, unlikely to be needed outside of testing
         * @param {string} name Name of the flag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPossibleFlagByName(name: string, options?: any): AxiosPromise<PossibleFlag> {
            return localVarFp.getPossibleFlagByName(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of all the flags that can be applied to a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPossibleFlags(options?: any): AxiosPromise<Array<PossibleFlag>> {
            return localVarFp.getPossibleFlags(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the user with the specified email address
         * @param {string} email User\&#39;s email address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByEmail(email: string, options?: any): AxiosPromise<UserData> {
            return localVarFp.getUserByEmail(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get users with the specified PAYE status
         * @param {boolean} paye User\&#39;s PAYE status. True for PAYE, false for NOT PAYE
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByPayeStatus(paye: boolean, options?: any): AxiosPromise<Array<UserData>> {
            return localVarFp.getUserByPayeStatus(paye, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the user with the specified username
         * @param {string} username User\&#39;s username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByUsername(username: string, options?: any): AxiosPromise<UserData> {
            return localVarFp.getUserByUsername(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get stats on total user counts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCounts(options?: any): AxiosPromise<UserCount> {
            return localVarFp.getUserCounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all users that have a username within the request
         * @param {ByUsernameListRequest} byUsernameListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByUsernames(byUsernameListRequest: ByUsernameListRequest, options?: any): AxiosPromise<Array<UserData>> {
            return localVarFp.getUsersByUsernames(byUsernameListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get users that meet criteria, returning location data in user data
         * @param {UserComplexRequest} userComplexRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersComplex(userComplexRequest: UserComplexRequest, options?: any): AxiosPromise<Array<UserData>> {
            return localVarFp.getUsersComplex(userComplexRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Clear the password for user with the specified email address
         * @param {string} email User\&#39;s email address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserPasswordByEmail(email: string, options?: any): AxiosPromise<void> {
            return localVarFp.resetUserPasswordByEmail(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Clear the password for user with the specified username
         * @param {string} username User\&#39;s username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserPasswordByUsername(username: string, options?: any): AxiosPromise<void> {
            return localVarFp.resetUserPasswordByUsername(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save a flag value that can be assigned to users
         * @param {PossibleFlag} possibleFlag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePossibleFlag(possibleFlag: PossibleFlag, options?: any): AxiosPromise<void> {
            return localVarFp.savePossibleFlag(possibleFlag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set the password for user with the specified email address
         * @param {string} email User\&#39;s email address
         * @param {SetPasswordRequest} setPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserPasswordByEmail(email: string, setPasswordRequest: SetPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.setUserPasswordByEmail(email, setPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set the password for user with the specified username
         * @param {string} username User\&#39;s username
         * @param {SetPasswordRequest} setPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserPasswordByUsername(username: string, setPasswordRequest: SetPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.setUserPasswordByUsername(username, setPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a PNG QR code image for a TOTP associate/secret step. This cannot be a GET due to the secret in the body.
         * @param {TotpTokenAssociate} [totpTokenAssociate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        totpQrImage(totpTokenAssociate?: TotpTokenAssociate, options?: any): AxiosPromise<File> {
            return localVarFp.totpQrImage(totpTokenAssociate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing user in the system
         * @param {UserData} userData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userData: UserData, options?: any): AxiosPromise<UserData> {
            return localVarFp.updateUser(userData, options).then((request) => request(axios, basePath));
        },
        /**
         * Verify that the auth app has linked correctly. Send a generated TOTP token and see if it matches what we expect.
         * @param {TotpTokenVerify} [totpTokenVerify] TOTP token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyTotp(totpTokenVerify?: TotpTokenVerify, options?: any): AxiosPromise<TotpVerifyResponse> {
            return localVarFp.verifyTotp(totpTokenVerify, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Add a new user to the system
     * @param {NewUser} newUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public addUser(newUser: NewUser, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).addUser(newUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Associate a TOTP authenticator app with the user\'s account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public associateTotp(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).associateTotp(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a specific flag
     * @param {string} name Name of the flag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deletePossibleFlag(name: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).deletePossibleFlag(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the user with the specified email address
     * @param {string} email User\&#39;s email address
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUserByEmail(email: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteUserByEmail(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the user with the specified username
     * @param {string} username User\&#39;s username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUserByUsername(username: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteUserByUsername(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of ALL users in the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getAllUsers(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getAllUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a flag with a specific name, unlikely to be needed outside of testing
     * @param {string} name Name of the flag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getPossibleFlagByName(name: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getPossibleFlagByName(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of all the flags that can be applied to a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getPossibleFlags(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getPossibleFlags(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the user with the specified email address
     * @param {string} email User\&#39;s email address
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserByEmail(email: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserByEmail(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get users with the specified PAYE status
     * @param {boolean} paye User\&#39;s PAYE status. True for PAYE, false for NOT PAYE
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserByPayeStatus(paye: boolean, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserByPayeStatus(paye, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the user with the specified username
     * @param {string} username User\&#39;s username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserByUsername(username: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserByUsername(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get stats on total user counts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserCounts(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserCounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all users that have a username within the request
     * @param {ByUsernameListRequest} byUsernameListRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsersByUsernames(byUsernameListRequest: ByUsernameListRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUsersByUsernames(byUsernameListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get users that meet criteria, returning location data in user data
     * @param {UserComplexRequest} userComplexRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsersComplex(userComplexRequest: UserComplexRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUsersComplex(userComplexRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Clear the password for user with the specified email address
     * @param {string} email User\&#39;s email address
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public resetUserPasswordByEmail(email: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).resetUserPasswordByEmail(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Clear the password for user with the specified username
     * @param {string} username User\&#39;s username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public resetUserPasswordByUsername(username: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).resetUserPasswordByUsername(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save a flag value that can be assigned to users
     * @param {PossibleFlag} possibleFlag 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public savePossibleFlag(possibleFlag: PossibleFlag, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).savePossibleFlag(possibleFlag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set the password for user with the specified email address
     * @param {string} email User\&#39;s email address
     * @param {SetPasswordRequest} setPasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public setUserPasswordByEmail(email: string, setPasswordRequest: SetPasswordRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).setUserPasswordByEmail(email, setPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set the password for user with the specified username
     * @param {string} username User\&#39;s username
     * @param {SetPasswordRequest} setPasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public setUserPasswordByUsername(username: string, setPasswordRequest: SetPasswordRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).setUserPasswordByUsername(username, setPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a PNG QR code image for a TOTP associate/secret step. This cannot be a GET due to the secret in the body.
     * @param {TotpTokenAssociate} [totpTokenAssociate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public totpQrImage(totpTokenAssociate?: TotpTokenAssociate, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).totpQrImage(totpTokenAssociate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing user in the system
     * @param {UserData} userData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUser(userData: UserData, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUser(userData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verify that the auth app has linked correctly. Send a generated TOTP token and see if it matches what we expect.
     * @param {TotpTokenVerify} [totpTokenVerify] TOTP token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public verifyTotp(totpTokenVerify?: TotpTokenVerify, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).verifyTotp(totpTokenVerify, options).then((request) => request(this.axios, this.basePath));
    }
}


