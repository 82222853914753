import {Dispatch} from "redux";
import {USER_DOCUMENTS_STORE_STATE, UserDocumentsDispatchTypes} from "./UserDocumentsActionTypes";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import PPBApiModel from "../../apiModel/PPBApiModel";
import {RootStore} from "../../Store";
import {Category, DocumentViewRecordWithDocument} from "../../../api/ppb";
import StaffApiModel from "../../apiModel/StaffApiModel";
import {CATEGORIES_STORE_STATE} from "../../categories/actions/CategoriesActionTypes";
import {USER_STORE_STATE} from "../../user/actions/UserActionTypes";
import {StaffAccessLevel} from "../../../api/staff";
import {statusCodeCallback} from "../../helpers/storeHelpers";

export const nullifyUserDocStore = () => {
    return async (dispatch: Dispatch<UserDocumentsDispatchTypes>) => {
        dispatch({
            type: USER_DOCUMENTS_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: null
        });
    };
};

export const fetchDocumentsForStaffMember = (username: string) => {
    return async (dispatch: Dispatch<UserDocumentsDispatchTypes>, getState: () => RootStore) => {
        try {
            const success = await Promise.all([
                getDataFromServiceWithRedux(
                    USER_DOCUMENTS_STORE_STATE,
                    dispatch,
                    () => PPBApiModel.getDocsApi().getViewsForStaffMember(username),
                    statusCodeCallback
                ),
                getDataFromServiceWithRedux(
                    CATEGORIES_STORE_STATE,
                    dispatch,
                    () => PPBApiModel.getDocsApi().getAllCategories(),
                    statusCodeCallback
                ),
                getDataFromServiceWithRedux(
                    USER_STORE_STATE,
                    dispatch,
                    () => StaffApiModel.getUsersApi().getUserByUsername(username),
                    statusCodeCallback
                )
            ]);

            if (success) {
                const user = getState().user.data;
                const categories = getState().categories.data;
                const docs = getState().userDocuments.data;
                if (!user || !docs || !categories) return;

                //After getting all the data needed, check if it is a system admin, if so, return all docs.
                if (user.accessLevel === StaffAccessLevel.SystemAdministrator) {
                    dispatch({
                        type: USER_DOCUMENTS_STORE_STATE.SUCCESS,
                        loading: false,
                        error: null,
                        data: docs
                    });
                    return;
                }

                const userApplicableCategories: Category[] = [];

                //Check if the user that was fetched has a clinical grade that is within a category.
                for (const category of categories) {
                    const containsClinicalGrade = category.clinicalGrades.indexOf(
                        user.clinicalGrade
                    );

                    //Push those that apply
                    if (containsClinicalGrade > -1) {
                        userApplicableCategories.push(category);
                    }
                }

                let userApplicableDocs: DocumentViewRecordWithDocument[] = [];

                //Filter the documents that match the categories that apply to that user.
                for (const category of userApplicableCategories) {
                    const localDocs = docs.filter(
                        (el: DocumentViewRecordWithDocument) =>
                            el.document.categoryId === category.id
                    );

                    userApplicableDocs = [...userApplicableDocs, ...localDocs];
                }

                //Send to the ui
                dispatch({
                    type: USER_DOCUMENTS_STORE_STATE.SUCCESS,
                    loading: false,
                    error: null,
                    data: userApplicableDocs
                });
            }
        } catch (e: any) {
            dispatch({
                type: USER_DOCUMENTS_STORE_STATE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};
