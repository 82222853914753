// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * PPB API
 * API to manipulate PPB entities
 *
 * OpenAPI spec version: 0.1-SNAPSHOT
 * Contact: dan@pulseadvancedmedia.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as url from "url";
import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';

const BASE_PATH = "http://localhost:9002".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * Unique ID for this category
     * @type {number}
     * @memberof Category
     */
    id: number;
    /**
     * Name for this category
     * @type {string}
     * @memberof Category
     */
    name: string;
    /**
     * Clinical grades that can view the category. If empty, only admins will be able to see.
     * @type {Array<string>}
     * @memberof Category
     */
    clinicalGrades: Array<string>;
}

/**
 * View statistics for a single category
 * @export
 * @interface CategoryViewStat
 */
export interface CategoryViewStat {
    /**
     * Unique ID for the category
     * @type {number}
     * @memberof CategoryViewStat
     */
    categoryId: number;
    /**
     * Name of the category
     * @type {string}
     * @memberof CategoryViewStat
     */
    categoryName: string;
    /**
     * Total number of IN DATE views for documents in the category
     * @type {number}
     * @memberof CategoryViewStat
     */
    views: number;
    /**
     * Total number of views for a PREVIOUS VERSION of the document in the category
     * @type {number}
     * @memberof CategoryViewStat
     */
    oldViews: number;
    /**
     * The total number of views this cateogry could possibly receive
     * @type {number}
     * @memberof CategoryViewStat
     */
    possibleViews: number;
    /**
     * The number of documents in this category
     * @type {number}
     * @memberof CategoryViewStat
     */
    numDocs: number;
}

/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * Unique ID for this document
     * @type {number}
     * @memberof Document
     */
    id: number;
    /**
     * ID of the category this document is within
     * @type {number}
     * @memberof Document
     */
    categoryId: number;
    /**
     * Name for this document
     * @type {string}
     * @memberof Document
     */
    name: string;
    /**
     * Original filename for this document
     * @type {string}
     * @memberof Document
     */
    filename: string;
    /**
     * MIME Type or Content Type for this file. eg. text/plain
     * @type {string}
     * @memberof Document
     */
    mimeType: string;
    /**
     * Linux Epoch for when this date became valid
     * @type {number}
     * @memberof Document
     */
    date: number;
}

/**
 * Request to upload a document with optional physical file
 * @export
 * @interface DocumentUploadRequest
 */
export interface DocumentUploadRequest {
    /**
     * 
     * @type {Document}
     * @memberof DocumentUploadRequest
     */
    entity: Document;
    /**
     * Optional BASE 64 ENCODED binary file content
     * @type {string}
     * @memberof DocumentUploadRequest
     */
    file?: string;
}

/**
 * Single record of a user's view of a document
 * @export
 * @interface DocumentViewRecord
 */
export interface DocumentViewRecord {
    /**
     * ID of the document the view is for
     * @type {number}
     * @memberof DocumentViewRecord
     */
    documentId: number;
    /**
     * ID (username) of the staff member that viewed the document
     * @type {string}
     * @memberof DocumentViewRecord
     */
    staffId: string;
    /**
     * Linux epoch of when the view occurred
     * @type {number}
     * @memberof DocumentViewRecord
     */
    date: number;
}

/**
 * View record for a specific document, including the document. Usually used when listing documents along with a specific staff member's views
 * @export
 * @interface DocumentViewRecordWithDocument
 */
export interface DocumentViewRecordWithDocument {
    /**
     * 
     * @type {Document}
     * @memberof DocumentViewRecordWithDocument
     */
    document: Document;
    /**
     * Name of the category the document is within
     * @type {string}
     * @memberof DocumentViewRecordWithDocument
     */
    categoryName: string;
    /**
     * Optional Staff ID (Username) that viewed the document. NULL for no view.
     * @type {string}
     * @memberof DocumentViewRecordWithDocument
     */
    staffId?: string;
    /**
     * Optional Linux epoch for when the view occurred. NULL for no view.
     * @type {number}
     * @memberof DocumentViewRecordWithDocument
     */
    date?: number;
}

/**
 * An error has occurred, and this object contains the detailed information
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    message: string;
}


/**
 * DocsApi - axios parameter creator
 * @export
 */
export const DocsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a specific category
         * @param {number} id Unique ID for the category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteCategory.');
            }
            const localVarPath = `/cats/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a specific document
         * @param {number} id Unique ID for the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteDocument.');
            }
            const localVarPath = `/docs/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of all categories in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCategories(options: any = {}): RequestArgs {
            const localVarPath = `/cats/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific Category
         * @param {number} id Unique ID for the category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategory(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCategory.');
            }
            const localVarPath = `/cats/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the view counts for every category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryViewCounts(options: any = {}): RequestArgs {
            const localVarPath = `/cats/stats/viewcount`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific document
         * @param {number} id Unique ID for the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDocument.');
            }
            const localVarPath = `/docs/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the raw data for a specific document
         * @param {number} id Unique ID for the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentRawData(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDocumentRawData.');
            }
            const localVarPath = `/docs/byid/{id}/file`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a timed link to download the file for a document
         * @param {number} id Unique ID for the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentSignedLink(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDocumentSignedLink.');
            }
            const localVarPath = `/docs/byid/{id}/file/url`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all documents within a specific category
         * @param {number} id Unique ID for the category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsForCategory(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDocumentsForCategory.');
            }
            const localVarPath = `/docs/forcat/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get raw view records for a specific document
         * @param {number} id Unique ID for the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewsForDocument(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getViewsForDocument.');
            }
            const localVarPath = `/docs/views/fordoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all of the document views for a specific staff member. This will return all documents in the system, along with view information.
         * @param {string} staffid Unique ID for the staff member
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewsForStaffMember(staffid: string, options: any = {}): RequestArgs {
            // verify required parameter 'staffid' is not null or undefined
            if (staffid === null || staffid === undefined) {
                throw new RequiredError('staffid','Required parameter staffid was null or undefined when calling getViewsForStaffMember.');
            }
            const localVarPath = `/docs/views/forstaff/{staffid}`
                .replace(`{${"staffid"}}`, encodeURIComponent(String(staffid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save that a user viewed a document
         * @param {DocumentViewRecord} documentViewRecord 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logView(documentViewRecord: DocumentViewRecord, options: any = {}): RequestArgs {
            // verify required parameter 'documentViewRecord' is not null or undefined
            if (documentViewRecord === null || documentViewRecord === undefined) {
                throw new RequiredError('documentViewRecord','Required parameter documentViewRecord was null or undefined when calling logView.');
            }
            const localVarPath = `/docs/views`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DocumentViewRecord" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(documentViewRecord || {}) : (documentViewRecord || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a category to the DB. If the ID is greater than 0 perform an update, if 0 perform an insert.
         * @param {Category} category 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCategory(category: Category, options: any = {}): RequestArgs {
            // verify required parameter 'category' is not null or undefined
            if (category === null || category === undefined) {
                throw new RequiredError('category','Required parameter category was null or undefined when calling saveCategory.');
            }
            const localVarPath = `/cats`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Category" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(category || {}) : (category || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a document to the system
         * @param {DocumentUploadRequest} documentUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDocument(documentUploadRequest: DocumentUploadRequest, options: any = {}): RequestArgs {
            // verify required parameter 'documentUploadRequest' is not null or undefined
            if (documentUploadRequest === null || documentUploadRequest === undefined) {
                throw new RequiredError('documentUploadRequest','Required parameter documentUploadRequest was null or undefined when calling saveDocument.');
            }
            const localVarPath = `/docs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DocumentUploadRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(documentUploadRequest || {}) : (documentUploadRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocsApi - functional programming interface
 * @export
 */
export const DocsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a specific category
         * @param {number} id Unique ID for the category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DocsApiAxiosParamCreator(configuration).deleteCategory(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Delete a specific document
         * @param {number} id Unique ID for the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DocsApiAxiosParamCreator(configuration).deleteDocument(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a list of all categories in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCategories(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Category>> {
            const localVarAxiosArgs = DocsApiAxiosParamCreator(configuration).getAllCategories(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a specific Category
         * @param {number} id Unique ID for the category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategory(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category> {
            const localVarAxiosArgs = DocsApiAxiosParamCreator(configuration).getCategory(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get the view counts for every category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryViewCounts(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoryViewStat>> {
            const localVarAxiosArgs = DocsApiAxiosParamCreator(configuration).getCategoryViewCounts(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a specific document
         * @param {number} id Unique ID for the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document> {
            const localVarAxiosArgs = DocsApiAxiosParamCreator(configuration).getDocument(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get the raw data for a specific document
         * @param {number} id Unique ID for the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentRawData(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DocsApiAxiosParamCreator(configuration).getDocumentRawData(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a timed link to download the file for a document
         * @param {number} id Unique ID for the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentSignedLink(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DocsApiAxiosParamCreator(configuration).getDocumentSignedLink(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get all documents within a specific category
         * @param {number} id Unique ID for the category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsForCategory(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Document>> {
            const localVarAxiosArgs = DocsApiAxiosParamCreator(configuration).getDocumentsForCategory(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get raw view records for a specific document
         * @param {number} id Unique ID for the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewsForDocument(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentViewRecord>> {
            const localVarAxiosArgs = DocsApiAxiosParamCreator(configuration).getViewsForDocument(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get all of the document views for a specific staff member. This will return all documents in the system, along with view information.
         * @param {string} staffid Unique ID for the staff member
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewsForStaffMember(staffid: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentViewRecordWithDocument>> {
            const localVarAxiosArgs = DocsApiAxiosParamCreator(configuration).getViewsForStaffMember(staffid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Save that a user viewed a document
         * @param {DocumentViewRecord} documentViewRecord 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logView(documentViewRecord: DocumentViewRecord, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DocsApiAxiosParamCreator(configuration).logView(documentViewRecord, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Save a category to the DB. If the ID is greater than 0 perform an update, if 0 perform an insert.
         * @param {Category} category 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCategory(category: Category, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category> {
            const localVarAxiosArgs = DocsApiAxiosParamCreator(configuration).saveCategory(category, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Save a document to the system
         * @param {DocumentUploadRequest} documentUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDocument(documentUploadRequest: DocumentUploadRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document> {
            const localVarAxiosArgs = DocsApiAxiosParamCreator(configuration).saveDocument(documentUploadRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * DocsApi - factory interface
 * @export
 */
export const DocsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Delete a specific category
         * @param {number} id Unique ID for the category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory(id: number, options?: any) {
            return DocsApiFp(configuration).deleteCategory(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Delete a specific document
         * @param {number} id Unique ID for the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument(id: number, options?: any) {
            return DocsApiFp(configuration).deleteDocument(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a list of all categories in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCategories(options?: any) {
            return DocsApiFp(configuration).getAllCategories(options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a specific Category
         * @param {number} id Unique ID for the category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategory(id: number, options?: any) {
            return DocsApiFp(configuration).getCategory(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get the view counts for every category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryViewCounts(options?: any) {
            return DocsApiFp(configuration).getCategoryViewCounts(options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a specific document
         * @param {number} id Unique ID for the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument(id: number, options?: any) {
            return DocsApiFp(configuration).getDocument(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get the raw data for a specific document
         * @param {number} id Unique ID for the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentRawData(id: number, options?: any) {
            return DocsApiFp(configuration).getDocumentRawData(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a timed link to download the file for a document
         * @param {number} id Unique ID for the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentSignedLink(id: number, options?: any) {
            return DocsApiFp(configuration).getDocumentSignedLink(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get all documents within a specific category
         * @param {number} id Unique ID for the category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsForCategory(id: number, options?: any) {
            return DocsApiFp(configuration).getDocumentsForCategory(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get raw view records for a specific document
         * @param {number} id Unique ID for the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewsForDocument(id: number, options?: any) {
            return DocsApiFp(configuration).getViewsForDocument(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get all of the document views for a specific staff member. This will return all documents in the system, along with view information.
         * @param {string} staffid Unique ID for the staff member
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewsForStaffMember(staffid: string, options?: any) {
            return DocsApiFp(configuration).getViewsForStaffMember(staffid, options)(axios, basePath);
        },
        /**
         * 
         * @summary Save that a user viewed a document
         * @param {DocumentViewRecord} documentViewRecord 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logView(documentViewRecord: DocumentViewRecord, options?: any) {
            return DocsApiFp(configuration).logView(documentViewRecord, options)(axios, basePath);
        },
        /**
         * 
         * @summary Save a category to the DB. If the ID is greater than 0 perform an update, if 0 perform an insert.
         * @param {Category} category 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCategory(category: Category, options?: any) {
            return DocsApiFp(configuration).saveCategory(category, options)(axios, basePath);
        },
        /**
         * 
         * @summary Save a document to the system
         * @param {DocumentUploadRequest} documentUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDocument(documentUploadRequest: DocumentUploadRequest, options?: any) {
            return DocsApiFp(configuration).saveDocument(documentUploadRequest, options)(axios, basePath);
        },
    };
};

/**
 * DocsApi - object-oriented interface
 * @export
 * @class DocsApi
 * @extends {BaseAPI}
 */
export class DocsApi extends BaseAPI {
    /**
     * 
     * @summary Delete a specific category
     * @param {number} id Unique ID for the category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocsApi
     */
    public deleteCategory(id: number, options?: any) {
        return DocsApiFp(this.configuration).deleteCategory(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Delete a specific document
     * @param {number} id Unique ID for the document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocsApi
     */
    public deleteDocument(id: number, options?: any) {
        return DocsApiFp(this.configuration).deleteDocument(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a list of all categories in the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocsApi
     */
    public getAllCategories(options?: any) {
        return DocsApiFp(this.configuration).getAllCategories(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a specific Category
     * @param {number} id Unique ID for the category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocsApi
     */
    public getCategory(id: number, options?: any) {
        return DocsApiFp(this.configuration).getCategory(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get the view counts for every category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocsApi
     */
    public getCategoryViewCounts(options?: any) {
        return DocsApiFp(this.configuration).getCategoryViewCounts(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a specific document
     * @param {number} id Unique ID for the document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocsApi
     */
    public getDocument(id: number, options?: any) {
        return DocsApiFp(this.configuration).getDocument(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get the raw data for a specific document
     * @param {number} id Unique ID for the document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocsApi
     */
    public getDocumentRawData(id: number, options?: any) {
        return DocsApiFp(this.configuration).getDocumentRawData(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a timed link to download the file for a document
     * @param {number} id Unique ID for the document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocsApi
     */
    public getDocumentSignedLink(id: number, options?: any) {
        return DocsApiFp(this.configuration).getDocumentSignedLink(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get all documents within a specific category
     * @param {number} id Unique ID for the category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocsApi
     */
    public getDocumentsForCategory(id: number, options?: any) {
        return DocsApiFp(this.configuration).getDocumentsForCategory(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get raw view records for a specific document
     * @param {number} id Unique ID for the document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocsApi
     */
    public getViewsForDocument(id: number, options?: any) {
        return DocsApiFp(this.configuration).getViewsForDocument(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get all of the document views for a specific staff member. This will return all documents in the system, along with view information.
     * @param {string} staffid Unique ID for the staff member
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocsApi
     */
    public getViewsForStaffMember(staffid: string, options?: any) {
        return DocsApiFp(this.configuration).getViewsForStaffMember(staffid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Save that a user viewed a document
     * @param {DocumentViewRecord} documentViewRecord 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocsApi
     */
    public logView(documentViewRecord: DocumentViewRecord, options?: any) {
        return DocsApiFp(this.configuration).logView(documentViewRecord, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Save a category to the DB. If the ID is greater than 0 perform an update, if 0 perform an insert.
     * @param {Category} category 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocsApi
     */
    public saveCategory(category: Category, options?: any) {
        return DocsApiFp(this.configuration).saveCategory(category, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Save a document to the system
     * @param {DocumentUploadRequest} documentUploadRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocsApi
     */
    public saveDocument(documentUploadRequest: DocumentUploadRequest, options?: any) {
        return DocsApiFp(this.configuration).saveDocument(documentUploadRequest, options)(this.axios, this.basePath);
    }

}

