import React from "react";
import {Category} from "../../../api/ppb";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../Button/MCButton";

const CategoryButtonItem = ({category, onCategoryClicked}: CategoryButtonItemProps) => {
    return (
        <div className="col-6 category-button">
            <MCButton
                size={ButtonSize.ExtraLarge}
                innerValue={category.name}
                onClick={() => onCategoryClicked(category)}
                colour={ButtonColourOptions.DarkBlue}
                className={"w-100"}
            />
        </div>
    );
};

export default CategoryButtonItem;

interface CategoryButtonItemProps {
    onCategoryClicked: (category: Category) => void;
    category: Category;
}
