import {StoreServiceData, ServiceActionTypes} from "store-fetch-wrappers";
import {CATEGORIES_STORE_STATE} from "../actions/CategoriesActionTypes";
import {Category} from "../../../api/ppb";
import {createDefaultStoreState, createReducer} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<Category[]>([]);

const categoriesReducer = (
    state: StoreServiceData<Category[]> = defaultState,
    action: ServiceActionTypes<Category[]>
): StoreServiceData<Category[]> =>
    createReducer(state, action, CATEGORIES_STORE_STATE, () => showErrorToast(action.error));

export default categoriesReducer;
