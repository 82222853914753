import {Auth, CognitoHostedUIIdentityProvider} from "@aws-amplify/auth";
import axios from "axios";
import {Dispatch} from "redux";
import {AUTH_STORE_STATE, AuthDispatchTypes} from "./AuthActionTypes";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import StaffApiModel from "../../apiModel/StaffApiModel";
import store from "../../Store";
import {routeNames} from "../../../components/Navigation/routeNames";
import history from "../../../components/Navigation/Routes/history";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import {Amplify} from "aws-amplify";
import {Hub} from "@aws-amplify/core";

export const login = () => async (dispatch: Dispatch<AuthDispatchTypes>) => {
    dispatch({
        type: AUTH_STORE_STATE.LOADING,
        error: null,
        loading: true,
        authenticated: false
    });

    await Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Cognito});
};

export const unAuthenticateUser = () => async (dispatch: Dispatch<AuthDispatchTypes>) => {
    dispatch({
        type: AUTH_STORE_STATE.AUTHENTICATED,
        error: null,
        loading: false,
        authenticated: false
    });
};

export const logout = () => async (dispatch: Dispatch<AuthDispatchTypes>) => {
    try {
        dispatch({
            type: AUTH_STORE_STATE.AUTHENTICATED,
            error: null,
            loading: false,
            authenticated: false
        });
        await Auth.signOut();
        await Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Cognito});
    } catch (e: any) {
        dispatch({
            type: AUTH_STORE_STATE.ERROR,
            error: e,
            loading: false,
            authenticated: false
        });
    }
};

export const fetchUserData = (username: string) => {
    return async (dispatch: Dispatch<AuthDispatchTypes>) => {
        try {
            const success = await getDataFromServiceWithRedux(
                AUTH_STORE_STATE,
                dispatch,
                () => StaffApiModel.getUsersApi().getUserByUsername(username),
                statusCodeCallback
            );
            if (!success) return;
            dispatch({
                type: AUTH_STORE_STATE.AUTHENTICATED,
                authenticated: true,
                error: null,
                loading: false
            });
        } catch (e: any) {
            dispatch({
                type: AUTH_STORE_STATE.ERROR,
                error: e,
                loading: false,
                authenticated: false
            });
        }
    };
};

export async function handleUpdatedCredentials(): Promise<AuthCredentials | null> {
    try {
        const user = await Auth.currentAuthenticatedUser();

        if (user && user.username) {
            const jwtToken = user.getSignInUserSession().getAccessToken().getJwtToken();

            axios.defaults.headers.common = {
                Authorization: "Bearer " + jwtToken
            };
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await store.dispatch(fetchUserData(user.username));

            return {
                jwtToken,
                username: user.username
            };
        } else {
            handleLoggedOut();
        }
    } catch (e) {
        goToSignIn();
    }

    return null;
}

function goToSignIn() {
    history.replace(routeNames.login.path);
}

function handleLoggedOut() {
    // Clear bearer auth and local state
    delete axios.defaults.headers.common["Authorization"];
}

export async function initAuth(config: any) {
    if (!config) throw Error("Auth config is missing or empty");

    Amplify.configure(config);

    Hub.listen("auth", (data) => {
        const {payload} = data;
        switch (payload.event) {
            case "signIn":
                handleUpdatedCredentials();
                break;
            case "oAuthSignOut":
                handleLoggedOut();
                break;
        }
    });

    await handleUpdatedCredentials();
}

let lastError = 0;
let retryCount = 0;

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        const status = error.response ? error.response.status : null;
        if (status !== 401) throw error;

        // Auto clear retry count if last error was over 10 seconds ago
        const now = Math.round(new Date().getTime() / 1000);
        const delta = now - lastError;

        if (delta > 10000) retryCount = 0;
        lastError = now;

        // Only try a finite number of times
        if (++retryCount > 20) {
            history.push(routeNames.inActive.path);
            throw error;
        }

        const creds = await handleUpdatedCredentials();
        if (creds && creds.jwtToken) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + creds.jwtToken;
            error.config.headers["Authorization"] = "Bearer " + creds.jwtToken;

            return await axios.request(error.config);
        } else {
            throw error;
        }
    }
);

interface AuthCredentials {
    jwtToken: string;
    username: string;
}

export const RedirectQuery = {
    redirectUri: "redirect_uri"
};
