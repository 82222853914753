import React, {useEffect, useRef, useState} from "react";
import {Document} from "../../../../api/ppb";
import FormHeader from "../../../Form/FormHeader";
import FormRow from "../../../Form/FormRow";
import {DebounceInput} from "react-debounce-input";
import {useDispatch, useSelector} from "react-redux";
import {
    nullifyDocumentStore,
    saveDocumentToService,
    setDocument
} from "../../../../store/document/actions/DocumentActions";
import CategoryDropdown, {getEmptyCategory} from "../../../Dropdown/CategoryDropdown";
import {formatUnixToLLL} from "../../../../utils/momentUtils";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import moment from "moment";
import {showErrorToast, showSuccessToast} from "../../../../utils/toastUtils";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../Navigation/routeNames";
import FormActionContainer from "../../../Form/FormActionContainer";
import {Modal, useModal} from "pulse-modal";
import {RootStore} from "../../../../store/Store";
import {
    fetchSignedLink,
    nullifySignedLink
} from "../../../../store/downloadUrl/actions/DownloadUrlActions";
import {createDownloadAnchorAndDownload} from "../../../../utils/downloadUtils";

const EditDocumentForm = (props: Document) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const uploadButtonRef = useRef(null);
    const [base64EncodedUrl, setBase64EncodedUrl] = useState<string>("");
    const {toggle, isShown} = useModal();
    const {loading} = useSelector((state: RootStore) => state.downloadUrl);

    useEffect(() => {
        return () => {
            dispatch(nullifyDocumentStore());
            dispatch(nullifySignedLink());
        };
    }, []);

    /** Triggers the synthetic click to select a file to upload. */
    const selectFile = () => {
        if (uploadButtonRef && uploadButtonRef.current) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            uploadButtonRef.current.click();
        }
    };

    const onFileSelected = async (event: any) => {
        const file = event?.target?.files[0];
        dispatch(
            setDocument({
                ...props,
                filename: file.name,
                mimeType: file.type,
                date: moment().unix()
            })
        );

        const b64 = await toBase64(file);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setBase64EncodedUrl(b64.split("base64,")[1]);
    };

    const save = async () => {
        const valid = isFormValid(props, base64EncodedUrl);
        if (!valid) return;

        if (props.id === 0) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const success: boolean = await dispatch(
                saveDocumentToService({
                    entity: props,
                    file: base64EncodedUrl
                })
            );
            if (!success) return;
            showSuccessToast("Saved Document");
            navigateToDocumentsList();

            return;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(
            saveDocumentToService({
                entity: props,
                file: base64EncodedUrl.length > 0 ? base64EncodedUrl : undefined
            })
        );
        if (!success) return;
        showSuccessToast("Updated Document");
        navigateToDocumentsList();
    };

    const navigateToDocumentsList = () => {
        if (props.categoryId === 0) {
            history.push(`${routeNames.documentList.path}`);
            return;
        }
        history.push(`${routeNames.documentByCategory.path}/${props.categoryId}`);
    };

    useEffect(() => {
        if (base64EncodedUrl.length > 0 && props.id > 0) {
            const valid = isFormValid(props, base64EncodedUrl);
            if (!valid) return;

            dispatch(
                saveDocumentToService({
                    entity: props,
                    file: base64EncodedUrl
                })
            );
        }
    }, [base64EncodedUrl]);

    const downloadFile = async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const file: string = await dispatch(fetchSignedLink(props.id));

        createDownloadAnchorAndDownload(props.filename, file);
    };

    return (
        <React.Fragment>
            <div className="mt-3">
                <FormHeader headerName={"Document Form"} />
                <FormRow rowName={"Document Name"} columnDetailClassName={"pr-0 pl-0"}>
                    <DebounceInput
                        debounceTimeout={300}
                        className={"input-fields"}
                        inputType={"input"}
                        onChange={(event) =>
                            dispatch(
                                setDocument({
                                    ...props,
                                    name: event.target.value
                                })
                            )
                        }
                        disabled={false}
                        value={props.name}
                        placeholder="Enter document name..."
                    />
                </FormRow>
                <FormRow rowName={"Select Category"} columnDetailClassName={"pr-0 pl-0"}>
                    <CategoryDropdown
                        item={{
                            ...getEmptyCategory(),
                            id: props.categoryId
                        }}
                        changeOption={(newCategory) => {
                            if (!newCategory) return;
                            dispatch(
                                setDocument({
                                    ...props,
                                    categoryId: newCategory.id
                                })
                            );
                        }}
                    />
                </FormRow>
                <FormRow rowName={"Upload Document"}>
                    <React.Fragment>
                        {props.filename.length === 0 ? (
                            <p className="mt-2">No document has been uploaded</p>
                        ) : (
                            <p className="mt-2">{props.filename}</p>
                        )}
                        <input
                            ref={uploadButtonRef}
                            multiple={true}
                            type="file"
                            className={"d-none"}
                            onChange={onFileSelected}
                        />
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={props.id > 0 ? "Update File" : "Upload File"}
                            onClick={selectFile}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                        {props.id > 0 && (
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={loading ? "Downloading..." : "Download"}
                                onClick={downloadFile}
                                colour={ButtonColourOptions.Yellow}
                                disabled={loading}
                                roundedCorner
                            />
                        )}
                    </React.Fragment>
                </FormRow>
                {props.date > 0 && (
                    <FormRow rowName={"Upload Date"}>
                        <p>{formatUnixToLLL(props.date)}</p>
                    </FormRow>
                )}
                <FormActionContainer withColumn>
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Save"}
                        onClick={save}
                        colour={ButtonColourOptions.Yellow}
                        roundedCorner
                    />
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Cancel"}
                        onClick={toggle}
                        colour={ButtonColourOptions.DarkBlue}
                        roundedCorner
                    />
                </FormActionContainer>
            </div>
            <Modal
                modalSize={"sm"}
                title={"Unsaved Changes"}
                bodyChildren={
                    <div className="row ml-0 mr-0">
                        <p className="mb-0">
                            There are unsaved changes on this page, are you sure you want to
                            navigate away?
                        </p>
                    </div>
                }
                onClose={toggle}
                isShown={isShown}
                footerChildren={
                    <React.Fragment>
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Yes"}
                            onClick={navigateToDocumentsList}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"No"}
                            onClick={toggle}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    </React.Fragment>
                }
            />
        </React.Fragment>
    );
};

export default EditDocumentForm;

function toBase64(file: any) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

function isFormValid(document: Document, base64EncodedUrl: string): boolean {
    let valid = true;

    if (document.name.length < 3) {
        showErrorToast("Document name must be more than 2 characters");
        valid = false;
    }

    if (document.id === 0 && base64EncodedUrl.length < 1) {
        showErrorToast("No file has been uploaded. Please upload a file.");
        valid = false;
    }

    return valid;
}
