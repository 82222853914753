import React from "react";
import CacheBuster from "react-cache-buster";
import {Loading} from "store-fetch-wrappers";
import pjson from "../../../package.json";

interface Props {
    children: JSX.Element | JSX.Element[] | number | string | boolean;
}

function CacheBusterWrapper({children}: Props): JSX.Element {
    const isProduction = process.env.NODE_ENV === "production";

    return (
        <React.Fragment>
            <CacheBuster
                currentVersion={pjson.version}
                isEnabled={isProduction}
                isVerboseMode={false}
                loadingComponent={<Loading type={"three-ring"} showLoadingText={false} />}
            >
                {children}
            </CacheBuster>
        </React.Fragment>
    );
}

export default CacheBusterWrapper;
