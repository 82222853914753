import {StoreServiceData} from "store-fetch-wrappers";
import {UserCount} from "../../../api/staff";
import {createStoreState} from "store-fetch-wrappers/dist";

export const USER_COUNT_STORE_STATE = createStoreState("user_count");

export interface UserCountLoading extends StoreServiceData<UserCount> {
    type: typeof USER_COUNT_STORE_STATE.LOADING;
}

export interface UserCountError extends StoreServiceData<UserCount> {
    type: typeof USER_COUNT_STORE_STATE.ERROR;
}

export interface UserCountSuccess extends StoreServiceData<UserCount> {
    type: typeof USER_COUNT_STORE_STATE.SUCCESS;
}

export type UserCountDispatchTypes = UserCountError | UserCountLoading | UserCountSuccess;
