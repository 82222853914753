import {Dispatch} from "redux";
import {SIDEBAR_STORE_STATE, SidebarDispatchTypes} from "./SidebarActionTypes";

export const setSidebarToggled =
    (toggled: boolean) => async (dispatch: Dispatch<SidebarDispatchTypes>) => {
        dispatch({
            type: SIDEBAR_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: toggled
        });
    };
