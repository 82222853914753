import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {McConfig} from "../../../McConfigPlugin";

export const MC_CONFIG_STORE = createStoreState("mc_config");

interface McConfigLoading extends StoreServiceData<McConfig> {
    type: typeof MC_CONFIG_STORE.LOADING;
}

interface McConfigError extends StoreServiceData<McConfig> {
    type: typeof MC_CONFIG_STORE.ERROR;
}

interface McConfigSuccess extends StoreServiceData<McConfig> {
    type: typeof MC_CONFIG_STORE.SUCCESS;
}

export type McConfigDispatchTypes = McConfigError | McConfigLoading | McConfigSuccess;
