import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../Navigation/routeNames";
import {RootStore} from "../../../store/Store";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {getDocumentsForCategory} from "../../../store/documents/actions/DocumentsActions";
import {WithServiceState} from "store-fetch-wrappers";
import ViewDocumentsByCategoryTable from "./ViewDocumentsByCategoryTable";
import {usePageUrl} from "../../Hooks/usePageUrl";

const ViewDocumentsByCategoryTableWrapper = WithServiceState(ViewDocumentsByCategoryTable);

const ViewDocumentsByCategory = () => {
    const dispatch = useDispatch();
    const documentsStoreState = useSelector((state: RootStore) => state.documents);
    const params: any = useParams();
    const {fullPath} = usePageUrl();
    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.overview.name,
                path: routeNames.overview.path
            },
            {
                name: routeNames.categoryList.name,
                path: routeNames.categoryList.path
            },
            {
                name: routeNames.documentList.name,
                path: fullPath
            },
            {
                name: routeNames.myDocuments.name,
                path: routeNames.myDocuments.path
            },
            {
                name: routeNames.staffCompliance.name,
                path: routeNames.staffCompliance.path
            }
        ];

        dispatch(setNavigationItems(navItems));

        dispatch(getDocumentsForCategory(+params.id));
    }, []);

    return (
        <div className="page-container">
            <ViewDocumentsByCategoryTableWrapper
                showLoadingText={true}
                loaderWheelType={"three-ring"}
                loaderType={"overlay"}
                placeholderBlockCount={1}
                {...documentsStoreState}
            />
        </div>
    );
};

export default ViewDocumentsByCategory;
