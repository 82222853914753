import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {NavigationItem} from "../../../../store/navigation/actions/NavigationActionTypes";
import {setNavigationItems} from "../../../../store/navigation/actions/NavigationActions";
import {routeNames} from "../../../Navigation/routeNames";
import {useUserData} from "../../../Hooks/useUserData";
import {StaffAccessLevel, UserData} from "../../../../api/staff";

const NotAuthorised = () => {
    const dispatch = useDispatch();
    const user = useUserData();

    useEffect(() => {
        if (user.username.length === 0) return;

        //Set Nav Items
        dispatch(setNavigationItems(getNotFoundNotAuthorisedNavItemsForUser(user)));
    }, [user]);

    return (
        <div className="page background-stars">
            <h3 className="text-center title pt-5 header-font">Error 403 - Not Authorised</h3>
            <div className="mt-5 text-center">
                <p>You are not authorised to view this resource</p>
            </div>
        </div>
    );
};

export default NotAuthorised;

export function getNotFoundNotAuthorisedNavItemsForUser(user: UserData): NavigationItem[] {
    switch (user.accessLevel) {
        case StaffAccessLevel.SystemAdministrator:
            return [
                {
                    name: routeNames.categoryList.name,
                    path: routeNames.categoryList.path
                },
                {
                    name: routeNames.documentList.name,
                    path: routeNames.documentList.path
                },
                {
                    name: routeNames.myDocuments.name,
                    path: routeNames.myDocuments.path
                },
                {
                    name: routeNames.staffCompliance.name,
                    path: routeNames.staffCompliance.path
                }
            ];
        case StaffAccessLevel.DutyManager:
        case StaffAccessLevel.Staff:
            return [
                {
                    name: routeNames.myDocuments.name,
                    path: routeNames.myDocuments.path
                }
            ];
    }
}
