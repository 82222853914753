import {StoreServiceData, ServiceActionTypes} from "store-fetch-wrappers";
import {DocumentViewRecordWithDocument} from "../../../api/ppb";
import {USER_DOCUMENTS_STORE_STATE} from "../actions/UserDocumentsActionTypes";
import {createDefaultStoreState, createReducer} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<DocumentViewRecordWithDocument[]>([]);

const userDocumentReducer = (
    state: StoreServiceData<DocumentViewRecordWithDocument[]> = defaultState,
    action: ServiceActionTypes<DocumentViewRecordWithDocument[]>
): StoreServiceData<DocumentViewRecordWithDocument[]> =>
    createReducer(state, action, USER_DOCUMENTS_STORE_STATE, () => showErrorToast(action.error));

export default userDocumentReducer;
