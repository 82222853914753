import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {routeNames} from "../../Navigation/routeNames";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {RootStore} from "../../../store/Store";
import {StaffAccessLevel, UserData} from "../../../api/staff";
import {WithServiceState} from "store-fetch-wrappers";
import {useUserData} from "../../Hooks/useUserData";
import {getDataForDocumentListButtons} from "../DocumentsList/ViewDocumentList";
import ViewDocumentsCategoryButtonList from "../DocumentsList/ViewDocumentsCategoryButtonList";
import {
    getCategoriesList,
    nullifyCategoryListStore
} from "../../../store/categories/actions/CategoriesActions";
import useMcConfig from "../../Hooks/useMcConfig";
import {McConfig} from "../../../McConfigPlugin";
import {getUserAccessLevel} from "../../../utils/userDataUtils";

const ServiceWrapper = WithServiceState(ViewDocumentsCategoryButtonList);

const ViewUserDocuments = () => {
    const dispatch = useDispatch();
    const user = useUserData();
    const categoriesState = useSelector((state: RootStore) => state.categories);
    const {config} = useMcConfig();

    useEffect(() => {
        return () => {
            dispatch(nullifyCategoryListStore());
        };
    }, []);

    useEffect(() => {
        if (!config.auth) return;
        //Set Nav Items
        dispatch(setNavigationItems(getNavItemsForUserForMyDocuments(user, config)));
        dispatch(getCategoriesList());
    }, [user, config]);

    return (
        <div className="page-container mt-4">
            <ServiceWrapper
                showLoadingText={true}
                loaderType={"overlay"}
                loaderWheelType={"three-ring"}
                placeholderBlockCount={1}
                data={getDataForDocumentListButtons(categoriesState, true)}
                loading={categoriesState.loading}
                error={categoriesState.error}
            />
        </div>
    );
};

export default ViewUserDocuments;

function getNavItemsForUserForMyDocuments(user: UserData, config: McConfig): NavigationItem[] {
    const accessLevel = getUserAccessLevel(user, config);
    switch (accessLevel) {
        case StaffAccessLevel.SystemAdministrator:
            return [
                {
                    name: routeNames.overview.name,
                    path: routeNames.overview.path
                },
                {
                    name: routeNames.categoryList.name,
                    path: routeNames.categoryList.path
                },
                {
                    name: routeNames.documentList.name,
                    path: routeNames.documentList.path
                },
                {
                    name: routeNames.myDocuments.name,
                    path: routeNames.myDocuments.path
                },
                {
                    name: routeNames.staffCompliance.name,
                    path: routeNames.staffCompliance.path
                }
            ];
        case StaffAccessLevel.DutyManager:
        case StaffAccessLevel.Staff:
            return [
                {
                    name: routeNames.myDocuments.name,
                    path: routeNames.myDocuments.path
                }
            ];
    }
}
