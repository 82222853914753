import {Dispatch} from "redux";
import {
    NAVIGATION_STORE_STATE,
    NavigationDispatchTypes,
    NavigationItem
} from "./NavigationActionTypes";

export const setNavigationItems = (navItems: NavigationItem[]) => {
    return async (dispatch: Dispatch<NavigationDispatchTypes>) => {
        dispatch({
            type: NAVIGATION_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: navItems
        });
    };
};
