import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const SIDEBAR_STORE_STATE = createStoreState("sidebar_page");

export interface SidebarPageLoading extends StoreServiceData<boolean> {
    type: typeof SIDEBAR_STORE_STATE.LOADING;
}

export interface SidebarPageError extends StoreServiceData<boolean> {
    type: typeof SIDEBAR_STORE_STATE.ERROR;
}

export interface SidebarPageSuccess extends StoreServiceData<boolean> {
    type: typeof SIDEBAR_STORE_STATE.SUCCESS;
}

export type SidebarDispatchTypes = SidebarPageLoading | SidebarPageError | SidebarPageSuccess;
