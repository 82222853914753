import {Category} from "../../../api/ppb";
import {StoreServiceData} from "store-fetch-wrappers";
import {createStoreState} from "store-fetch-wrappers/dist";

export const CATEGORY_STORE_STATE = createStoreState("category_page");

export interface CategoryLoading extends StoreServiceData<Category> {
    type: typeof CATEGORY_STORE_STATE.LOADING;
}

export interface CategoryError extends StoreServiceData<Category> {
    type: typeof CATEGORY_STORE_STATE.ERROR;
}

export interface CategorySuccess extends StoreServiceData<Category> {
    type: typeof CATEGORY_STORE_STATE.SUCCESS;
}

export type CategoryDispatchTypes = CategorySuccess | CategoryLoading | CategoryError;
