import {Dispatch} from "redux";
import {CATEGORIES_STORE_STATE, CategoriesDispatchTypes} from "./CategoriesActionTypes";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import PPBApiModel from "../../apiModel/PPBApiModel";
import {statusCodeCallback} from "../../helpers/storeHelpers";

export const nullifyCategoryListStore = () => {
    return async (dispatch: Dispatch<CategoriesDispatchTypes>) => {
        dispatch({
            type: CATEGORIES_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

export const getCategoriesList = () => {
    return async (dispatch: Dispatch<CategoriesDispatchTypes>) => {
        try {
            await getDataFromServiceWithRedux(
                CATEGORIES_STORE_STATE,
                dispatch,
                () => PPBApiModel.getDocsApi().getAllCategories(),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: CATEGORIES_STORE_STATE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};
