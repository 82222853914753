import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../Navigation/routeNames";
import {
    createNewDocument,
    nullifyDocumentStore
} from "../../../store/document/actions/DocumentActions";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {WithServiceState} from "store-fetch-wrappers";
import EditDocumentForm from "./Components/EditDocumentForm";

const ServiceWrapper = WithServiceState(EditDocumentForm);

const AddDocument = () => {
    const dispatch = useDispatch();
    const documentStore = useSelector((state: RootStore) => state.document);

    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.overview.name,
                path: routeNames.overview.path
            },
            {
                name: routeNames.categoryList.name,
                path: routeNames.categoryList.path
            },
            {
                name: routeNames.documentList.name,
                path: routeNames.documentList.path
            },
            {
                name: routeNames.myDocuments.name,
                path: routeNames.myDocuments.path
            },
            {
                name: routeNames.staffCompliance.name,
                path: routeNames.staffCompliance.path
            }
        ];

        //Set Nav Items
        dispatch(setNavigationItems(navItems));

        dispatch(createNewDocument());

        return () => {
            dispatch(nullifyDocumentStore());
        };
    }, []);

    return (
        <div className="page-container">
            <ServiceWrapper
                placeholderBlockCount={1}
                loaderType={"overlay"}
                loaderWheelType={"three-ring"}
                showLoadingText={true}
                {...documentStore}
            />
        </div>
    );
};

export default AddDocument;
