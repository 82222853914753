import {StoreServiceData, ServiceActionTypes} from "store-fetch-wrappers";
import {Document} from "../../../api/ppb";
import {DOCUMENT_STORE_STATE} from "../actions/DocumentActionTypes";
import {createDefaultStoreState, createReducer} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<Document>(null);

const documentReducer = (
    state: StoreServiceData<Document> = defaultState,
    action: ServiceActionTypes<Document>
): StoreServiceData<Document> =>
    createReducer(state, action, DOCUMENT_STORE_STATE, () => showErrorToast(action.error));

export default documentReducer;
