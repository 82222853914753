import {StoreServiceData} from "store-fetch-wrappers";
import {createStoreState} from "store-fetch-wrappers/dist";

export const RAW_DOWNLOAD_FILE_STORE_STATE = createStoreState("raw_download_file");

export interface RawDownloadFileSuccess extends StoreServiceData<string> {
    type: typeof RAW_DOWNLOAD_FILE_STORE_STATE.SUCCESS;
}

export interface RawDownloadFileLoading extends StoreServiceData<string> {
    type: typeof RAW_DOWNLOAD_FILE_STORE_STATE.LOADING;
}

export interface RawDownloadFileError extends StoreServiceData<string> {
    type: typeof RAW_DOWNLOAD_FILE_STORE_STATE.ERROR;
}

export type RawDownloadFileDispatchTypes =
    | RawDownloadFileError
    | RawDownloadFileLoading
    | RawDownloadFileSuccess;
