import {StoreServiceData} from "store-fetch-wrappers";
import {CategoryViewStat} from "../../../api/ppb";
import {createStoreState} from "store-fetch-wrappers/dist";

export const VIEW_COUNT_STORE_STATE = createStoreState("view_count");

export interface ViewCountLoading extends StoreServiceData<CategoryViewStat[]> {
    type: typeof VIEW_COUNT_STORE_STATE.LOADING;
}

export interface ViewCountError extends StoreServiceData<CategoryViewStat[]> {
    type: typeof VIEW_COUNT_STORE_STATE.ERROR;
}

export interface ViewCountSuccess extends StoreServiceData<CategoryViewStat[]> {
    type: typeof VIEW_COUNT_STORE_STATE.SUCCESS;
}

export type ViewCountDispatchTypes = ViewCountSuccess | ViewCountError | ViewCountLoading;
