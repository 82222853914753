import {StoreServiceData} from "store-fetch-wrappers";
import {Document} from "../../../api/ppb";
import {createStoreState} from "store-fetch-wrappers/dist";

export const DOCUMENTS_STORE_STATE = createStoreState("document_list_page");

export interface DocumentsLoading extends StoreServiceData<Document[]> {
    type: typeof DOCUMENTS_STORE_STATE.LOADING;
}

export interface DocumentsError extends StoreServiceData<Document[]> {
    type: typeof DOCUMENTS_STORE_STATE.ERROR;
}

export interface DocumentsSuccess extends StoreServiceData<Document[]> {
    type: typeof DOCUMENTS_STORE_STATE.SUCCESS;
}

export type DocumentsDispatchTypes = DocumentsSuccess | DocumentsError | DocumentsLoading;
