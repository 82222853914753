import {StoreServiceData} from "store-fetch-wrappers";
import {Document} from "../../../api/ppb";
import {createStoreState} from "store-fetch-wrappers/dist";

export const DOCUMENT_STORE_STATE = createStoreState("document_page");

export interface DocumentLoading extends StoreServiceData<Document> {
    type: typeof DOCUMENT_STORE_STATE.LOADING;
}

export interface DocumentError extends StoreServiceData<Document> {
    type: typeof DOCUMENT_STORE_STATE.ERROR;
}

export interface DocumentSuccess extends StoreServiceData<Document> {
    type: typeof DOCUMENT_STORE_STATE.SUCCESS;
}

export type DocumentDispatchTypes = DocumentSuccess | DocumentError | DocumentLoading;
