import {useCallback, useState} from "react";

/** Use Toggle hook */
export const useToggle = (initialState = false): [boolean, any] => {
    // Initialize the state
    const [state, setState] = useState<boolean>(initialState);
    // Define and memorize toggler function in case we pass down the comopnent,
    // This function change the boolean value to it's opposite value
    const toggle = useCallback((): void => setState((v) => !v), []);
    return [state, toggle];
};
