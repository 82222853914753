import React, {useEffect} from "react";
import {Provider, useDispatch} from "react-redux";
import store from "./store/Store";
import Routes from "./components/Navigation/Routes/Routes";
import {Router} from "react-router-dom";
import history from "./components/Navigation/Routes/history";
import {ToastContainer} from "react-toastify";
import {getMcConfig} from "./store/mcConfig/actions/McConfigActions";
import CacheBusterWrapper from "./components/CacheBuster/CacheBusterWrapper";

const App = () => {
    return (
        <CacheBusterWrapper>
            <Provider store={store}>
                <RouteWrapper />
                <ToastContainer
                    position="bottom-center"
                    pauseOnHover
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    draggable
                    autoClose={5000}
                />
            </Provider>
        </CacheBusterWrapper>
    );
};

export default App;

function RouteWrapper() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMcConfig());
    }, []);
    return (
        <Router history={history}>
            <Routes />
        </Router>
    );
}
