import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../../../store/Store";
import Icon, {IconType} from "../../../../../Icon/Icon";
import {setSidebarToggled} from "../../../../../../store/sidebar/actions/SidebarActions";
import SidebarMenu from "./Components/SidebarMenu";

const Sidebar = () => {
    const sidebarOpen = useSelector((state: RootStore) => state.sidebar.data) || false;
    const dispatch = useDispatch();
    const closeSidebar = () => {
        dispatch(setSidebarToggled(false));
    };
    return (
        <React.Fragment>
            {sidebarOpen && (
                <div className="mobile">
                    <div className="sidebar-bg main-menu-overlay fadeInMenu" />
                    <div className="sidebar-wrapper">
                        <div className="sidebar-container">
                            <SidebarMenu />
                        </div>
                        <Icon
                            icon={IconType.Cross}
                            rootElementClassName="sidebar-close pr-3 fadeIn"
                            size={"Medium"}
                            rootElement={"Span"}
                            className="nav-links"
                            onClick={closeSidebar}
                        />
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default Sidebar;
