import PPBApiModel from "../../apiModel/PPBApiModel";
import {Dispatch} from "redux";
import {DOCUMENTS_STORE_STATE, DocumentsDispatchTypes} from "./DocumentsActionTypes";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

export const nullifyDocumentStore = () => {
    return async (dispatch: Dispatch<DocumentsDispatchTypes>) => {
        dispatch({
            type: DOCUMENTS_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: null
        });
    };
};
export const getDocumentsForCategory = (categoryId: number) => {
    return async (dispatch: Dispatch<DocumentsDispatchTypes>) => {
        try {
            await getDataFromServiceWithRedux(
                DOCUMENTS_STORE_STATE,
                dispatch,
                () => PPBApiModel.getDocsApi().getDocumentsForCategory(categoryId),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: DOCUMENTS_STORE_STATE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};
