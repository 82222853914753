import {UserData} from "../../../api/staff";
import {StoreServiceData} from "store-fetch-wrappers";
import {createStoreState} from "store-fetch-wrappers/dist";

export const USER_STORE_STATE = createStoreState("user_store");

interface UserLoading extends StoreServiceData<UserData> {
    type: typeof USER_STORE_STATE.LOADING;
}
interface UserError extends StoreServiceData<UserData> {
    type: typeof USER_STORE_STATE.ERROR;
}
interface UserSuccess extends StoreServiceData<UserData> {
    type: typeof USER_STORE_STATE.SUCCESS;
}

export type UserDispatchTypes = UserSuccess | UserError | UserLoading;
