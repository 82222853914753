import {ServiceActionTypes, StoreServiceData} from "store-fetch-wrappers";
import {RAW_DOWNLOAD_FILE_STORE_STATE} from "../actions/RawDownloadActionTypes";
import {createDefaultStoreState, createReducer} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<string>(null);

const rawDownloadFileReducer = (
    state: StoreServiceData<string> = defaultState,
    action: ServiceActionTypes<string>
): StoreServiceData<string> =>
    createReducer(state, action, RAW_DOWNLOAD_FILE_STORE_STATE, () => showErrorToast(action.error));

export default rawDownloadFileReducer;
