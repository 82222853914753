import React, {useContext} from "react";
import SubSystemHeader from "./SubSystemHeader";
import Topbar from "./Topbar";
import {LayoutHeaderContext} from "../../Context/LayoutHeaderContext";

const Header = () => {
    const showHeader = useContext(LayoutHeaderContext);
    return (
        <React.Fragment>
            <div className="header-wrapper stuck">
                <Topbar />
                {showHeader && <SubSystemHeader />}
            </div>
        </React.Fragment>
    );
};

export default Header;

export interface HeaderProps {
    showNavigation: boolean;
}
