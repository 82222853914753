import React, {useState} from "react";
import {StaffLink} from "../../../Dropdown/Helpers/dropdownUtils";
import StaffDropdown from "../../../Dropdown/StaffDropdown";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";
import FormHeader from "../../../Form/FormHeader";
import FormRow from "../../../Form/FormRow";
import {fetchDocumentsForStaffMember} from "../../../../store/userDocuments/actions/UserDocumentsActions";
import StaffComplianceList from "./StaffComplianceList";
import {WithServiceState} from "store-fetch-wrappers";
const ServiceWrapper = WithServiceState(StaffComplianceList);

const StaffComplianceContainer = () => {
    const [staffMember, setStaffMember] = useState<StaffLink>();
    const documentStoreState = useSelector((state: RootStore) => state.userDocuments);
    const dispatch = useDispatch();
    const onStaffMemberSelected = (staff?: StaffLink) => {
        setStaffMember(staff);
        if (!staff) return;
        dispatch(fetchDocumentsForStaffMember(staff.staffId));
    };

    return (
        <React.Fragment>
            <FormHeader headerName={"Staff Compliance"} />
            <FormRow rowName={"Select Staff Member"} columnDetailClassName={"pr-0 pl-0"}>
                <StaffDropdown
                    searchable={true}
                    changeOption={onStaffMemberSelected}
                    clearable={true}
                    id={""}
                    disabled={false}
                />
            </FormRow>

            {staffMember ? (
                <React.Fragment>
                    <ServiceWrapper
                        {...documentStoreState}
                        showLoadingText={true}
                        loaderType={"overlay"}
                        placeholderBlockCount={1}
                        loaderWheelType={"three-ring"}
                    />
                </React.Fragment>
            ) : (
                <div className="row ml-0 mr-0 fadeIn">
                    <div className="col">
                        <h5 className="text-center p-3">
                            Please select a staff member to view their compliance
                        </h5>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};
export default StaffComplianceContainer;
