import {UserData} from "../../../api/staff";
import {StoreServiceData} from "store-fetch-wrappers";
import {createStoreState} from "store-fetch-wrappers/dist";

export const STAFF_LIST_STORE_STATE = createStoreState("staff_list");

export interface StaffListLoading extends StoreServiceData<UserData[]> {
    type: typeof STAFF_LIST_STORE_STATE.LOADING;
}

export interface StaffListError extends StoreServiceData<UserData[]> {
    type: typeof STAFF_LIST_STORE_STATE.ERROR;
}

export interface StaffListSuccess extends StoreServiceData<UserData[]> {
    type: typeof STAFF_LIST_STORE_STATE.SUCCESS;
}

export type StaffListDispatchTypes = StaffListError | StaffListLoading | StaffListSuccess;
