import {StaffAccessLevel, UserData} from "../../../../../../../api/staff";
import {IconType} from "../../../../../../Icon/Icon";

export interface TopBar {
    items: TopBarItem[];
}

export interface TopBarItem extends LinkItemBase {
    groupName: string;
    adminUrl?: string;
    staffUrl?: string;
    subsystems?: GroupSubSystem[];
}

export interface GroupSubSystem extends LinkItemBase {
    name: string;
    adminUrl: string;
    staffUrl: string;
    icon: string;
}

export interface LinkItemBase {
    accessLevels: Array<StaffAccessLevel>;
    isActive: boolean;
    developedFor: boolean;
    moduleName?: string;
}

/* Checks if user can see a subsystem link */
export function canUserSeeLink(user: UserData, linkInfo: LinkItemBase) {
    if (!linkInfo.developedFor) return false;

    const linkAccessLevel = user.accessDetail![linkInfo.moduleName || ""] || user.accessLevel;

    const linkAccessLevelIndex = linkInfo.accessLevels.indexOf(linkAccessLevel);

    return linkAccessLevelIndex >= 0;
}

/** Gets the container style */
export function getContainerStyle(listItem: HTMLLIElement): ContainerStyle {
    return {
        width: getNavWidth(),
        right: getRightOffset(listItem)
    };
}

/** Gets nav width with offset */
function getNavWidth() {
    const navHeader = document.getElementById("nav-header");
    if (!navHeader) return "1350px";

    return `${navHeader.getBoundingClientRect().width - 20}px`;
}

/** Gets the right offset for the navigation dropdown container when hovered. Ensures the container is always centered */
function getRightOffset(listItem: HTMLLIElement): string {
    const navWidth = getTrueNavWidth(listItem);
    const listItemMargin = 7; //part of CSS rule
    const {right} = listItem.getBoundingClientRect();

    const trueLIPos = right + listItemMargin;

    return `${-(navWidth - trueLIPos)}px`;
}

/** Gets the width of the nav container. x is not at 0 due to margin-left: auto rule on css class */
function getTrueNavWidth(listItem: HTMLLIElement): number {
    const navHeader = document.getElementById("nav-header");
    if (!navHeader) return listItem.getBoundingClientRect().right;

    const {x, width} = navHeader.getBoundingClientRect();

    return x + width;
}

export interface ContainerStyle {
    right: string;
    width: string;
}

export function getIconTypeFromString(value: string): IconType {
    return IconType[value as keyof typeof IconType];
}
