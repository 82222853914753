import React from "react";
import {NavigationItem} from "../../../../../../store/navigation/actions/NavigationActionTypes";
import {NavLink} from "react-router-dom";

function SubsystemChildRouteContainer({navItem, onActiveRoute}: Props): JSX.Element {
    return (
        <React.Fragment>
            {navItem.children &&
                navItem.children.map(({path, name}, index) => {
                    return (
                        <div key={index} className="bounceIn">
                            <NavLink
                                exact
                                strict
                                to={path || ""}
                                className="nav-links d-inline-block sub-system-child-route-item"
                                activeClassName="sub-system-child-route-item-active-link"
                                isActive={(match) => {
                                    if (match && match.url === decodeURI(path || "")) {
                                        onActiveRoute(true);
                                        return true;
                                    }
                                    onActiveRoute(false);
                                    return false;
                                }}
                            >
                                <p className="mb-0 body-font">{name}</p>
                            </NavLink>
                        </div>
                    );
                })}
        </React.Fragment>
    );
}

export default SubsystemChildRouteContainer;

interface Props {
    onActiveRoute: (value: boolean) => void;
    navItem: NavigationItem;
}
