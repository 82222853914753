import React from "react";
import {TopBarItem} from "../Header/Helpers/topbarHelpers";
import {NavigationItem} from "../../../../../../store/navigation/actions/NavigationActionTypes";
import SidebarExternalItem from "./SidebarExternalItem";
import SidebarInternalItem from "./SidebarInternalItem";
import {SidebarItemContainerProps} from "./Helpers/sidebarTabsHelpers";
import LogoutButton from "../Buttons/LogoutButton";

const SidebarItemContainer = (props: SidebarItemContainerProps) => {
    return (
        <React.Fragment>
            <ul className="sidebar-nav nav-vertical nav-slide">
                {props.externalNavigationItems.map((item: TopBarItem, index: number) => {
                    return <SidebarExternalItem {...item} key={index} />;
                })}
                {props.internalNavigationItems.map((item: NavigationItem, index: number) => {
                    return <SidebarInternalItem {...item} key={index} />;
                })}
                <LogoutButton />
            </ul>
        </React.Fragment>
    );
};

export default SidebarItemContainer;
