import {useHistory} from "react-router-dom";

/** Hook that returns the path name, the query strings and a joined version of both */
export const usePageUrl = (): PageUrlProps => {
    const history = useHistory();

    const pathName = history.location.pathname;
    const searchQueries = history.location.search;
    const fullPath = `${pathName}${searchQueries}`;

    return {
        pathName,
        searchQueries,
        fullPath
    };
};

interface PageUrlProps {
    pathName: string;
    searchQueries: string;
    fullPath: string;
}
