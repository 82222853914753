import {DocsApi} from "../../api/ppb";

export default class PPBApiModel {
    private static instance: PPBApiModel;
    private static docsApi: DocsApi;

    public static getInstance(): PPBApiModel {
        if (!PPBApiModel.instance) {
            PPBApiModel.instance = new PPBApiModel();
        }
        return PPBApiModel.instance;
    }

    public static getDocsApi(): DocsApi {
        if (!PPBApiModel.docsApi) {
            PPBApiModel.docsApi = new DocsApi({
                basePath: process.env.REACT_APP_PPB_API
            });
        }

        return PPBApiModel.docsApi;
    }
}
