import React from "react";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import {LayoutProps} from "./MedicareLayout";
import {LayoutHeaderContext} from "./Context/LayoutHeaderContext";

const NoNavLayout = ({children}: LayoutProps) => {
    return (
        <React.Fragment>
            <div className="layout-wrapper" id="main-panel">
                <LayoutHeaderContext.Provider value={false}>
                    <Header />
                </LayoutHeaderContext.Provider>
                <div className="fadeIn">
                    <div className="row ml-0 mr-0">
                        <div className="col">{children}</div>
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
};

export default NoNavLayout;
