import React from "react";
import {nanoid} from "nanoid";
import ReactTooltip from "react-tooltip";

const Tooltip = (props: TooltipProps) => {
    const tooltipId = nanoid();

    const getTooltip = () => {
        switch (props.rootElement) {
            case "span":
                return (
                    <span
                        id={tooltipId}
                        data-tip
                        data-for={tooltipId}
                        className={props.wrapperClassName}
                    >
                        {markup()}
                    </span>
                );
            case "div":
                return (
                    <div
                        id={tooltipId}
                        data-tip
                        data-for={tooltipId}
                        className={props.wrapperClassName}
                    >
                        {markup()}
                    </div>
                );
            default:
                return (
                    <span
                        id={tooltipId}
                        data-tip
                        data-for={tooltipId}
                        className={props.wrapperClassName}
                    >
                        {markup()}
                    </span>
                );
        }
    };

    const markup = () => {
        return (
            <React.Fragment>
                {props.children}
                <ReactTooltip
                    place={props.place}
                    id={tooltipId}
                    type="dark"
                    effect="solid"
                    className={`fadeIn tooltip-override-${props.size} ${props.theme}`}
                >
                    <span>{props.tooltipText}</span>
                </ReactTooltip>
            </React.Fragment>
        );
    };

    return getTooltip();
};

export default Tooltip;

interface TooltipProps {
    children: JSX.Element | JSX.Element[];
    tooltipText: string;
    onClick?: () => void;
    size: TooltipSize;
    place?: Place;
    theme?: Theme;
    wrapperClassName?: string;
    rootElement?: Root;
}

type TooltipSize = "xs" | "sm" | "md" | "lg";
type Place = "top" | "right" | "bottom" | "left";
type Theme = "light" | "dark";
type Root = "span" | "div";
