import {UserData} from "../api/staff";
import {McConfig} from "../McConfigPlugin";

export function getUserFlags(flags: string[] | undefined) {
    //Undefined flags
    if (!flags) return "";

    //If flags exist but the array is empty.
    if (flags.length < 1) {
        return "";
    }

    //If one flag exists and it is more than 0 characters.
    if (flags.length === 1 && flags[0].length > 0) {
        return `(${flags[0]})`;
    }

    //More than 1 flag.
    return `(${flags.join(", ")})`;
}

/** Added in to account for custom ACLs users might have per system.
 * Gets users access level based on module. If there is no data, take
 * default access level and use that*/
export function getUserAccessLevel(user: UserData, config: McConfig) {
    const {moduleName} = config.systemDetails;

    return user.accessDetail![moduleName] || user.accessLevel;
}
