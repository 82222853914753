import React, {useEffect, useState} from "react";
import {nanoid} from "nanoid";

const Toggle = ({checked, onChange, size, disabled, name, value}: ToggleProps) => {
    const buttonId = nanoid();

    const [toggleClassName, setToggleClassName] = useState("");

    useEffect(() => {
        switch (size) {
            case ToggleSize.Large:
                setToggleClassName("dmcheck");
                break;
            case ToggleSize.Small:
                setToggleClassName("dmcheck-sm");
                break;
        }
    }, [size]);
    return (
        <span className="toggle-control">
            <input
                className={toggleClassName}
                type="checkbox"
                checked={checked}
                onChange={onChange}
                id={buttonId}
                disabled={disabled}
                name={name}
                value={value}
            />
            <label htmlFor={buttonId} />
        </span>
    );
};

export default Toggle;

export interface ToggleProps {
    checked: any;
    onChange: any;
    size: ToggleSize;
    disabled?: boolean;
    name?: string;
    value?: string;
}

// eslint-disable-next-line no-shadow
export enum ToggleSize {
    Small = "Small",
    Large = "Large"
}
