import React, {useEffect} from "react";
import {Category} from "../../../api/ppb";
import {PulseTable} from "pulse_table";
import {toArray} from "../../../utils/sortingUtils";
import {useHistory} from "react-router-dom";
import Tooltip from "../../Tooltip/Tooltip";
import Icon, {IconType} from "../../Icon/Icon";
import {routeNames} from "../../Navigation/routeNames";
import IconWithModal from "../../Icon/IconWithModal";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import {deleteCategoryById} from "../../../store/category/actions/CategoryActions";
import {
    getCategoriesList,
    nullifyCategoryListStore
} from "../../../store/categories/actions/CategoriesActions";
import {getUiFriendlyText} from "../../../utils/textUtils";
import {ClinicalGrade} from "../../../api/staff";
import TableActionContainer from "../../Table/TableActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../Button/MCButton";
import {showSuccessToast} from "../../../utils/toastUtils";

const CategoryListTable = (props: Category[]) => {
    const history = useHistory();
    const {loading} = useSelector((state: RootStore) => state.category);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(nullifyCategoryListStore());
        };
    }, []);

    const deleteCategory = async (id: number) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(deleteCategoryById(id));

        if (success) {
            dispatch(getCategoriesList());
            showSuccessToast("Deleted Category");
        }
        return success;
    };
    return (
        <React.Fragment>
            <div className="mt-3 mb-3">
                <TableActionContainer>
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Add Category"}
                        onClick={() => {
                            const path = routeNames.categoryAdd.path;
                            history.push(path);
                        }}
                        roundedCorner
                        colour={ButtonColourOptions.Yellow}
                    />
                </TableActionContainer>
                <PulseTable
                    items={toTableRow(toArray(props))}
                    headers={{
                        categoryName: "Category Name",
                        clinicalGrades: "Clinical Grades",
                        actions: "Actions"
                    }}
                    customRenderers={{
                        clinicalGrades: (item: TableRow) => (
                            <React.Fragment>{item.clinicalGrades}</React.Fragment>
                        ),
                        actions: (item: TableRow) => (
                            <div className="row">
                                <div className="icon-tooltip-wrapper-md mr-3">
                                    <Tooltip
                                        tooltipText={`Edit ${item.categoryName}`}
                                        size={"md"}
                                        place={"left"}
                                    >
                                        <Icon
                                            rootElement={"Div"}
                                            icon={IconType.Edit}
                                            size={"Medium"}
                                            className="icon-dark"
                                            onClick={() => {
                                                const path = `${routeNames.categoryEdit.path}/${item.actions}`;
                                                history.push(path);
                                            }}
                                        />
                                    </Tooltip>
                                </div>
                                <IconWithModal
                                    modalTitle={"Delete Category"}
                                    modalBody={`Are you sure you want to delete ${item.categoryName}?`}
                                    tooltipText={`Delete ${item.categoryName}`}
                                    iconType={IconType.Cross}
                                    onConfirm={() => deleteCategory(item.actions)}
                                    loading={loading}
                                />
                            </div>
                        )
                    }}
                    noItemsSection={
                        <div className="row ml-0 mr-0 fadeIn">
                            <div className="col">
                                <h5 className="text-center p-3">There are no categories!</h5>
                            </div>
                        </div>
                    }
                />
            </div>
        </React.Fragment>
    );
};

export default CategoryListTable;

interface TableRow {
    categoryName: string;
    clinicalGrades: JSX.Element | JSX.Element[];
    actions: number;
}

function toTableRow(categoryList: Category[]): TableRow[] {
    return categoryList.map((category) => {
        return {
            categoryName: category.name,
            clinicalGrades: getClinicalGradeColumn(category.clinicalGrades),
            actions: category.id
        };
    });
}

function getClinicalGradeColumn(grades: string[]): JSX.Element | JSX.Element[] {
    if (grades.length === 0) return <p className="mb-0">N/A</p>;
    return grades.map((grade, index) => {
        return (
            <p key={index} className="mb-0">
                {getUiFriendlyGrade(grade)}
            </p>
        );
    });
}

export function getUiFriendlyGrade(grade: string): string {
    switch (grade) {
        case ClinicalGrade.EAC:
            return "Emergency Ambulance Crew";
        default:
            return getUiFriendlyText(grade);
    }
}
