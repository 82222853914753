import React from "react";
import {GroupSubSystem, TopBarItem} from "../Header/Helpers/topbarHelpers";
import useCanUserSeeLink from "../Header/Hooks/useCanUserSeeLink";
import ExternalLinkSubsystemItem from "./ExternalLinkSubsystemItem";
import {useCorrectSystemLink} from "../Hooks/useCorrectSystemLink";

const SidebarExternalItem = (props: TopBarItem) => {
    const link = useCorrectSystemLink(props);
    const onItemClicked = (active: boolean, incomingLink?: string) => {
        if (!incomingLink) return;
        if (active) return;
        window.open(incomingLink, "_self");
    };

    const showLink = useCanUserSeeLink(props);
    return (
        <React.Fragment>
            {showLink && (
                <React.Fragment>
                    <li
                        onClick={() => onItemClicked(props.isActive, link)}
                        className={`${
                            props.adminUrl ? "sidebar-list-item" : "sidebar-list-item-no-link"
                        } ${props.adminUrl && props.isActive ? "active-route" : ""}`}
                    >
                        <a
                            className="group-item"
                            onClick={() => onItemClicked(props.isActive, link)}
                        >
                            {props.groupName}
                        </a>
                    </li>
                    {props.subsystems &&
                        props.subsystems.map((subsystem: GroupSubSystem, index: number) => {
                            return <ExternalLinkSubsystemItem {...subsystem} key={index} />;
                        })}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default SidebarExternalItem;
