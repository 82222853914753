import {StoreServiceData} from "store-fetch-wrappers";
import {DocumentViewRecord} from "../../../api/ppb";
import {createStoreState} from "store-fetch-wrappers/dist";

export const USER_READ_RECEIPT_STORE_STATE = createStoreState("user_read_receipt");

export interface UserReadReceiptLoading extends StoreServiceData<DocumentViewRecord> {
    type: typeof USER_READ_RECEIPT_STORE_STATE.LOADING;
}

export interface UserReadReceiptError extends StoreServiceData<DocumentViewRecord> {
    type: typeof USER_READ_RECEIPT_STORE_STATE.ERROR;
}

export interface UserReadReceiptSuccess extends StoreServiceData<DocumentViewRecord> {
    type: typeof USER_READ_RECEIPT_STORE_STATE.SUCCESS;
}

export type UserReadReceiptDispatchTypes =
    | UserReadReceiptSuccess
    | UserReadReceiptError
    | UserReadReceiptLoading;
