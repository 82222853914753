import {UserData} from "../../../api/staff";
import {StoreServiceData, StoreState} from "store-fetch-wrappers";

export const AUTH_STORE_STATE: AuthStoreState = {
    SUCCESS: "AUTH_STORE_SUCCESS",
    LOADING: "AUTH_STORE_LOADING",
    ERROR: "AUTH_STORE_ERROR",
    AUTHENTICATED: "AUTH_STORE_AUTHENTICATED"
};

export interface AuthLoading extends AuthStoreServiceData<UserData> {
    type: typeof AUTH_STORE_STATE.LOADING;
}

export interface AuthError extends AuthStoreServiceData<UserData> {
    type: typeof AUTH_STORE_STATE.ERROR;
}

export interface AuthSuccess extends AuthStoreServiceData<UserData> {
    type: typeof AUTH_STORE_STATE.SUCCESS;
}

export interface AuthAuthenticated extends AuthStoreServiceData<UserData> {
    type: typeof AUTH_STORE_STATE.AUTHENTICATED;
}

export type AuthDispatchTypes = AuthSuccess | AuthLoading | AuthError | AuthAuthenticated;

export interface AuthStoreServiceData<T> extends StoreServiceData<T> {
    authenticated: boolean;
}

export interface AuthStoreState extends StoreState {
    AUTHENTICATED: string;
}

export interface AuthServiceError<T> extends AuthStoreServiceData<T> {
    type: string;
}

export interface AuthServiceLoading<T> extends AuthStoreServiceData<T> {
    type: string;
}

export interface AuthServiceSuccess<T> extends AuthStoreServiceData<T> {
    type: string;
}

export interface AuthServiceAuthorised<T> extends AuthStoreServiceData<T> {
    type: string;
}

export type AuthServiceActionTypes<T> =
    | AuthServiceAuthorised<T>
    | AuthServiceSuccess<T>
    | AuthServiceLoading<T>
    | AuthServiceError<T>;
