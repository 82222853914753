import React from "react";
import Tooltip from "../Tooltip/Tooltip";
import Icon, {IconType} from "./Icon";
import {Modal, useModal} from "pulse-modal";
import MCButton, {ButtonColourOptions, ButtonSize} from "../Button/MCButton";
import {Loading} from "store-fetch-wrappers";

const IconWithModal = ({
    tooltipText,
    iconType,
    modalTitle,
    modalBody,
    onConfirm,
    loading
}: IconWithModalProps) => {
    const {toggle, isShown} = useModal();
    return (
        <React.Fragment>
            <div className="icon-tooltip-wrapper-md mr-3">
                <Tooltip tooltipText={tooltipText} size={"md"} place={"left"}>
                    <Icon
                        rootElement={"Div"}
                        icon={iconType}
                        size={"Medium"}
                        className="icon-dark"
                        onClick={toggle}
                    />
                </Tooltip>
            </div>
            <Modal
                modalSize={"sm"}
                title={modalTitle}
                bodyChildren={
                    <React.Fragment>
                        <div className="row ml-0 mr-0">
                            <p className="mb-0">{modalBody}</p>
                        </div>
                        <div className="row ml-0 mr-0">
                            <div className="col text-center">
                                {loading && <Loading type={"three-ring"} showLoadingText={false} />}
                            </div>
                        </div>
                    </React.Fragment>
                }
                footerChildren={
                    <div className="row mr-0 ml-0">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Yes"}
                            onClick={async () => {
                                const success = await onConfirm();
                                if (!success) return;
                                toggle();
                            }}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"No"}
                            onClick={toggle}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    </div>
                }
                onClose={toggle}
                isShown={isShown}
            />
        </React.Fragment>
    );
};

export default IconWithModal;

interface IconWithModalProps {
    tooltipText: string;
    iconType: IconType;
    modalTitle: string;
    modalBody: string;
    onConfirm: () => Promise<boolean>;
    loading: boolean;
}
