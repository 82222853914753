import React from "react";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../../../Navigation/routeNames";

const LogoutButton = () => {
    const history = useHistory();

    const logoutApp = () => {
        history.push(routeNames.logout.path);
    };
    return (
        <React.Fragment>
            <span className="desktop no-list-style">
                <li onClick={logoutApp} className="nav-links mr-2">
                    <a className="nav-links" onClick={logoutApp}>
                        Logout
                    </a>
                </li>
            </span>
            <span className="mobile">
                <li onClick={logoutApp} className="sidebar-list-item mt-auto">
                    <a className="group-item" onClick={logoutApp}>
                        Logout
                    </a>
                </li>
            </span>
        </React.Fragment>
    );
};

export default LogoutButton;
