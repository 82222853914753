import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {WithServiceState} from "store-fetch-wrappers";
import {useParams} from "react-router-dom";
import EditCategoryForm from "./Components/EditCategoryForm";
import {RootStore} from "../../../store/Store";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../Navigation/routeNames";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {getCategory, nullifyCategoryStore} from "../../../store/category/actions/CategoryActions";

const EditFormWrapper = WithServiceState(EditCategoryForm);

const EditCategory = () => {
    const dispatch = useDispatch();
    const categoryStore = useSelector((state: RootStore) => state.category);

    const params: any = useParams();

    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.overview.name,
                path: routeNames.overview.path
            },
            {
                name: routeNames.categoryList.name,
                path: routeNames.categoryList.path
            },
            {
                name: routeNames.documentList.name,
                path: routeNames.documentList.path
            },
            {
                name: routeNames.myDocuments.name,
                path: routeNames.myDocuments.path
            },
            {
                name: routeNames.staffCompliance.name,
                path: routeNames.staffCompliance.path
            }
        ];

        //Set Nav Items
        dispatch(setNavigationItems(navItems));

        dispatch(getCategory(+params.id));
        return () => {
            dispatch(nullifyCategoryStore());
        };
    }, []);

    return (
        <div className="page-container">
            <EditFormWrapper
                showLoadingText={true}
                loaderWheelType={"three-ring"}
                loaderType={"overlay"}
                placeholderBlockCount={1}
                {...categoryStore}
            />
        </div>
    );
};

export default EditCategory;
