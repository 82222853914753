export const routeNames = {
    categoryList: {
        path: "/categories",
        name: "Categories"
    },
    categoryAdd: {
        path: "/category/add",
        name: "Add Category"
    },
    categoryEdit: {
        path: "/category/edit",
        name: "Edit Category"
    },
    documentAdd: {
        name: "Add Document",
        path: "/document/add"
    },
    documentEdit: {
        name: "Update Document",
        path: "/document/update"
    },
    documentList: {
        name: "View Documents",
        path: "/document/list"
    },
    documentByCategory: {
        name: "View Documents",
        path: "/document/list/category"
    },
    myDocuments: {
        name: "My Documents",
        path: "/documents/user-documents"
    },
    myDocumentsByCategory: {
        name: "My Documents",
        path: "/documents/user-documents/category"
    },
    staffCompliance: {
        path: "/staff-compliance",
        name: "Staff Compliance"
    },
    overview: {
        path: "/overview",
        name: "Overview"
    },
    //Auth pages
    login: {
        path: "/login",
        name: "",
        icon: ""
    },
    loggedin: {
        path: "/loggedin",
        name: "",
        icon: ""
    },
    logout: {
        path: "/logout",
        name: "",
        icon: ""
    },
    inActive: {
        path: "/inactive",
        name: "",
        icon: ""
    },
    notAuthorised: {
        path: "/not-authorised",
        name: "",
        icon: ""
    },
    notFound: {
        path: "/not-found",
        name: "",
        icon: ""
    }
};
