import {StoreServiceData, ServiceActionTypes} from "store-fetch-wrappers";
import {USER_STORE_STATE} from "../actions/UserActionTypes";
import {UserData} from "../../../api/staff";
import {createDefaultStoreState, createReducer} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<UserData>(null);

const userReducer = (
    state: StoreServiceData<UserData> = defaultState,
    action: ServiceActionTypes<UserData>
): StoreServiceData<UserData> =>
    createReducer(state, action, USER_STORE_STATE, () => showErrorToast(action.error));

export default userReducer;
