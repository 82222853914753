import {Dispatch} from "redux";
import {MC_CONFIG_STORE, McConfigDispatchTypes} from "./McConfigActionTypes";
import {getConfig} from "../../../McConfigPlugin";

/** Clears the state of the store. */
export const nullifyMcConfigStore = () => {
    return async (dispatch: Dispatch<McConfigDispatchTypes>) => {
        dispatch({
            type: MC_CONFIG_STORE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

/** Clears the state of the store. */
export const getMcConfig = () => {
    return async (dispatch: Dispatch<McConfigDispatchTypes>) => {
        dispatch({
            type: MC_CONFIG_STORE.LOADING,
            error: null,
            loading: true
        });

        dispatch({
            type: MC_CONFIG_STORE.SUCCESS,
            error: null,
            loading: false,
            data: await getConfig()
        });
    };
};
