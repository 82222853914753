import React, {useEffect, useState} from "react";
import Select, {MultiValue} from "react-select";
import {ClinicalGradeMultiProps, DDProps} from "./Helpers/dropdownUtils";
import {getUiFriendlyGrade} from "../Pages/CategoryList/CategoryListTable";

const ClinicalGradesDropdown = (props: ClinicalGradeMultiProps) => {
    const clinicalGradeOptions = getClinicalGradeOptions();
    const [selectedOptions, setSelectedOptions] = useState<MultiValue<DDProps>>([]);

    useEffect(() => {
        setSelectedOptions(getSelectedOptions(props.ids));
    }, [props.ids]);

    const handleClinicalGradeOptionsSelected = (newValue: MultiValue<DDProps>) => {
        setSelectedOptions(newValue);
        if (!newValue) {
            props.changeOptions([]);
            return;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (newValue.length === 0) {
            props.changeOptions([]);
            return;
        }

        props.changeOptions(getNewlySelectedItems(newValue));
    };

    const getSelectedOptions = (grades: string[]) => {
        return clinicalGradeOptions.filter((item) => grades.includes(item.value.toString()));
    };

    const getNewlySelectedItems = (newValues: MultiValue<DDProps>): string[] => {
        return newValues.map((item) => item.value.toString());
    };

    return (
        <Select
            className="search-bar"
            classNamePrefix="select-options"
            options={clinicalGradeOptions}
            onChange={handleClinicalGradeOptionsSelected}
            isSearchable={false}
            value={selectedOptions}
            isMulti={true}
            noOptionsMessage={() => "No Options Found"}
        />
    );
};

export default ClinicalGradesDropdown;

function getClinicalGradeOptions(): DDProps[] {
    return Object.keys(UiClinicalGrade).map((grade) => {
        return {
            label: getUiFriendlyGrade(grade),
            value: grade
        };
    });
}

// eslint-disable-next-line no-shadow
export enum UiClinicalGrade {
    Paramedic = "Paramedic",
    Technician = "Technician",
    FirstResponder = "FirstResponder",
    EAC = "EAC",
    Nurse = "Nurse"
}
