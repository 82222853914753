import React, {useEffect, useState} from "react";
import {DocumentViewRecordWithDocument} from "../../../../api/ppb";
import {toArray} from "../../../../utils/sortingUtils";
import MyDocumentListItem from "../../MyDocumentByCategory/MyDocumentListItem";
import FormRow from "../../../Form/FormRow";
import FilterDocumentDropDown, {
    FilterType,
    getFilterTypeFromString
} from "../../../Dropdown/FilterDocumentDropdown";
import {filterDocuments} from "../../MyDocumentByCategory/MyDocumentsList";
import {nullifyUserDocStore} from "../../../../store/userDocuments/actions/UserDocumentsActions";
import {useDispatch} from "react-redux";

const StaffComplianceList = (props: DocumentViewRecordWithDocument[]) => {
    const [filter, setFilter] = useState<FilterType>(FilterType.All);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(nullifyUserDocStore());
        };
    }, []);

    return (
        <React.Fragment>
            <FormRow rowName={"Filter Documents"} columnDetailClassName={"pr-0 pl-0"}>
                <FilterDocumentDropDown
                    id={filter}
                    changeOption={(id) => setFilter(getFilterTypeFromString(id.toString()))}
                    disabled={false}
                />
            </FormRow>
            {filterDocuments(filter, toArray(props)).length > 0 ? (
                <React.Fragment>
                    <div className="row ml-0 mr-0 mt-3">
                        {filterDocuments(filter, toArray(props)).map((item, index) => {
                            return (
                                <MyDocumentListItem
                                    documentViewRecord={item}
                                    disabled={true}
                                    key={index}
                                />
                            );
                        })}
                    </div>
                </React.Fragment>
            ) : (
                <div className="row ml-0 mr-0 fadeIn">
                    <div className="col">
                        <h5 className="text-center p-3">
                            {filter === FilterType.All ? (
                                <span>There are no documents available for this staff member!</span>
                            ) : (
                                <span>There are no documents matching this filter!</span>
                            )}
                        </h5>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default StaffComplianceList;
