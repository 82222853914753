import {Dispatch} from "redux";
import {CATEGORY_STORE_STATE, CategoryDispatchTypes} from "./CategoryActionTypes";
import {
    postDataToServiceWithRedux,
    getDataFromServiceWithRedux,
    deleteDataFromServiceWithRedux
} from "store-fetch-wrappers";
import PPBApiModel from "../../apiModel/PPBApiModel";
import {Category} from "../../../api/ppb";
import {statusCodeCallback} from "../../helpers/storeHelpers";

export const nullifyCategoryStore = () => {
    return async (dispatch: Dispatch<CategoryDispatchTypes>) => {
        dispatch({
            type: CATEGORY_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: null
        });
    };
};

export const saveCategory = (category: Category) => {
    return async (dispatch: Dispatch<CategoryDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                CATEGORY_STORE_STATE,
                dispatch,
                () => PPBApiModel.getDocsApi().saveCategory(category),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: CATEGORY_STORE_STATE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};

export const createNewCategory = () => {
    return async (dispatch: Dispatch<CategoryDispatchTypes>) => {
        dispatch({
            type: CATEGORY_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: {
                name: "New Category",
                id: 0,
                clinicalGrades: []
            }
        });
    };
};

export const getCategory = (id: number) => {
    return async (dispatch: Dispatch<CategoryDispatchTypes>) => {
        try {
            await getDataFromServiceWithRedux(
                CATEGORY_STORE_STATE,
                dispatch,
                () => PPBApiModel.getDocsApi().getCategory(id),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: CATEGORY_STORE_STATE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};

export const deleteCategoryById = (id: number) => {
    return async (dispatch: Dispatch<CategoryDispatchTypes>) => {
        try {
            return await deleteDataFromServiceWithRedux(
                CATEGORY_STORE_STATE,
                dispatch,
                () => PPBApiModel.getDocsApi().deleteCategory(id),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: CATEGORY_STORE_STATE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};

export const setCategory = (category: Category) => {
    return async (dispatch: Dispatch<CategoryDispatchTypes>) => {
        dispatch({
            type: CATEGORY_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: category
        });
    };
};
