import {useUserData} from "../../../../../../Hooks/useUserData";
import {useEffect, useState} from "react";
import {canUserSeeLink, LinkItemBase} from "../Helpers/topbarHelpers";

function useCanUserSeeLink(linkInfo: LinkItemBase) {
    const user = useUserData();
    const [showLink, setShowLink] = useState<boolean>(false);

    useEffect(() => {
        if (user.username.length === 0) return;
        setShowLink(canUserSeeLink(user, linkInfo));
    }, [linkInfo, user]);

    return showLink;
}

export default useCanUserSeeLink;
