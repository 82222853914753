import {generateBlankConfig} from "../../McConfigPlugin";
import {useSelector} from "react-redux";
import {RootStore} from "../../store/Store";

function useMcConfig() {
    const {loading, data} = useSelector((state: RootStore) => state.mcConfig);

    const config = data ? data : generateBlankConfig();

    return {config, loading};
}
export default useMcConfig;
