import {Dispatch} from "redux";
import {DOCUMENT_STORE_STATE, DocumentDispatchTypes} from "./DocumentActionTypes";
import {
    postDataToServiceWithRedux,
    deleteDataFromServiceWithRedux,
    getDataFromServiceWithRedux
} from "store-fetch-wrappers";
import {Document, DocumentUploadRequest} from "../../../api/ppb";
import PPBApiModel from "../../apiModel/PPBApiModel";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import Store from "../../Store";
import {getCategoriesList} from "../../categories/actions/CategoriesActions";

export const nullifyDocumentStore = () => {
    return async (dispatch: Dispatch<DocumentDispatchTypes>) => {
        dispatch({
            type: DOCUMENT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: null
        });
    };
};

export const saveDocumentToService = (uploadRequest: DocumentUploadRequest) => {
    return async (dispatch: Dispatch<DocumentDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                DOCUMENT_STORE_STATE,
                dispatch,
                () => PPBApiModel.getDocsApi().saveDocument(uploadRequest),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: DOCUMENT_STORE_STATE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};

export const deleteDocumentById = (id: number) => {
    return async (dispatch: Dispatch<DocumentDispatchTypes>) => {
        try {
            return await deleteDataFromServiceWithRedux(
                DOCUMENT_STORE_STATE,
                dispatch,
                () => PPBApiModel.getDocsApi().deleteDocument(id),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: DOCUMENT_STORE_STATE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};

export const getDocumentById = (id: number) => {
    return async (dispatch: Dispatch<DocumentDispatchTypes>) => {
        try {
            dispatch({
                type: DOCUMENT_STORE_STATE.LOADING,
                loading: true,
                error: null
            });
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await Store.dispatch(getCategoriesList());

            return await getDataFromServiceWithRedux(
                DOCUMENT_STORE_STATE,
                dispatch,
                () => PPBApiModel.getDocsApi().getDocument(id),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: DOCUMENT_STORE_STATE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};

export const createNewDocument = () => {
    return async (dispatch: Dispatch<DocumentDispatchTypes>) => {
        dispatch({
            type: DOCUMENT_STORE_STATE.LOADING,
            loading: true,
            error: null
        });

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await Store.dispatch(getCategoriesList());

        dispatch({
            type: DOCUMENT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: generateBlankDocument()
        });
    };
};

export const setDocument = (document: Document) => {
    return async (dispatch: Dispatch<DocumentDispatchTypes>) => {
        dispatch({
            type: DOCUMENT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: document
        });
    };
};

function generateBlankDocument(): Document {
    return {id: 0, categoryId: 0, name: "New Document", filename: "", mimeType: "", date: 0};
}
