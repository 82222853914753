import React from "react";
import Icon, {IconType} from "../../../../../../Icon/Icon";
import {useDispatch} from "react-redux";
import {setSidebarToggled} from "../../../../../../../store/sidebar/actions/SidebarActions";
import {useFadeInFadeOut} from "../Hooks/useFadeInFadeOut";

const SidebarBurger = () => {
    const dispatch = useDispatch();
    const rootElementClassName = useFadeInFadeOut({fadeInFirst: false});
    const onBurgerClicked = () => {
        dispatch(setSidebarToggled(true));
    };
    return (
        <React.Fragment>
            <Icon
                rootElement={"Div"}
                rootElementClassName={`burger-menu ${rootElementClassName}`}
                icon={IconType.Menu}
                size={"Medium"}
                className="nav-links"
                onClick={onBurgerClicked}
            />
        </React.Fragment>
    );
};

export default SidebarBurger;
