import React from "react";
import "./style/global.scss";
import App from "./App";
import {getConfig} from "./McConfigPlugin";
import reportWebVitals from "./reportWebVitals";
import {initAuth} from "./store/auth/actions/AuthActions";
import {createRoot} from "react-dom/client";

/** Init the React app */
async function initReact() {
    const config = await getConfig();
    await initAuth(config.auth);

    const container = document.getElementById("root");
    const root = createRoot(container || document.body);
    root.render(<App />);
}

initReact().catch((e: any) => console.log(e, "Could not initialise MC Project"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/** Removes routes if the pages are in the local storage */
window.addEventListener("beforeunload", () => {
    const whitelistRoutes: string[] = [];

    const currentRoute = localStorage.getItem("current_route");

    const index = whitelistRoutes.findIndex((route: string) => route === currentRoute);

    if (index < 0) {
        localStorage.removeItem("current_route");
        localStorage.removeItem("previous_route");
    }
});
