import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export interface NavigationItem extends NavigationItemBase {
    children?: NavigationItemBase[];
}

export interface NavigationItemBase {
    name: string;
    path?: string;
}

export const NAVIGATION_STORE_STATE = createStoreState("navigation_store");

export interface NavigationLoading extends StoreServiceData<NavigationItem[]> {
    type: typeof NAVIGATION_STORE_STATE.LOADING;
}

export interface NavigationError extends StoreServiceData<NavigationItem[]> {
    type: typeof NAVIGATION_STORE_STATE.ERROR;
}

export interface NavigationSuccess extends StoreServiceData<NavigationItem[]> {
    type: typeof NAVIGATION_STORE_STATE.SUCCESS;
}

export type NavigationDispatchTypes = NavigationSuccess | NavigationError | NavigationLoading;
