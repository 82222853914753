import {
    AUTH_STORE_STATE,
    AuthServiceActionTypes,
    AuthStoreServiceData
} from "../actions/AuthActionTypes";
import {UserData} from "../../../api/staff";

const defaultState: AuthStoreServiceData<UserData> = {
    loading: false,
    authenticated: false,
    error: null,
    data: null
};

const authReducer = (
    state: AuthStoreServiceData<UserData> = defaultState,
    action: AuthServiceActionTypes<UserData>
) => {
    switch (action.type) {
        case AUTH_STORE_STATE.AUTHENTICATED:
            return {
                ...state,
                loading: false,
                error: null,
                authenticated: action.authenticated
            };
        case AUTH_STORE_STATE.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
                authenticated: true
            };
        case AUTH_STORE_STATE.LOADING:
            return {
                ...state,
                loading: true,
                error: null,
                authenticated: true
            };
        case AUTH_STORE_STATE.SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                authenticated: true,
                data: action.data
            };
        default:
            return state;
    }
};

export default authReducer;
