import React from "react";
import SidebarTabs from "../../Tabs/SidebarTabs";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../../../../store/Store";
import useMcConfig from "../../../../../../Hooks/useMcConfig";

const SidebarMenu = () => {
    const navigationItems = useSelector((state: RootStore) => state.navigation.data) || [];
    const {config} = useMcConfig();
    return (
        <React.Fragment>
            <div className="animateSidebarIn sidebar-content">
                <SidebarTabs
                    navigationItems={navigationItems}
                    externalNavigation={config.navigation}
                    systemDetails={config.systemDetails}
                />
            </div>
        </React.Fragment>
    );
};

export default SidebarMenu;
