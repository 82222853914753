import {getDataFromServiceWithData, postDataToServiceWithRedux} from "store-fetch-wrappers";
import {Dispatch} from "redux";
import {
    USER_READ_RECEIPT_STORE_STATE,
    UserReadReceiptDispatchTypes
} from "./UserReadReceiptActionTypes";
import PPBApiModel from "../../apiModel/PPBApiModel";
import {DocumentViewRecord} from "../../../api/ppb";
import {statusCodeCallback} from "../../helpers/storeHelpers";

export const fetchReadReceiptForDoc = (id: number) => {
    return async (dispatch: Dispatch<UserReadReceiptDispatchTypes>) => {
        try {
            await getDataFromServiceWithData(
                USER_READ_RECEIPT_STORE_STATE,
                dispatch,
                () => PPBApiModel.getDocsApi().getViewsForDocument(id),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: USER_READ_RECEIPT_STORE_STATE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};

export const saveReadReceiptForDoc = (receipt: DocumentViewRecord) => {
    return async (dispatch: Dispatch<UserReadReceiptDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                USER_READ_RECEIPT_STORE_STATE,
                dispatch,
                () => PPBApiModel.getDocsApi().logView(receipt),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: USER_READ_RECEIPT_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

export const resetReadReceiptStore = () => {
    return async (dispatch: Dispatch<UserReadReceiptDispatchTypes>) => {
        dispatch({
            type: USER_READ_RECEIPT_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};
