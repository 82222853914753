import {StoreServiceData, ServiceActionTypes} from "store-fetch-wrappers";
import {DocumentViewRecord} from "../../../api/ppb";
import {USER_READ_RECEIPT_STORE_STATE} from "../actions/UserReadReceiptActionTypes";
import {createDefaultStoreState, createReducer} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<DocumentViewRecord>(null);

const userReadReceiptReducer = (
    state: StoreServiceData<DocumentViewRecord> = defaultState,
    action: ServiceActionTypes<DocumentViewRecord>
): StoreServiceData<DocumentViewRecord> =>
    createReducer(state, action, USER_READ_RECEIPT_STORE_STATE, () => showErrorToast(action.error));

export default userReadReceiptReducer;
