import {StoreServiceData, ServiceActionTypes} from "store-fetch-wrappers";
import {USER_COUNT_STORE_STATE} from "../actions/UserCountActionTypes";
import {UserCount} from "../../../api/staff";
import {createDefaultStoreState, createReducer} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<UserCount>(null);

const userCountReducer = (
    state: StoreServiceData<UserCount> = defaultState,
    action: ServiceActionTypes<UserCount>
): StoreServiceData<UserCount> =>
    createReducer(state, action, USER_COUNT_STORE_STATE, () => showErrorToast(action.error));

export default userCountReducer;
