/** Splits a string by capital letter */
export function splitStringByCapitalLetter(value: string): string {
    const regex = /(?=[A-Z])+/g;
    //Split via capital letter regex.
    return value.split(regex).join(" ");
}

/** Capitalises the first letter of a string */
export function capitalizeFirstLetter(value: string): string {
    if (!value) return "";
    return value.charAt(0).toUpperCase() + value.slice(1);
}

/** lower cases the first letter of a string */
export function decapitalizeFirstLetter(value: string): string {
    return value.charAt(0).toLowerCase() + value.slice(1);
}

/** Removes any whitespace from a string */
export function removeWhitespaceFromString(value: string): string {
    return value.replace(/\s+/g, "");
}

/** Gets ui friendly name for an audit */
export function getUiFriendlyText(value: string): string {
    return splitStringByCapitalLetter(capitalizeFirstLetter(value));
}
