import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {generateBlankConfig, McConfig} from "../../../McConfigPlugin";
import {MC_CONFIG_STORE} from "../actions/McConfigActionTypes";

const defaultState = createDefaultStoreState<McConfig>(generateBlankConfig());

const mcConfigReducer = (
    state: StoreServiceData<McConfig> = defaultState,
    action: ServiceActionTypes<McConfig>
) => createReducer(state, action, MC_CONFIG_STORE);

export default mcConfigReducer;
