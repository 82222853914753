import {StoreServiceData, ServiceActionTypes} from "store-fetch-wrappers";
import {CategoryViewStat} from "../../../api/ppb";
import {VIEW_COUNT_STORE_STATE} from "../actions/ViewCountActionTypes";
import {createDefaultStoreState, createReducer} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<CategoryViewStat[]>([]);

const viewCountReducer = (
    state: StoreServiceData<CategoryViewStat[]> = defaultState,
    action: ServiceActionTypes<CategoryViewStat[]>
): StoreServiceData<CategoryViewStat[]> =>
    createReducer(state, action, VIEW_COUNT_STORE_STATE, () => showErrorToast(action.error));

export default viewCountReducer;
