import {STAFF_LIST_STORE_STATE, StaffListDispatchTypes} from "./StaffListActionTypes";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import {Dispatch} from "redux";
import StaffApiModel from "../../apiModel/StaffApiModel";
import {statusCodeCallback} from "../../helpers/storeHelpers";

export const nullifyStaffListStore = () => {
    return async (dispatch: Dispatch<StaffListDispatchTypes>) => {
        dispatch({
            type: STAFF_LIST_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: null
        });
    };
};

export const fetchAllStaff = () => {
    return async (dispatch: Dispatch<StaffListDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                STAFF_LIST_STORE_STATE,
                dispatch,
                () => StaffApiModel.getUsersApi().getAllUsers(),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: STAFF_LIST_STORE_STATE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};
