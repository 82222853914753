import React from "react";
import {Document} from "../../../api/ppb";
import {formatUnixToLLL} from "../../../utils/momentUtils";
import {PulseTable} from "pulse_table";
import {toArray} from "../../../utils/sortingUtils";
import Tooltip from "../../Tooltip/Tooltip";
import Icon, {IconType} from "../../Icon/Icon";
import {useHistory, useParams} from "react-router-dom";
import {routeNames} from "../../Navigation/routeNames";
import IconWithModal from "../../Icon/IconWithModal";
import {useDispatch, useSelector} from "react-redux";
import {deleteDocumentById} from "../../../store/document/actions/DocumentActions";
import {getDocumentsForCategory} from "../../../store/documents/actions/DocumentsActions";
import {RootStore} from "../../../store/Store";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../Button/MCButton";
import TableActionContainer from "../../Table/TableActionContainer";
import {showSuccessToast} from "../../../utils/toastUtils";

const ViewDocumentsByCategoryTable = (props: Document[]) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const params: any = useParams();
    const {loading} = useSelector((state: RootStore) => state.document);

    const deleteDocument = async (id: number): Promise<boolean> => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(deleteDocumentById(id));

        if (success) {
            dispatch(getDocumentsForCategory(+params.id));
            showSuccessToast("Deleted Document");
        }
        return success;
    };

    const backToCategoriesSelection = () => {
        history.push(routeNames.documentList.path);
    };

    return (
        <React.Fragment>
            <div className="mt-3 mb-3">
                <TableActionContainer>
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Add Document"}
                        onClick={() => {
                            const path = routeNames.documentAdd.path;
                            history.push(path);
                        }}
                        roundedCorner
                        colour={ButtonColourOptions.Yellow}
                    />
                </TableActionContainer>
                <PulseTable
                    items={toTableRow(toArray(props))}
                    headers={{
                        documentName: "Document Name",
                        dateCreated: "Date Created / Updated",
                        actions: "Actions"
                    }}
                    noItemsSection={
                        <div className="row ml-0 mr-0 fadeIn">
                            <div className="col">
                                <h5 className="text-center p-3">
                                    There are no documents for this category
                                </h5>
                            </div>
                        </div>
                    }
                    customRenderers={{
                        actions: (item: TableRow) => (
                            <div className="row">
                                <div className="icon-tooltip-wrapper-md mr-3">
                                    <Tooltip
                                        tooltipText={`Update ${item.documentName}`}
                                        size={"md"}
                                        place={"left"}
                                    >
                                        <Icon
                                            rootElement={"Div"}
                                            icon={IconType.Edit}
                                            size={"Medium"}
                                            className="icon-dark"
                                            onClick={() => {
                                                const path = `${routeNames.documentEdit.path}/${item.actions}`;
                                                history.push(path);
                                            }}
                                        />
                                    </Tooltip>
                                </div>
                                <IconWithModal
                                    modalTitle={"Delete Document"}
                                    modalBody={`Are you sure you want to delete ${item.documentName}?`}
                                    tooltipText={`Delete ${item.documentName}`}
                                    iconType={IconType.Cross}
                                    onConfirm={() => deleteDocument(item.actions)}
                                    loading={loading}
                                />
                            </div>
                        )
                    }}
                />
            </div>
            <div className="row ml-0 mr-0 mt-5">
                <div className="col pl-0 pr-0">
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Back"}
                        onClick={backToCategoriesSelection}
                        colour={ButtonColourOptions.DarkBlue}
                        roundedCorner
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default ViewDocumentsByCategoryTable;

interface TableRow {
    documentName: string;
    dateCreated: string;
    actions: number;
}

function toTableRow(list: Document[]): TableRow[] {
    return list.map((doc) => {
        return {
            documentName: doc.name,
            dateCreated: formatUnixToLLL(doc.date),
            actions: doc.id
        };
    });
}
