import {DOWNLOAD_URL_STORE_STATE, DownloadUrlDispatchTypes} from "./DownloadUrlActionTypes";
import {Dispatch} from "redux";
import PPBApiModel from "../../apiModel/PPBApiModel";
import {getDataFromServiceWithData} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import {showErrorToast} from "../../../utils/toastUtils";

export const nullifySignedLink = () => {
    return async (dispatch: Dispatch<DownloadUrlDispatchTypes>) => {
        dispatch({
            type: DOWNLOAD_URL_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: null
        });
    };
};

export const fetchSignedLink = (id: number) => {
    return async (dispatch: Dispatch<DownloadUrlDispatchTypes>) => {
        try {
            const url = await getDataFromServiceWithData(
                DOWNLOAD_URL_STORE_STATE,
                dispatch,
                () => PPBApiModel.getDocsApi().getDocumentSignedLink(id),
                statusCodeCallback
            );

            if (!url) {
                showErrorToast("Could not retrieve url");
                return;
            }

            dispatch({
                type: DOWNLOAD_URL_STORE_STATE.SUCCESS,
                loading: false,
                error: null
            });
            return url;
        } catch (e: any) {
            dispatch({
                type: DOWNLOAD_URL_STORE_STATE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};
