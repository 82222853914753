import {StoreServiceData} from "store-fetch-wrappers";
import {createStoreState} from "store-fetch-wrappers/dist";

export const DOWNLOAD_URL_STORE_STATE = createStoreState("download_url");

export interface DownloadUrlSuccess extends StoreServiceData<string> {
    type: typeof DOWNLOAD_URL_STORE_STATE.SUCCESS;
}

export interface DownloadUrlLoading extends StoreServiceData<string> {
    type: typeof DOWNLOAD_URL_STORE_STATE.LOADING;
}

export interface DownloadUrlError extends StoreServiceData<string> {
    type: typeof DOWNLOAD_URL_STORE_STATE.ERROR;
}

export type DownloadUrlDispatchTypes = DownloadUrlError | DownloadUrlLoading | DownloadUrlSuccess;
