import React, {useEffect, useState} from "react";
import Select, {SingleValue} from "react-select";
import {DDProps, DropDownProps} from "./Helpers/dropdownUtils";

const FilterDocumentDropDown = (props: DropDownProps) => {
    const [selectedOption, setSelectedOption] = useState<DDProps>({value: 0, label: ""});

    useEffect(() => {
        const options = getFilterOptions();
        const index = options.findIndex(
            (option) => option.value.toString() === props.id.toString()
        );

        setSelectedOption(options[index]);
    }, [props.id]);

    const handleFilterChange = (p: SingleValue<DDProps>) => {
        if (!p) return;
        props.changeOption(p.value.toString());
        setSelectedOption(p);
    };

    const getFilterOptions = (): DDProps[] => {
        return Object.keys(FilterType).map((key: string) => {
            return {
                label: getFilterTypeFromString(key),
                value: key
            };
        });
    };

    return (
        <React.Fragment>
            <Select
                className="search-bar"
                classNamePrefix="select-options"
                options={getFilterOptions()}
                onChange={handleFilterChange}
                isSearchable={false}
                isClearable={false}
                value={selectedOption}
            />
            <div className="row-separator" />
        </React.Fragment>
    );
};

export default FilterDocumentDropDown;

// eslint-disable-next-line no-shadow
export enum FilterType {
    All = "All",
    Read = "Read",
    Updated = "Updated",
    Unread = "Unread"
}

export function getFilterTypeFromString(value: string): FilterType {
    return FilterType[value as keyof typeof FilterType];
}
