import {StoreServiceData} from "store-fetch-wrappers";
import {DocumentViewRecordWithDocument} from "../../../api/ppb";
import {createStoreState} from "store-fetch-wrappers/dist";

export const USER_DOCUMENTS_STORE_STATE = createStoreState("user_documents_list");

export interface UserDocumentsLoading extends StoreServiceData<DocumentViewRecordWithDocument[]> {
    type: typeof USER_DOCUMENTS_STORE_STATE.LOADING;
}

export interface UserDocumentsError extends StoreServiceData<DocumentViewRecordWithDocument[]> {
    type: typeof USER_DOCUMENTS_STORE_STATE.ERROR;
}

export interface UserDocumentsSuccess extends StoreServiceData<DocumentViewRecordWithDocument[]> {
    type: typeof USER_DOCUMENTS_STORE_STATE.SUCCESS;
}

export type UserDocumentsDispatchTypes =
    | UserDocumentsSuccess
    | UserDocumentsError
    | UserDocumentsLoading;
