import React from "react";
import {GroupSubSystem} from "../Header/Helpers/topbarHelpers";
import useCanUserSeeLink from "../Header/Hooks/useCanUserSeeLink";
import {useCorrectSystemLink} from "../Hooks/useCorrectSystemLink";

const ExternalLinkSubsystemItem = (props: GroupSubSystem) => {
    const link = useCorrectSystemLink(props);
    const onItemClicked = (active: boolean, incomingLink?: string) => {
        if (!incomingLink) return;
        if (active) return;
        window.open(incomingLink, "_self");
    };
    const showLink = useCanUserSeeLink(props);

    return (
        <React.Fragment>
            {showLink && (
                <li
                    className={`sidebar-list-item ${props.isActive ? "active-route" : ""}`}
                    onClick={() => onItemClicked(props.isActive, link)}
                >
                    <a
                        onClick={() => onItemClicked(props.isActive, link)}
                        className="subsystem-group-item"
                    >
                        {props.name}
                    </a>
                </li>
            )}
        </React.Fragment>
    );
};

export default ExternalLinkSubsystemItem;
