import React from "react";

const FormHeader = ({headerName}: FormHeaderProps) => (
    <div className="row ml-0 mr-0 mc-form-heading">
        <div className="col">
            <h5 className="mb-0">{headerName}</h5>
        </div>
    </div>
);

export default FormHeader;

interface FormHeaderProps {
    headerName: string;
}
