import {StoreServiceData, ServiceActionTypes} from "store-fetch-wrappers";
import {Document} from "../../../api/ppb";
import {DOCUMENTS_STORE_STATE} from "../actions/DocumentsActionTypes";
import {createDefaultStoreState, createReducer} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<Document[]>([]);

const documentsReducer = (
    state: StoreServiceData<Document[]> = defaultState,
    action: ServiceActionTypes<Document[]>
): StoreServiceData<Document[]> =>
    createReducer(state, action, DOCUMENTS_STORE_STATE, () => showErrorToast(action.error));

export default documentsReducer;
