import React from "react";
import {getIconTypeFromString, GroupSubSystem} from "./Helpers/topbarHelpers";
import useCanUserSeeLink from "./Hooks/useCanUserSeeLink";
import Icon from "../../../../../Icon/Icon";
import {useCorrectSystemLink} from "../Hooks/useCorrectSystemLink";

const NavigationDropdownItem = (props: GroupSubSystem) => {
    const showLink = useCanUserSeeLink(props);
    const link = useCorrectSystemLink(props);
    const onItemClicked = (active: boolean, incomingLink?: string) => {
        if (!incomingLink) return;
        if (active) return;
        window.open(incomingLink, "_self");
    };
    return (
        <React.Fragment>
            {showLink && (
                <div className="col-lg-4 pl-1 pr-1 bounceIn">
                    <a
                        className={`group-sub-system-box ${
                            props.isActive ? "active-sub-system" : ""
                        }`}
                        onClick={() => onItemClicked(props.isActive, link)}
                    >
                        <div className="box-image">
                            <Icon
                                rootElement={"Span"}
                                icon={getIconTypeFromString(props.icon)}
                                className={""}
                                size={"Large"}
                            />
                        </div>
                        <div className="box-text">
                            <p>{props.name}</p>
                        </div>
                    </a>
                </div>
            )}
        </React.Fragment>
    );
};

export default NavigationDropdownItem;
