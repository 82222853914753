import React from "react";
import {GuardedRoute} from "react-router-guards";

export interface CustomRouteProps {
    exact?: any;
    path?: string;
    layout?: any;
    component?: any;
    meta?: any;
    render?: any;
}

const CustomGuardedRoute = ({layout, component, render, ...props}: CustomRouteProps) => {
    const componentToUse = component;

    return (
        <GuardedRoute
            {...props}
            render={(nestedProps): JSX.Element => {
                let renderComponent: any;

                if (componentToUse) {
                    renderComponent = React.createElement(componentToUse, nestedProps);
                } else if (render) {
                    renderComponent = React.createElement(render, nestedProps);
                }
                if (layout && renderComponent) {
                    return React.createElement(layout, nestedProps, renderComponent);
                }

                return renderComponent;
            }}
        />
    );
};

export default CustomGuardedRoute;
