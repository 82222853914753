import {UsersApi} from "../../api/staff";

export default class StaffApiModel {
    private static instance: StaffApiModel;
    private static usersApi: UsersApi;

    public static getInstance(): StaffApiModel {
        if (!StaffApiModel.instance) {
            StaffApiModel.instance = new StaffApiModel();
        }
        return StaffApiModel.instance;
    }

    public static getUsersApi(): UsersApi {
        if (!StaffApiModel.usersApi) {
            StaffApiModel.usersApi = new UsersApi({
                basePath: process.env.REACT_APP_STAFF_API,
                isJsonMime(): boolean {
                    return false;
                }
            });
        }

        return StaffApiModel.usersApi;
    }
}
