import {ServiceActionTypes, StoreServiceData} from "store-fetch-wrappers";
import {STAFF_LIST_STORE_STATE} from "../actions/StaffListActionTypes";
import {UserData} from "../../../api/staff";
import {createDefaultStoreState, createReducer} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<UserData[]>([]);

const staffListReducer = (
    state: StoreServiceData<UserData[]> = defaultState,
    action: ServiceActionTypes<UserData[]>
): StoreServiceData<UserData[]> =>
    createReducer(state, action, STAFF_LIST_STORE_STATE, () => showErrorToast(action.error));

export default staffListReducer;
