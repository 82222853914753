import React, {useEffect, useState} from "react";
import {DocumentViewRecordWithDocument} from "../../../api/ppb";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../Button/MCButton";
import {useDispatch} from "react-redux";
import {
    fetchSignedLink,
    nullifySignedLink
} from "../../../store/downloadUrl/actions/DownloadUrlActions";
import {createDownloadAnchorAndDownload} from "../../../utils/downloadUtils";
import {useToggle} from "../../Toggle/useToggle";
import Toggle, {ToggleSize} from "../../Toggle/Toggle";
import {Modal, useModal} from "pulse-modal";
import Tooltip from "../../Tooltip/Tooltip";
import {saveReadReceiptForDoc} from "../../../store/userReadReceipt/actions/UserReadReceiptActions";
import {useUserData} from "../../Hooks/useUserData";
import moment from "moment";
import {fetchDocumentsForStaffMember} from "../../../store/userDocuments/actions/UserDocumentsActions";
import {showSuccessToast} from "../../../utils/toastUtils";

const MyDocumentListItem = ({documentViewRecord, disabled}: MyDocumentListItemProps) => {
    const dispatch = useDispatch();
    const user = useUserData();
    const [loading, setLoading] = useState<boolean>(false);
    const [documentType, setDocumentType] = useState<DocumentType>(DocumentType.Unset);
    const [opened, setOpened] = useToggle(false);
    const [read, setRead] = useToggle(false);
    const {toggle, isShown} = useModal();

    useEffect(() => {
        return () => {
            dispatch(nullifySignedLink());
        };
    }, []);

    useEffect(() => {
        if (!documentViewRecord.date) {
            setDocumentType(DocumentType.Unread);
            return;
        }

        if (documentViewRecord.document.date > documentViewRecord.date) {
            setDocumentType(DocumentType.Updated);
            return;
        }
        setDocumentType(DocumentType.Read);
    }, [documentViewRecord]);

    const onDocumentClicked = async () => {
        if (disabled) return;
        setLoading(true);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const file: string = await dispatch(fetchSignedLink(documentViewRecord.document.id));

        createDownloadAnchorAndDownload(documentViewRecord.document.filename, file);
        setLoading(false);
        if (documentType === DocumentType.Read) return;
        setOpened(true);
    };
    useEffect(() => {
        if (!read) return;
        toggle();
    }, [read]);

    const savedReadReceipt = async () => {
        if (user.username.length === 0) return;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(
            saveReadReceiptForDoc({
                documentId: documentViewRecord.document.id,
                staffId: user.username,
                date: moment().unix()
            })
        );

        if (!success) return;

        showSuccessToast(`Marked ${documentViewRecord.document.name} as read.`);

        dispatch(fetchDocumentsForStaffMember(user.username));
        setOpened(false);
        setRead(false);
    };

    const onCancel = () => {
        setRead(false);
        toggle();
    };
    return (
        <React.Fragment>
            {opened ? (
                <div className="col-6 category-button">
                    <div className="desktop">
                        <div className="row ml-0 mr-0">
                            <div className="col-sm-6 pl-0">
                                <MCButton
                                    size={ButtonSize.ExtraLarge}
                                    innerValue={
                                        loading
                                            ? "Downloading..."
                                            : documentViewRecord.document.name
                                    }
                                    onClick={onDocumentClicked}
                                    colour={getButtonColour(documentViewRecord)}
                                    className={"w-100"}
                                    disabled={disabled}
                                />
                            </div>
                            <div className="col-sm-6 pl-0 pr-0">
                                <div className="icon-tooltip-wrapper-md mr-3">
                                    <Tooltip
                                        tooltipText={`Mark ${documentViewRecord.document.name} as read?`}
                                        size={"md"}
                                        place={"left"}
                                    >
                                        <div className="toggle-wrapper-lg d-inline-block">
                                            <p className="mb-0 pl-3"> Read? </p>
                                            <Toggle
                                                checked={read}
                                                onChange={() => setRead(true)}
                                                size={ToggleSize.Large}
                                            />
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mobile">
                        <div className="row ml-0 mr-0">
                            <div className="col-sm-6 pl-0">
                                <MCButton
                                    size={ButtonSize.ExtraLarge}
                                    innerValue={
                                        loading
                                            ? "Downloading..."
                                            : documentViewRecord.document.name
                                    }
                                    onClick={onDocumentClicked}
                                    colour={getButtonColour(documentViewRecord)}
                                    className={"w-100"}
                                    disabled={disabled}
                                />
                            </div>
                        </div>
                        <div className="row ml-0 mr-0">
                            <div className="col-sm-6 pl-0 pr-0">
                                <div className="toggle-wrapper-lg d-inline-block">
                                    <p className="mb-0 pl-3"> Read? </p>
                                    <Toggle
                                        checked={read}
                                        onChange={() => setRead(true)}
                                        size={ToggleSize.Large}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="col-6 category-button">
                    <MCButton
                        size={ButtonSize.ExtraLarge}
                        innerValue={loading ? "Downloading..." : documentViewRecord.document.name}
                        onClick={onDocumentClicked}
                        colour={getButtonColour(documentViewRecord)}
                        className={"w-100"}
                        disabled={disabled}
                    />
                </div>
            )}
            <Modal
                modalSize={"sm"}
                title={"Mark as read..."}
                bodyChildren={
                    <div className="row ml-0 mr-0">
                        <p className="mb-0">
                            By marking this document as ‘read’ you are confirming that you have read
                            and understood the content of the{" "}
                            <b>{documentViewRecord.document.name}.</b>
                        </p>
                    </div>
                }
                onClose={onCancel}
                isShown={isShown}
                footerChildren={
                    <div className="row mr-0 ml-0">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Yes"}
                            onClick={async () => {
                                await savedReadReceipt();
                                toggle();
                            }}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"No"}
                            onClick={onCancel}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    </div>
                }
            />
        </React.Fragment>
    );
};

export default MyDocumentListItem;

function getButtonColour(document: DocumentViewRecordWithDocument): ButtonColourOptions {
    if (!document.date) return ButtonColourOptions.Red;
    if (document.document.date > document.date) {
        return ButtonColourOptions.Yellow;
    }
    return ButtonColourOptions.Green;
}

interface MyDocumentListItemProps {
    disabled: boolean;
    documentViewRecord: DocumentViewRecordWithDocument;
}

// eslint-disable-next-line no-shadow
enum DocumentType {
    Read = "Read",
    Updated = "Updated",
    Unread = "Unread",
    Unset = "Unset"
}
