import {StoreServiceData, ServiceActionTypes} from "store-fetch-wrappers";
import {CATEGORY_STORE_STATE} from "../actions/CategoryActionTypes";
import {Category} from "../../../api/ppb";
import {createDefaultStoreState, createReducer} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<Category>(null);

const categoryReducer = (
    state: StoreServiceData<Category> = defaultState,
    action: ServiceActionTypes<Category>
): StoreServiceData<Category> =>
    createReducer(state, action, CATEGORY_STORE_STATE, () => showErrorToast(action.error));

export default categoryReducer;
