import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {logout, unAuthenticateUser} from "../../../../store/auth/actions/AuthActions";
import {setNavigationItems} from "../../../../store/navigation/actions/NavigationActions";
import {CustomLoadingWheel} from "react-state-helpers";

const Logout = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        localStorage.removeItem("current_route");
        dispatch(setNavigationItems([]));
        dispatch(unAuthenticateUser());
        setTimeout(() => {
            dispatch(logout());
        }, 1500);
    }, []);

    return (
        <React.Fragment>
            <div className="page background-stars">
                <h1 className="text-center title mt-5 pt-5 header-font">Signing You Out...</h1>
                <CustomLoadingWheel />
            </div>
        </React.Fragment>
    );
};

export default Logout;
