import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {SIDEBAR_STORE_STATE} from "../actions/SidebarActionTypes";

const defaultState = createDefaultStoreState<boolean>(false);

const sidebarReducer = (
    state: StoreServiceData<boolean> = defaultState,
    action: ServiceActionTypes<boolean>
): StoreServiceData<boolean> => createReducer(state, action, SIDEBAR_STORE_STATE);

export default sidebarReducer;
