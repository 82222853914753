import React, {useEffect, useState} from "react";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Sidebar from "./Components/Sidebar/Sidebar";
import {LayoutHeaderContext} from "./Context/LayoutHeaderContext";
import useWindowSize from "../../../Hooks/useWindowResize";

export interface LayoutProps {
    compiler?: string;
    framework?: string;
    children: JSX.Element;
}

const MedicareLayout = ({children}: LayoutProps) => {
    const [contentStyling, setContentStyling] = useState<MainContentStyle>({
        marginTop: "0",
        paddingTop: "0"
    });

    const size = useWindowSize();

    useEffect(() => {
        setContentStyling(getMainContentStyle);
    }, [size]);

    return (
        <React.Fragment>
            <div className="layout-wrapper" id="main-panel">
                <div className="position-absolute main-content-overlay h-100 w-100" />
                <LayoutHeaderContext.Provider value={true}>
                    <Header />
                </LayoutHeaderContext.Provider>
                <div className="fadeIn main-layout" style={contentStyling}>
                    {children}
                </div>
            </div>
            <Footer />
            <Sidebar />
        </React.Fragment>
    );
};

export default MedicareLayout;

interface MainContentStyle {
    marginTop: string;
    paddingTop: string;
}

function getMainContentStyle(): MainContentStyle {
    return {
        marginTop: getNavHeaderHeight(),
        paddingTop: getSubSystemHeaderHeight()
    };
}

function getNavHeaderHeight(): string {
    const navHeader = document.getElementById("nav-header");
    if (!navHeader) return "0";

    return `${navHeader.getBoundingClientRect().height}px`;
}

function getSubSystemHeaderHeight(): string {
    const subsystemHeader = document.getElementById("sub-system-header");
    if (!subsystemHeader) return "0";

    return `${subsystemHeader.getBoundingClientRect().height}px`;
}
