import {
    StoreServiceData,
    ServiceActionTypes,
    createReducer,
    createDefaultStoreState
} from "store-fetch-wrappers/dist";
import {NAVIGATION_STORE_STATE, NavigationItem} from "../actions/NavigationActionTypes";

const defaultState = createDefaultStoreState<NavigationItem[]>([]);

const navigationReducer = (
    state: StoreServiceData<NavigationItem[]> = defaultState,
    action: ServiceActionTypes<NavigationItem[]>
): StoreServiceData<NavigationItem[]> => createReducer(state, action, NAVIGATION_STORE_STATE);

export default navigationReducer;
