import {Category} from "../../../api/ppb";
import {StoreServiceData} from "store-fetch-wrappers";
import {createStoreState} from "store-fetch-wrappers/dist";

export const CATEGORIES_STORE_STATE = createStoreState("category_list_page");

export interface CategoriesLoading extends StoreServiceData<Category[]> {
    type: typeof CATEGORIES_STORE_STATE.LOADING;
}

export interface CategoriesError extends StoreServiceData<Category[]> {
    type: typeof CATEGORIES_STORE_STATE.ERROR;
}

export interface CategoriesSuccess extends StoreServiceData<Category[]> {
    type: typeof CATEGORIES_STORE_STATE.SUCCESS;
}

export type CategoriesDispatchTypes = CategoriesSuccess | CategoriesLoading | CategoriesError;
