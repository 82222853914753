import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../../../../store/Store";

export const useFadeInFadeOut = ({fadeInFirst}: FadeInFadeOutOptions) => {
    const [fadeClassName, setFadeClassName] = useState<string>("");
    const sidebarToggled = useSelector((state: RootStore) => state.sidebar.data) || false;
    useEffect(() => {
        if (fadeInFirst) {
            setFadeClassName(sidebarToggled ? "fadeIn" : "fadeOut");
            return;
        }
        setFadeClassName(sidebarToggled ? "fadeOut" : "fadeIn");
    }, [sidebarToggled]);

    return fadeClassName;
};

interface FadeInFadeOutOptions {
    fadeInFirst: boolean;
}
