import {combineReducers} from "redux";
import authReducer from "./auth/reducer/AuthReducer";
import navigationReducer from "./navigation/reducer/NavigationReducer";
import sidebarReducer from "./sidebar/reducer/sidebarReducer";
import mcConfigReducer from "./mcConfig/reducer/McConfigReducer";
import categoriesReducer from "./categories/reducer/CategoriesReducer";
import categoryReducer from "./category/reducer/CategoryReducer";
import documentReducer from "./document/reducer/DocumentReducer";
import documentsReducer from "./documents/reducer/DocumentsReducer";
import downloadUrlReducer from "./downloadUrl/reducer/DownloadUrlReducer";
import rawDownloadFileReducer from "./rawDownloadFile/reducer/rawDownloadFileReducer";
import userDocumentReducer from "./userDocuments/reducer/UserDocumentReducer";
import userReadReceiptReducer from "./userReadReceipt/reducer/UserReadReceiptReducer";
import staffListReducer from "./staffList/reducer/StaffListReducer";
import userCountReducer from "./userCount/reducer/UserCountReducer";
import viewCountReducer from "./viewCount/reducer/ViewCountReducer";
import userReducer from "./user/reducer/UserReducer";

const RootReducer = combineReducers({
    navigation: navigationReducer,
    sidebar: sidebarReducer,
    auth: authReducer,
    mcConfig: mcConfigReducer,
    categories: categoriesReducer,
    category: categoryReducer,
    document: documentReducer,
    documents: documentsReducer,
    downloadUrl: downloadUrlReducer,
    rawDownloadFile: rawDownloadFileReducer,
    userDocuments: userDocumentReducer,
    userReadReceipt: userReadReceiptReducer,
    userCount: userCountReducer,
    viewCount: viewCountReducer,
    user: userReducer,
    staffList: staffListReducer
});

export default RootReducer;
